import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SelectedBankQuestionsService {
  constructor(private http: HttpClient) {}

  public SelectedBankQuestions(data: any): Observable<any> {
    const baseUrl = environment.baseUrl;
    const listUrl = baseUrl + "/testbuilder/api/itemInfo";
    //  let listUrl = '/../../assets/stubs/sourceBank.json';
    return this.http.post(listUrl, data);
  }
  public deleteQuestion(data: any): Observable<any> {
    const baseUrl = environment.baseUrl;
    const questionId = data.qid;
    const listUrl =
      baseUrl + "/testbuilder/api/v1/deletequestion/" + questionId;
    let postData = { data: "" };
    if (data.refType == "multipart" && data.hasOwnProperty("parts")) {
      var ids: any = [];
      data.parts.forEach((item) => {
        ids.push({
          qid: item.qid,
          tid: item.ezid,
        });
      });
      postData = ids;
      return this.http.post(listUrl, postData);
    } else {
      return this.http.delete(listUrl);
    }
  }

  public getExamStatistics(data: any): Observable<any> {
    const baseUrl = environment.baseUrl;
    const listUrl = baseUrl + "/testbuilder/api/statistics";
    return this.http.post(listUrl, data);
  }
}
