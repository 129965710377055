import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ButtonType } from '@mhe/ngx-shared';
import { ButtonPurpose,AlertType  } from '@mhe/ngx-shared';
import { PaginatePipe } from './../../pipes/pagination.pipe';
@Component({
  selector: 'preview-modal-component',
  templateUrl: './preview.modal.component.html',
  styleUrls: ['./preview.modal.component.scss']
})
export class PreviewModalComponent implements OnInit {

  public buttonType = ButtonType;
  public _buttonPurpose = ButtonPurpose;
  public alertType = AlertType;
  @Input('header') header: String;
  @Input('type') type: String;
  @Input('question-index') index;
  @Input('sourceLocationInfo') sourceLocationInfo : String ;
  @Input('preview-data') previewData: any;
  @Input('sectionBreakWarning') sectionBreakWarning:boolean;
  @Output() closeModal = new EventEmitter<any>();

  public pageNumber: number = 0;
  public itemsPerPage = 1;
  public dataLength = 0;
  public copyOfPreviewData;
  public questionInfoMessage:String = 'This question has been added to the test';
  public pagerObject:any = {};
  public isQuestionAddedRemoved:boolean = false;
  public questionStatus:string = '';
  public timeOutHandler:any;
  public sectionBreakEnabled = false;
  public beforeDroppedValue;
  @Output() addQuestionClicked = new EventEmitter<any>();

  constructor(public sanitizer: DomSanitizer, private paginate: PaginatePipe) { }

  ngOnInit(): void {

    if( typeof this.header == 'undefined'){
      this.header ='Preview';
    }
    this.pageNumber = this.index;
    this.copyOfPreviewData = this.previewData;
    this.previewData.map((obj,index)=>{
      obj['visibleIndex'] = obj.index;
      const key =  index + 1;
      this.pagerObject[key.toString()] = obj.index
    });
    this.dataLength = this.previewData.length;
    this.previewData = this.paginate.transform(this.previewData, { 'itemsPerPage': this.itemsPerPage, 'currentPage': this.pageNumber })
  }

  pagerUpdate(){
    setTimeout(() => {
      let buttonArray = document.getElementById("pagerWrapper").querySelectorAll("button");
      for (var i = 0; i < buttonArray.length; i++) {
        if(i != 0 && i != buttonArray.length - 1) {
          if(!buttonArray[i].classList.contains("changed")){
            buttonArray[i].innerText = this.pagerObject[buttonArray[i].innerText];
            buttonArray[i].className = buttonArray[i].className + " changed" ;
          }
        }
      }
    }, 500);
  }

  public pageChanged(event) {
    this.isQuestionAddedRemoved = false;
    this.pageNumber = event.pageIndex;
    this.previewData = this.copyOfPreviewData;
    this.previewData = this.paginate.transform(this.copyOfPreviewData, { 'itemsPerPage': this.itemsPerPage, 'currentPage': this.pageNumber });
    this.pagerUpdate();
  }

  public closePreviewModal() {
    this.closeModal.emit(true);
  }

  public addQuestion(obj){
    if(this.timeOutHandler){
      clearTimeout(this.timeOutHandler);
    }
    let index = this.previewData[0].index;
    this.beforeDroppedValue = JSON.parse(JSON.stringify(this.previewData[0].dropped));
    this.previewData[0].dropped = !this.previewData[0].dropped ;
    this.previewData[0].isRemoved = true;
    this.isQuestionAddedRemoved = true;
    let mode;
    if(this.previewData[0].dropped) {
      this.questionStatus = "Question added";
      mode = 'add';
    } else {
      mode = 'remove';
      this.questionStatus = "Question removed";
      if(this.sourceLocationInfo == "RightPanel"){
        this.questionStatus = "Question removed from the test";
      }
    }
    this.timeOutHandler = setTimeout(() => {
      this.closeSuccessAlert();
    }, 8000);



    this.addQuestionClicked.emit({'mode':mode,'data':this.previewData,'index': this.previewData[0].originalIndex,'source':this.sourceLocationInfo});
    this.previewData[0].index = index;
    
  }

  closeSuccessAlert(){
    this.isQuestionAddedRemoved = false;
  }

 




}
