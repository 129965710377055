import { QuestionList } from "./../../shared/models/bank-question-list.model";
import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener,
  Output,
  EventEmitter,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DragulaService } from "ng2-dragula";
import { ItemsListService } from "../../shared/services/items-list.service";
import { ShareDataService } from "../../shared/services/shared.service";
import { SaveAssessmentService } from "../../shared/services/save-assessment.service";
import { SourceInfoService } from "../../shared/services/source-info.service";
import { ButtonType } from "@mhe/ngx-shared";
import { ButtonPurpose } from "@mhe/ngx-shared";
import { HeaderData } from "../../shared/models/header-items";
import { Collection } from "../../shared/models/collection.model";
import { MetaData } from "../../shared/models/metadata.model";
import { References } from "../../shared/models/references.model";
import { KebabMenuInterface } from "../../shared/models/kebab-menu-model";
import { EditListInterface } from "../../shared/models/edit-assessment-list.model";
import { Subscription } from "rxjs";
import { AutoUnsubscribe } from "./../../shared/components/decorator/autoUnsubscribe.decorator";
import { environment } from "../../../environments/environment";

@Component({
  selector: "edit-assessment",
  templateUrl: "./edit-assessment.component.html",
  styleUrls: ["./edit-assessment.component.scss"],
})
export class EditAssessmentComponent implements OnInit, AfterViewInit {
  collectionId: String = "";
  private error;
  private message;
  public screenHeight;
  public screenWidth;
  public previewUrl = [];
  public closeModalPopUp = false;
  public qIndex;
  public zeroStateCompHeight;
  public editCompHeight;
  public itemsList = [];
  public selectedQuestionsList = [];
  public operationMode = "create";
  public headerData: HeaderData;
  public step: String = "step1";
  private collection: Collection;
  public type: String;
  public saveAssessementSubscription: Subscription;
  public itemsListServiceSubscription: Subscription;
  selectAll = { selected: false };
  public buttonType = ButtonType;
  public _buttonPurpose = ButtonPurpose;
  public alertMessage =
    "Are you sure you want to remove this question from your test?";
  public size = { width: 302 };
  public removeReqQuestion = [];
  public modalShow = true;
  public showModal = false;
  public tabIndex = 0;
  public kebabIndex: number;
  private sectionBreakRemoveObj: any;
  private sectionPartsRemoveObj: any;
  public showSectionBreakQuestionDeleteModal: boolean = false;
  public deleteMessage: String;
  public DeleteModalHeader: String;
  public secBreakRemove: boolean;
  public partQuestionRemove: boolean;
  private testIsbn: String;

  private arrayIndex = 0;
  private questionIdArray = [];
  private pinnedQuestionListIndex = [];
  private pinnedQuestionList = [];
  public algoImgUrl =
    environment.assests + "/assets/img/connect_sprite_icon2.png";

  public isPreviewTestQuestions: boolean = false;
  public previewQuestionsList = [];
  showQuestionTypes: boolean = false;
  menuWidth: any;
  public removeIndex: any;
  public previewHeaderText: string = "Previewing all questions";
  public questionPreviewList: any = [];
  public questionPreviewListBackup: any = [];
  @Output() itemInfoDataEmitter = new EventEmitter<any>();

  constructor(
    private saveAssessmentService: SaveAssessmentService,
    private itemsListService: ItemsListService,
    private shareDataService: ShareDataService,
    private router: Router,
    private route: ActivatedRoute,
    private dragula: DragulaService,
    private renderer: Renderer2,
    private sourceInfoService: SourceInfoService
  ) {
    dragula.destroy("EDIT-QUESTIONS"); // Remove group before creating new
    dragula.createGroup("EDIT-QUESTIONS", {
      moves: (el, container, handle) => {
        return handle.parentElement.className === "handles";
      },
    });
    this.dragula.dragend().subscribe((value) => {
      this.arrayIndex = 0;
      this.questionIdArray = [];
      this.assignArrayIndex(this.itemsList["references"]);
      this.arrayPinnedQuestions();
    });
  }

  @ViewChild("globalHeader")
  globalHeader: ElementRef;
  @ViewChild("sourceContainer")
  sourceContainer: ElementRef;

  kebabMenu: KebabMenuInterface;
  sectionBreakKebabMenu: KebabMenuInterface;
  partQuestionKebabMenu: KebabMenuInterface;

  ngAfterViewInit() {}

  ngOnInit() {
    this.operationMode = this.route.snapshot.paramMap.get("operationMode");

    if (this.operationMode && this.operationMode == "edit") {
      this.headerData = this.shareDataService.getHeaderData();
      this.collectionId = this.headerData.getCollectionId();
      this.operationMode = "edit";
      this.itemsListServiceSubscription = this.itemsListService
        .getCollectionItems(this.collectionId)
        .subscribe((data) => {
          this.itemsList = data;
          this.setSourceInfoData();
          //Assign index
          this.arrayIndex = 0;
          this.questionIdArray = [];
          let arrayIndex = 0;
          this.itemsList["references"].map((itm) => {
            itm.index = arrayIndex + 1;
            itm.previewIndex = arrayIndex + 1;
            arrayIndex = arrayIndex + 1;
            let randomNumber = Math.random().toString().substring(2);
            itm["uniqueId"] = itm.qid + "-" + randomNumber;
            itm["isDeleted"] = false;
            if (itm.hasOwnProperty("parts")) {
              itm.parts.map((item, pIndex) => {
                itm.parts[pIndex].index = pIndex;
                itm.parts[pIndex].previewIndex = arrayIndex + pIndex + 1;
                let randomNumber = Math.random().toString().substring(2);
                item["uniqueId"] = itm.qid + "-" + randomNumber;
                item["isDeleted"] = false;
              });
              arrayIndex = itm.parts[itm.parts.length - 1].index;
            }
          });

          let references = this.itemsList["references"];
          this.assignArrayIndex(references);
          this.arrayPinnedQuestions();
          this.shareDataService.setCollectionObjectDetails(data);
          this.setCollectionInfoInHeader();
          this.createCollectionObject();
          this.assignArrayIndexSectionBreakQuestion(
            this.itemsList["references"]
          );

          if (references && references.length > 0) {
            for (let i = 0; i < references.length; i++) {
              if ("isbn" in references[i]) {
                this.testIsbn = references[i]["isbn"];
                break;
              }
            }
          }
          this.addEditQuestions();
        });
    } else {
      this.shareDataService.setcheckISBN(this.testIsbn);
      this.shareDataService.setHeaderData(null);

      this.createCollectionObject();

      this.saveAssessementSubscription = this.saveAssessmentService
        .saveAssessment(this.collection)
        .subscribe(
          (data) => {
            this.collectionId = data.collectionId;
            this.message = data.Message;
            this.createCollectionObject();
          },
          (error) => {
            this.error = error;
          }
        );
    }
    this.onResize();

    this.kebabMenu = {
      addNewPart: false,
      get_info: false,
      preview: true,
      pinUnpin: true,
      edit: false,
      copy: false,
      export: false,
      rename: false,
      print: false,
      delete: false,
      remove: true,
      pastUsage: true,
      copyAndEdit: true,
      editQuestion: true,
    };
    this.sectionBreakKebabMenu = {
      addNewPart: true,
      get_info: false,
      preview: true,
      pinUnpin: true,
      edit: false,
      copy: false,
      export: false,
      rename: false,
      print: false,
      delete: false,
      remove: true,
      pastUsage: true,
      editQuestion: true,
      copyAndEdit: true,
    };
    this.partQuestionKebabMenu = {
      addNewPart: false,
      get_info: false,
      preview: true,
      pinUnpin: false,
      edit: false,
      copy: false,
      export: false,
      rename: false,
      print: false,
      delete: false,
      remove: true,
      pastUsage: true,
      editQuestion: true,
      copyAndEdit: true,
    };
    this.shareDataService.getCurrentStep().subscribe((stepName) => {
      this.step = stepName;
    });
  }
  setSourceInfoData() {
    this.sourceInfoService.getSourceInfoCollection().subscribe(
      (data) => {
        this.shareDataService.setSourceInfoDataList(data.sourceInfo);
      },
      (error) => {
        this.error = error;
      }
    );
  }
  @HostListener("window:resize", ["$event"]) onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    /**set source picker component height */
    this.zeroStateCompHeight = this.screenHeight - 351;
    this.editCompHeight = this.screenHeight - 462;
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (event.target.className !== "ahe-icon ahe-icon-system-kebab") {
      if (event.srcElement.id != "kebab-item-add-new-part") {
        this.kebabIndex = -1;
      }
    }
    if (document.getElementsByClassName("ahe-ui-alert-modal").length > 0) {
      this.kebabIndex = -1;
    }
  }

  public setKebabIndex(index: number) {
    this.kebabIndex = index;
  }

  public assignArrayIndex(data) {
    const list = [];
    this.arrayIndex = 0;
    this.itemsList["references"] = data.map((item) => {
      item.index = this.arrayIndex++;
      item.previewIndex = item.index;
      return item;
    });

    // Share data to footer component for save and exit
    this.createCollectionObject();
  }

  public selectAllQuestions(event) {
    if (event) {
      this.selectAll = { selected: true };
    } else {
      this.selectAll = { selected: false };
      this.selectedQuestionsList = [];
    }
    //check uncheck all on basis of select all
    this.itemsList["references"].forEach((item) => {
      if (event) {
        item.selected = true;
        this.selectedQuestionsList.push(item);
      } else {
        item.selected = false;
      }
      item["isSelected"] = event;

      if (item?.parts && item?.parts.length > 0) {
        item?.parts.map((item) => {
          item["selected"] = event;
          item["isSelected"] = event;
        });
      }
    });
    //to trigger ng on change on input
    this.selectedQuestionsList = this.selectedQuestionsList.slice();
  }

  public setCollectionInfoInHeader() {
    let totalPoints = 0,
      questionLength = 0;
    if (this.itemsList) {
      const questionsList = this.itemsList["references"];
      if (questionsList && questionsList.length > 0) {
        let totalPoints = 0;
        for (const question of questionsList) {
          totalPoints += +question.points;
        }
        this.headerData.setTotalPoints(totalPoints.toFixed(2));
        this.headerData.setTotalQuestions(questionsList.length);
        this.shareDataService.setHeaderData(this.headerData);
      } else {
        this.headerData.setTotalPoints(totalPoints);
        this.headerData.setTotalQuestions(questionLength);
        this.shareDataService.setHeaderData(this.headerData);
      }
      return this.headerData;
    }
  }

  public getOnlinePreviewUrl(previewUrl, type, index) {
    this.qIndex = index;
    this.isPreviewTestQuestions = true;
    this.pushPartQuestionPreviewQuestionsList();
    this.setPreviewQuestionIndex();
    this.type = type;
    this.closeModalPopUp = true;
  }
  public closeModal(obj) {
    this.closeModalPopUp = !obj;
    this.isPreviewTestQuestions = !obj;
    this.itemsList["references"] = this.itemsList["references"].filter(
      (item) => {
        if (item.parts) {
          item.parts = item.parts.filter((itm) => {
            return !itm.isDeleted;
          });
        }
        return !item.isDeleted;
      }
    );

    this.assignArrayIndex(this.itemsList["references"]);
    this.assignArrayIndexSectionBreakQuestion(this.itemsList["references"]);
    //set header information
    this.setCollectionInfoInHeader();
  }
  public updateModel = function (question) {
    this.createCollectionObject();
  };

  deleteAndUpdateQuestion() {
    //remove index from question list the one we get from kebab
    this.itemsList["references"].splice(this.removeReqQuestion["index"], 1);
    // reset all the index value after one/multiple objects inserted in between array
    this.arrayIndex = 0;
    this.questionIdArray = [];
    this.assignArrayIndex(this.itemsList["references"]);
    //set header information
    this.setCollectionInfoInHeader();
  }
  modalOptOut($event) {
    this.modalShow = false;
  }
  executePartQuestionRemove(params) {
    this.itemsList["references"].forEach((item) => {
      var sbindex;

      if (params.multiPartId == item.multiPartId) {
        sbindex = item.index;
        const index = params.index;
        this.itemsList["references"][sbindex].parts.splice(index, 1);

        if (item.parts.length == 0) {
          // If no question present under the section break then delete the section break
          this.itemsList["references"].splice(sbindex, 1);
          this.arrayIndex = 0;
          this.questionIdArray = [];

          this.showSectionBreakQuestionDeleteModal = false;
        }
        this.partQuestionRemove = false;
      }
    });
    this.arrayIndex = 0;
    this.assignArrayIndex(this.itemsList["references"]);
    this.assignArrayIndexSectionBreakQuestion(this.itemsList["references"]);
  }
  softSourceDelete(obj) {
    if (obj.refType == "multipart") {
      this.sectionBreakRemoveObj = obj;
      this.DeleteModalHeader = "Delete Section Break";
      this.deleteMessage = "msg1";
      this.secBreakRemove = true;
      this.partQuestionRemove = false;
      this.showSectionBreakQuestionDeleteModal = true;
    } else if (
      obj.refType == "scalar" &&
      obj.hasOwnProperty("multiPartId") &&
      obj.multiPartId != ""
    ) {
      let noOfPartQuestion;
      this.itemsList["references"].forEach((item) => {
        if (obj.multiPartId == item.multiPartId) {
          noOfPartQuestion = item.parts.length;
        }
      });

      if (noOfPartQuestion == 1) {
        this.showSectionBreakQuestionDeleteModal = true;
        this.partQuestionRemove = true;
        this.secBreakRemove = false;
        this.sectionPartsRemoveObj = obj;
        this.DeleteModalHeader = "Delete Question";
        this.deleteMessage = "msg2";
      } else {
        this.executePartQuestionRemove(obj);
      }
    } else {
      this.removeReqQuestion = obj;
      this.showModal = true;
      if (!this.modalShow) {
        this.deleteAndUpdateQuestion();
      }
    }
  }
  modalOkJob() {
    this.showModal = false;
    this.deleteAndUpdateQuestion();
  }
  modalCancelJob() {
    this.showModal = false;
  }
  public createCollectionObject() {
    this.collection = new Collection(
      Number(this.collectionId) || 0,
      Date.now(),
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    const metaDataArr: MetaData[] = new Array();
    if (this.headerData) {
      metaDataArr.push(new MetaData("title", this.headerData.getTitle()));
    } else {
      metaDataArr.push(new MetaData("title", "Unnamed Test"));
    }
    this.collection.setMetaData(metaDataArr);

    const questionsList = this.itemsList["references"];
    const isbn = localStorage.getItem("isbn");
    let algoPresent = false;
    if (questionsList && questionsList.length > 0) {
      const referencesArr: References[] = new Array();
      this.itemsList["references"].forEach((question) => {
        question.selected = question.isSelected ? true : false;
        let mpartid = question.hasOwnProperty("multiPartId")
          ? question["multiPartId"]
          : "";
        let reftype = question.hasOwnProperty("refType")
          ? question["refType"]
          : "";
        let edited = question.hasOwnProperty("edited") ? question["edited"] : 0;
        //let parts   = ( question.hasOwnProperty("parts") ? question['parts'] : [] );
        var parts = [];
        if (question.hasOwnProperty("parts")) {
          question.parts.forEach((partQuestion) => {
            let quesparts = {};
            quesparts["tid"] = partQuestion["ezid"];
            quesparts["qid"] = partQuestion["qid"];
            quesparts["algo"] = partQuestion["algo"];
            quesparts["types"] = partQuestion["type"];
            quesparts["points"] = partQuestion["points"];
            quesparts["pinned"] = partQuestion["pinned"];
            quesparts["edited"] = partQuestion["edited"];
            quesparts["multiPartId"] = partQuestion["multiPartId"];
            quesparts["refType"] = partQuestion["refType"];
            quesparts["qTitle"] = partQuestion["title"];
            quesparts["bankName"] = this.shareDataService.getBankName();
            quesparts["selected"] = quesparts["isSelected"] ? true : false;
            let randomNumber = Math.random().toString().substring(2);
            quesparts["uniqueId"] = partQuestion.qid + "-" + randomNumber;
            parts.push(quesparts);
          });
        }

        let updatedQuestion = new References(
          question["ezid"],
          question["qid"],
          question["algo"],
          question["type"],
          question["points"],
          question["pinned"],
          question["title"],
          this.shareDataService.getBankName(),
          mpartid,
          reftype,
          parts,
          edited
        );
        if (question["isbn"] === undefined || question["isbn"] == "") {
          updatedQuestion.setIsbn(isbn);
        } else {
          updatedQuestion.setIsbn(question["isbn"]);
        }
        referencesArr.push(updatedQuestion);
        if (updatedQuestion.getAlgo() && !algoPresent) {
          algoPresent = true;
        }
      });
      this.collection.setReferences(referencesArr);
    } else {
      this.collection.setClearedQuestion(true);
    }
    this.shareDataService.setCollectionDetails(this.collection);
    this.shareDataService.setCollectionSubject(this.collection);
    this.shareDataService.setAlgoPresent(algoPresent);
    return this.collection;
  }

  public validatePoints(event: any) {
    let isValid = true;
    let message = { Message: "Enter a point value between 0.00 and 1000.00" };
    const value = event.target.value;
    if (value === undefined || value == "") {
      isValid = false;
    } else if (isNaN(value)) {
      isValid = false;
    } else {
      const numVal = parseFloat(value);
      if (numVal > 1000) {
        isValid = false;
      } else if (numVal < 0) {
        isValid = false;
      } else {
        const dotPos = value.indexOf(".");
        if (
          dotPos > -1 &&
          value.substring(dotPos + 1, value.length).length > 2
        ) {
          isValid = false;
          message = {
            Message: "Enter a point value up to two decimal places.",
          };
        }
      }
    }
    if (isValid) {
      event.target.style = "box-shadow: 0 2px 0 0 #007c91;";
      //set header information
      this.setCollectionInfoInHeader();
    } else {
      event.target.style = "box-shadow: 0 2px 0 0 red;";
      const element = this.renderer.selectRootElement("#" + event.target.id);
      element.focus();
      this.shareDataService.sendMessageData(message);
    }
    return isValid;
  }

  public addEditQuestions() {
    this.shareDataService.setcheckISBN(this.testIsbn);

    this.headerData = this.shareDataService.getHeaderData();
    if (this.headerData === undefined || this.headerData == null) {
      this.headerData = new HeaderData();
      this.headerData.setCollectionId(this.collectionId);
    }
    this.headerData.setBackPage("editAssessment");
    this.shareDataService.setHeaderData(this.headerData);
    this.shareDataService.setLandingState("edit-state");
    this.router.navigate(["/sourceSelect"]);
  }

  public OnCheckboxSelect(bank, event) {
    const index = this.selectedQuestionsList.indexOf(bank);
    if (event) {
      if (index === -1) {
        this.selectAll.selected = false;
        this.selectedQuestionsList.push(bank);
      }
    } else {
      if (index !== -1) {
        this.selectAll.selected = false;
        this.selectedQuestionsList.splice(index, 1);
      }
    }

    this.itemsList["references"].forEach((item) => {
      if (bank.uniqueId === item.uniqueId) {
        item["isSelected"] = event;
        item["selected"] = event;
        if (item?.parts && item?.parts.length > 0) {
          item?.parts.map((itm) => {
            itm["isSelected"] = item["isSelected"];
            itm["selected"] = itm["isSelected"];
          });
        }
      } else if (item?.parts && item?.parts.length > 0) {
        item?.parts.map((itm) => {
          if (bank.uniqueId === itm.uniqueId) {
            itm["isSelected"] = event;
            itm["selected"] = event;
          }
        });
        let selectedCount = item.parts.filter((itm) => itm.selected);
        if (selectedCount.length == item.parts.length) {
          item["isSelected"] = true;
          item["selected"] = true;
        } else {
          item["isSelected"] = false;
          item["selected"] = false;
        }
      }
    });
    //to trigger ng on change on input
    this.selectedQuestionsList = this.selectedQuestionsList.slice();
  }

  public addZeroQuestions() {
    this.headerData = this.shareDataService.getHeaderData();
    if (this.headerData === undefined || this.headerData == null) {
      this.headerData = new HeaderData();
      this.headerData.setCollectionId(this.collectionId);
    }
    this.headerData.setCollectionId(this.collectionId);
    this.headerData.setBackPage("editAssessment");
    this.shareDataService.setHeaderData(this.headerData);
    this.shareDataService.setLandingState("zero-state");
    this.router.navigate(["/sourceSelect"]);
  }

  public settingsRouteActivate() {
    if (this.itemsList["references"]) {
      this.step = "step1";
    }
  }

  //Pin Feature
  public arrayPinnedQuestions() {
    this.pinnedQuestionListIndex = [];
    this.pinnedQuestionList = [];
    this.itemsList["references"] = this.itemsList["references"].map((item) => {
      const index = this.itemsList["references"].indexOf(item);
      if (item.pinned) {
        this.pinnedQuestionListIndex.push(index);
        this.pinnedQuestionList.push(item);
      }
      return item;
    });
    this.createCollectionObject();
  }

  public pinUnpinQuestion(obj) {
    if (this.itemsList["references"][obj.index].pinned != undefined) {
      this.itemsList["references"][obj.index].pinned =
        !this.itemsList["references"][obj.index].pinned;
    }
    this.arrayPinnedQuestions();
  }

  sortOnlyUnpinned(event) {
    if (event != undefined) {
      this.itemsList["references"] = event;
      for (
        let questions = 0;
        questions < this.itemsList["references"].length;
        questions++
      ) {
        if (this.itemsList["references"][questions].pinned) {
          this.itemsList["references"].splice(questions, 1);
          questions--;
        }
      }
      //insert pinned one to designated pre determined position
      for (
        let pinnedArray = 0;
        pinnedArray < this.pinnedQuestionListIndex.length;
        pinnedArray++
      ) {
        this.itemsList["references"].splice(
          this.pinnedQuestionListIndex[pinnedArray],
          0,
          this.pinnedQuestionList[pinnedArray]
        );
      }
      // reset all the index value after one/multiple objects inserted in between array
      this.arrayIndex = 0;
      this.questionIdArray = [];
      this.assignArrayIndex(this.itemsList["references"]);
    }
  }

  public sortedQuestionsList() {
    if (this.selectedQuestionsList.length > 0) {
      this.selectedQuestionsList.sort(function (question1, question2) {
        return question1.index - question2.index;
      });
      return this.selectedQuestionsList;
    }
  }

  public getPinnedQuestionList() {
    return this.pinnedQuestionList;
  }

  public getItemsList() {
    return this.itemsList;
  }

  public setArrayIndex(arrayIndex: number) {
    this.arrayIndex = arrayIndex;
  }

  public tabClick(event) {
    this.tabIndex = event;
    if (this.tabIndex == 0) {
      this.step = "step1";
    } else {
      this.step = "step2";
    }
  }

  updateChangeValueOfDropdown(value) {
    this.showQuestionTypes = value;
  }

  passValueToSource(val) {
    val = val.itemInfo.questions[0];
    this.itemsList["references"].unshift(val);
    this.arrayIndex = 0;
    this.questionIdArray = [];
    this.assignArrayIndex(this.itemsList["references"]);
    //set header information
    this.setCollectionInfoInHeader();
  }
  sourceQuestionUpdate(val) {
    val = val.response.itemInfo.questions[0];
    let randomNumber = Math.random().toString().substring(2);
    val.uniqueId = val.qid + "-" + randomNumber;
    var getPos: number = -1;
    getPos = this.shareDataService.getEditedQuestionIndex();
    if (getPos > -1) {
      let questType = this.shareDataService.getEditedQuestionType();
      if (questType == "section-break") {
        val.edited = 1;
        this.itemsList["references"][getPos].parts.push(val);
        this.assignArrayIndexSectionBreakQuestion(this.itemsList["references"]);
        //this.shareDataService.setEditedQuestionType('');
      } else if (questType == "section-break-part-question") {
        // section break part question edit or copyedit for my question
        let partInfo = this.shareDataService.getPartQuestionEditedInfo();
        if (
          partInfo.hasOwnProperty("multiPartId") &&
          partInfo.multiPartId != ""
        ) {
          this.itemsList["references"] = this.itemsList["references"].map(
            (item) => {
              if (partInfo.multiPartId == item.multiPartId) {
                if (partInfo.copyy === true) {
                  val.edited = 1;
                  item.parts.unshift(val);
                } else {
                  val.edited = 1;
                  var partQuesIndex = partInfo.index;
                  item.parts[partQuesIndex] = val;
                }
              }
              return item;
            }
          );
        }
        this.shareDataService.setPartQuestionEditedInfo({});
      } else if (questType == "section-break-part-question-copy-edit") {
        // section break part question edit for library question
        let partInfo = this.shareDataService.getPartQuestionEditedInfo();
        if (
          partInfo.hasOwnProperty("multiPartId") &&
          partInfo.multiPartId != ""
        ) {
          // Removed the existing item
          for (var i = this.itemsList["references"].length; i--; ) {
            if (
              this.itemsList["references"][i].multiPartId ==
              partInfo.multiPartId
            ) {
              this.itemsList["references"].splice(i, 1);
            }
          }
        }
        for (let i = 0; i < val.parts.length; i++) {
          if (i == getPos) {
            val.parts[i].edited = 1;
          }
        }
        this.itemsList["references"].unshift(val);
        this.shareDataService.setPartQuestionEditedInfo({});
      } else {
        var newpartid = this.shareDataService.getNewPartQuestionId();
        if (typeof newpartid != "undefined" && newpartid != "-1") {
          if (val.hasOwnProperty("parts")) {
            for (let i = 0; i < val.parts.length; i++) {
              if (val.parts[i].qid == newpartid) {
                val.parts[i].edited = 1;
              }
            }
          }
          this.shareDataService.setNewPartQuestionId("-1");
        } else {
          val.edited = 1;
        }
        this.itemsList["references"][getPos] = val;
        //this.shareDataService.setEditedQuestionIndex(-1);
      }
      this.shareDataService.setEditedQuestionType("");
      this.shareDataService.setEditedQuestionIndex(-1);
    } else {
      val.edited = 1;
      this.itemsList["references"].unshift(val);
    }

    this.arrayIndex = 0;
    this.questionIdArray = [];
    this.assignArrayIndex(this.itemsList["references"]);
    //set header information
    this.setCollectionInfoInHeader();

    this.assignArrayIndexSectionBreakQuestion(this.itemsList["references"]);
  }
  indexCal(i, j) {
    return i + "." + j;
  }
  assignArrayIndexSectionBreakQuestion(data) {
    this.itemsList["references"] = data.map((question, index) => {
      if (question.hasOwnProperty("parts")) {
        let ind = 0;
        question.parts.map((partQuestion, pind) => {
          ind = ind + 1;
          partQuestion.index = pind;
          partQuestion.previewIndex = index + ind;
          return partQuestion;
        });
      }
      return question;
    });
  }

  confirmSectionBreakRemove() {
    this.itemsList["references"].splice(this.sectionBreakRemoveObj["index"], 1);
    // reset all the index value after one/multiple objects inserted in between array
    this.arrayIndex = 0;
    this.questionIdArray = [];
    this.assignArrayIndex(this.itemsList["references"]);
    //set header information
    this.setCollectionInfoInHeader();
    this.assignArrayIndexSectionBreakQuestion(this.itemsList["references"]);
    this.showSectionBreakQuestionDeleteModal = false;
    //this.deleteAndUpdateQuestion(this.sectionBreakRemoveObj);
  }
  closeSectionBreakModal() {
    this.showSectionBreakQuestionDeleteModal = false;
  }
  confirmPartQuestionRemove() {
    this.executePartQuestionRemove(this.sectionPartsRemoveObj);
  }
  decideBottomBorder(selectedQuestions, j) {
    return selectedQuestions.parts.length - 1 == j ? "bottom-border" : "";
  }
  decideTopBorder(selectedQuestions, j) {
    return 0 == j ? "top-border" : "";
  }
  decideTopLeftCorner(j) {
    return 0 == j ? "border-top-left-radius" : "";
  }
  decideTopRightCorner(j) {
    return 0 == j ? "border-top-right-radius" : "";
  }
  decideBottomRightCorner(selectedQuestions, j) {
    return selectedQuestions.parts.length - 1 == j
      ? "border-bottom-right-radius"
      : "";
  }
  decideBottomLeftCorner(selectedQuestions, j) {
    return selectedQuestions.parts.length - 1 == j
      ? "border-bottom-left-radius"
      : "";
  }
  setBackground() {
    return "part-background";
  }
  borderLeft() {
    return "part-border-left";
  }
  borderRight() {
    return "part-border-right";
  }
  isSectionBreak(refType) {
    return refType == "multipart" ? true : false;
  }

  pushPartQuestionPreviewQuestionsList() {
    var tempPreviewQuestionsList = [];
    this.itemsList["references"].forEach((element) => {
      tempPreviewQuestionsList.push(element);
      if (element.hasOwnProperty("parts")) {
        tempPreviewQuestionsList.push(...element.parts);
      }
    });

    tempPreviewQuestionsList = tempPreviewQuestionsList.map((elm) => {
      elm.dropped = true;
      return elm;
    });

    this.previewQuestionsList = JSON.parse(
      JSON.stringify(tempPreviewQuestionsList)
    );
  }

  kebabPreview(selectedQuestionsListObj, index?) {
    this.itemsList["references"] = this.itemsList["references"].filter(
      (item) => {
        if (item.parts) {
          item.parts = item.parts.filter((itm) => {
            return !itm.isDeleted;
          });
        }
        return !item.isDeleted;
      }
    );
    this.isPreviewTestQuestions = true;
    let arrayIndex = 0;
    this.itemsList["references"].forEach((itm, indx) => {
      itm.index = arrayIndex + 1;
      itm.previewIndex = arrayIndex + 1;
      arrayIndex = arrayIndex + 1;
      if (itm.hasOwnProperty("parts")) {
        itm.parts.forEach((item, pIndex) => {
          itm.parts[pIndex].index = arrayIndex + pIndex + 1;
          itm.parts[pIndex].previewIndex = arrayIndex + pIndex + 1;
        });
        arrayIndex = itm.parts[itm.parts.length - 1].index;
      }
    });
    this.pushPartQuestionPreviewQuestionsList();
    //this.setPreviewQuestionIndex();
    this.questionPreviewList = this.previewQuestionsList;
    this.questionPreviewList.map((item, ind) => {
      if (item.qid == selectedQuestionsListObj.qid) this.qIndex = ind;
    });
    let selectedObject = false;
    this.itemsList["references"].map((item) => {
      if (item.isSelected == true && item.qid == selectedQuestionsListObj.qid)
        selectedObject = true;
      if (item?.parts) {
        item.parts.map((item) => {
          if (
            item.isSelected == true &&
            item.qid == selectedQuestionsListObj.qid
          )
            selectedObject = true;
        });
      }
    });
    if (selectedObject) {
      let quetionList = [];
      this.itemsList["references"].map((item) => {
        if (item.isSelected) {
          quetionList.push(item);
        }
        if (item?.parts) {
          item.parts.map((itm) => {
            if (itm.isSelected) {
              quetionList.push(itm);
            }
          });
        }
      });
      let selObj = quetionList.filter(
        (item) => item.uniqueId == selectedQuestionsListObj.uniqueId
      );
      if (quetionList.length > 0 && selObj.length > 0) {
        this.questionPreviewList = quetionList;
      }
    }

    this.questionPreviewList.map((item, ind) => {
      if (item.uniqueId == selectedQuestionsListObj.uniqueId) this.qIndex = ind;
    });
  }

  setPreviewQuestionIndex() {
    let index = 0;
    this.previewQuestionsList.forEach((itm, ind) => {
      this.previewQuestionsList[ind].index = ind + 1;
    });
  }

  questionLinked(obj, index) {
    this.kebabPreview(obj, index);
    this.removeIndex = index;
  }

  previewSourceQuestions() {
    this.itemsList["references"] = this.itemsList["references"].filter(
      (item) => {
        if (item.parts) {
          item.parts = item.parts.filter((itm) => {
            return !itm.isDeleted;
          });
        }
        return !item.isDeleted;
      }
    );
    this.qIndex = 0;
    this.isPreviewTestQuestions = true;

    let index = 0;
    this.itemsList["references"].forEach((itm, indx) => {
      itm.index = index + 1;
      itm.previewIndex = index + 1;
      index = index + 1;
      if (itm.hasOwnProperty("parts")) {
        itm.parts.forEach((item, pIndex) => {
          itm.parts[pIndex].index = index + pIndex + 1;
          itm.parts[pIndex].previewIndex = index + pIndex + 1;
        });
        index = itm.parts[itm.parts.length - 1].index;
      }
    });

    this.pushPartQuestionPreviewQuestionsList();
    this.isPreviewTestQuestions = true;
    this.previewHeaderText = "Preview test questions";
    this.questionPreviewList = this.previewQuestionsList;
    let quetionList = [];

    this.previewQuestionsList.map((item) => {
      if (item.isSelected && !item?.parts) {
        quetionList.push(item);
      } else if (item.isSelected && item?.parts) {
        quetionList.push(item);
        item.parts.map((itm) => {
          itm.index = itm.previewIndex;
          quetionList.push(itm);
        });
      }
    });
    if (quetionList.length > 0) {
      this.questionPreviewList = quetionList;
    }
  }

  addQuestionClicked(obj) {
    this.removeReqQuestion = obj["data"][0];
    if (this.removeReqQuestion["dropped"]) {
      this.itemsList["references"].map((item) => {
        let parentSelected = false;
        if (item.qid == obj["data"][0].qid) {
          item["isDeleted"] = false;
          parentSelected = true;
        }
        if (item.parts) {
          item.parts.map((itm) => {
            if (itm.qid == obj["data"][0].qid) {
              itm["isDeleted"] = false;
            }
            if (parentSelected) {
              itm["isDeleted"] = false;
            }
          });
          if (
            item.parts.length !=
            item.parts.filter((obj) => obj.isDeleted == true).length
          ) {
            item["isDeleted"] = false;
            this.previewQuestionsList.map((itm) => {
              if (itm.qid == item.qid) {
                itm.isRemoved = false;
                itm.dropped = true;
              }
            });
          }
        }
      });
    } else {
      // reset all the index value after one/multiple objects inserted in between array
      this.itemsList["references"].map((item) => {
        let parentSelected = false;
        if (item.qid == obj["data"][0].qid) {
          item["isDeleted"] = true;
          parentSelected = true;
        }
        if (item.parts) {
          item.parts.map((itm) => {
            if (itm.qid == obj["data"][0].qid) {
              itm["isDeleted"] = true;
            }
            if (parentSelected) {
              itm["isDeleted"] = true;
            }
          });
          if (
            item.parts.length ==
            item.parts.filter((obj) => obj.isDeleted == true).length
          ) {
            item["isDeleted"] = true;
            this.previewQuestionsList.map((itm) => {
              if (itm.qid == item.qid) {
                itm.isRemoved = true;
                itm.dropped = false;
              }
            });
          } else {
            this.previewQuestionsList.map((itm) => {
              if (itm.qid == item.qid) {
                itm.isRemoved = false;
                itm.dropped = true;
              }
            });
          }
        }
      });
      this.arrayIndex = 0;
      this.questionIdArray = [];
      this.assignArrayIndex(this.itemsList["references"]);
      //set header information
      this.setCollectionInfoInHeader();
    }

    //this.softSourceDelete(obj[0]);
  }
}
