import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'questionFilter'
})
export class QuestionFilterPipe implements PipeTransform {
  transform(items: any, filter: any, defaultFilter: boolean): any {

    if (!filter) {
      return items;
    }

    if (!Array.isArray(items)) {
      return items;
    }

     // Manipulate Filter Data so that perform and /or operation for filter

     if( filter.cats != undefined && filter.cats.length > 0){ 
      var processFilterTest={};
      filter.cats.forEach(e=>{
        if(processFilterTest.hasOwnProperty(e.name)){
          processFilterTest[e.name].push(e.name.toLowerCase()+'#'+e.val);
        }else{
          processFilterTest[e.name] = [e.name.toLowerCase()+'#'+e.val];
        }
      })
      }
      // End

    if ((filter.hasOwnProperty('questions') &&
          filter.hasOwnProperty('cats') &&
          Array.isArray(items)) &&
          (filter.questions.length > 0 || filter.cats.length > 0)) {
      let filteredItems: any;
      let itemCats: any = [];
      let itemCatsName: any = [];
      let arrayString: string;
      return items.filter(item => {
        itemCats = [];
        itemCatsName = [];

        // check if metadataList present or not in question object
        if (typeof item.metadataList != 'undefined') {
          item.metadataList.forEach(element => {
            itemCats.push(element.categoryId);
            if(typeof element.category != 'undefined'){
              itemCatsName.push(element.category.toLowerCase()+'#'+element.categoryId);
            }else{
              itemCatsName.push('other'+'#'+element.categoryId);// If category key is not present in question metadata list then it will be under Other category.
            }
          });
        }
        var retVal = false;
        if (filter.questions.length > 0 && (itemCats.length > 0 && filter.cats.length > 0)) {
          // if applying question and category both the filter
          retVal =  filter.questions.indexOf(item.typeDesc) > -1 && this.performANDOROperation(itemCatsName,processFilterTest);
        } else if (filter.questions.length == 0 && (itemCats.length > 0 && filter.cats.length > 0)) {
          // if applying only category filter
          retVal =  this.performANDOROperation(itemCatsName,processFilterTest);
        } else if (filter.questions.length > 0 && filter.cats.length == 0) {
          // if applying question filter only
          retVal =  filter.questions.indexOf(item.typeDesc) > -1;
        }
        
        return retVal;
      });
    } else {
        // if no filters applied
        return items;
    }
  }
  // It will check every category like difficulty,blooms and topic present or not in Items metadata
  performANDOROperation( itemCatsName:any,processFilterTest:any ){

    var categoryKeys = Object.keys(processFilterTest);
    var allAndCond = [];
    for( var t=0;t< categoryKeys.length;t++){
      allAndCond.push(this.ORCondChecking(processFilterTest[categoryKeys[t]],itemCatsName));
    }
    return allAndCond.every(e=> e == true); // If all element of the array is true them it will return tru since we are performing and operation
    }
    // It will check any of difficulty like easy/moderate/hard type present or not in Items metadata
    ORCondChecking(selectedSubCat:any,itemMetaData:any){
      var ret = false;
      for(let i=0;i<selectedSubCat.length;i++){
        if(itemMetaData.indexOf(selectedSubCat[i]) > -1){
          ret =  true;
          break;
        }
      } 
      return ret;
    }
}
