import {
  Component,
  OnInit,
  Renderer2,
  HostListener,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ButtonType, MheOption, ButtonPurpose } from "@mhe/ngx-shared";
import { AutoUnsubscribe } from "./../../shared/components/decorator/autoUnsubscribe.decorator";
import { OrderByPipe } from "./../../shared/pipes/order-by.pipe";
import { PaginatePipe } from "./../../shared/pipes/pagination.pipe";
import { GrdFilterPipe } from "./../../shared/pipes/grd-filter.pipe";
import { AssessmentListService } from "../../shared/services/assessment-list.service";
import { ShareDataService } from "../../shared/services/shared.service";
import { KebabMenuInterface } from "./../../shared/models/kebab-menu-model";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { AlertType } from "@mhe/ngx-shared";
@Component({
  selector: "app-assessment-list",
  templateUrl: "./assessment-list.component.html",
  styleUrls: ["./assessment-list.component.scss"],
})
export class AssessmentListComponent implements OnInit {
  assessmentListdata = [];
  assessmentListdataCopy = [];
  private error;
  Math: any;
  private pageNumber = 0;
  private totalPage: number;
  public totalItems: number = 0;
  private itemsPerPage = 10;
  public collectionLength = 0;
  public searchText: string = "";
  public show = false;
  private spinner: boolean = false;
  private lastRefreshTime: number = 0;
  public qti: string = "qtiPackage";
  public docx: string = "document";
  public message: String = "";
  direction: number;
  isDesc: any;
  column: any;
  alertMessage: string = "Your test has been successfully imported";
  public kebabIndex: number;
  public alertType = AlertType;
  public assessmentListSubscription: Subscription;
  public fileDownlodSubscription: Subscription;
  public resyncSubscription: Subscription;
  public buttonType = ButtonType;
  public _buttonPurpose = ButtonPurpose;
  fileContent: string = "";
  kebabMenu: KebabMenuInterface;
  modalIsVisible: boolean = false;
  showSuccessMessage: boolean = false;
  showErrorMessage: boolean = false;
  isDisableUpload: boolean = true;
  showExportErrorMessage: boolean = false;
  errorMessage: string =
    "There was a problem importing your test. Please try again.";
  fileData: any = "";
  @ViewChild("myInput") myInputVariable: ElementRef;
  selectedOption: MheOption[] = [];
  ngModelOptions: MheOption[] = [
    {
      value: 0,
      viewValue: "Create New Test",
    },
    {
      value: 1,
      viewValue: "Import Test",
    },
  ];
  createTestText: string = "Create Test";
  //sortFlag: number = undefined;
  sortAscDsc = {
    collection_title: "dpg-icon-system-sort-white",
    modified: "dpg-icon-system-sort-white",
    isbnTitle: "dpg-icon-system-sort-white",
    isbnAuthor: "dpg-icon-system-sort-white",
    isbnEdition: "dpg-icon-system-sort-white",
    modified_format: "dpg-icon-system-sort-white",
  };
  constructor(
    private router: Router,
    private assessmentList: AssessmentListService,
    private shareDataService: ShareDataService,
    private renderer: Renderer2,
    private collectionListPipe: OrderByPipe,
    private paginate: PaginatePipe,
    private searchFilter: GrdFilterPipe
  ) {}
  ngOnInit() {
    this.assessmentListdata["collections"] = [];
    this.populateTestList();
    this.kebabMenu = {
      addNewPart: false,
      get_info: true,
      preview: false,
      pinUnpin: false,
      edit: true,
      copy: true,
      export: true,
      rename: true,
      print: false,
      delete: true,
      remove: false,
      copyAndEdit: false,
      pastUsage: false,
      editQuestion: false,
    };
  }
  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (event.target.className !== "ahe-icon ahe-icon-system-kebab") {
      this.kebabIndex = -1;
    }
    if (document.getElementsByClassName("ahe-ui-alert-modal").length > 0) {
      this.kebabIndex = -1;
    }
  }
  closeAlert(type?: string) {
    if (type == "manual") {
      this.showSuccessMessage = false;
      this.showErrorMessage = false;
    } else {
      setTimeout(() => {
        this.showSuccessMessage = false;
        this.showErrorMessage = false;
      }, 8000);
    }
  }
  public setKebabIndex(index: number) {
    this.kebabIndex = index;
  }
  showModal() {
    this.modalIsVisible = !this.modalIsVisible;
  }
  closeUploadModal() {
    this.modalIsVisible = false;
    this.modalIsVisible = false;
    this.showErrorMessage = false;
    this.errorMessage =
      "There was a problem importing your test. Please try again.";
    this.myInputVariable.nativeElement.value = "";
    this.isDisableUpload = true;
    this.selectedOption = [];
  }
  public onChange(fileList: FileList): void {
    let file = fileList[0];
    if (file !== undefined) {
      this.isDisableUpload = false;
      this.fileData = file;
    } else {
      this.isDisableUpload = true;
      this.fileData = "";
    }
  }
  showSuccessAlert(message: string) {
    this.alertMessage = message;
    this.showSuccessMessage = true;
    this.closeUploadModal();
    this.closeAlert();
  }
  uploadTestData() {
    this.modalIsVisible = true;
    const testFileData: FormData = new FormData();
    testFileData.append("files", this.fileData, this.fileData.name);
    this.assessmentList.uploadTestFile(testFileData).subscribe(
      () => {
        this.showSuccessMessage = true;
        this.showSuccessAlert("Your test has been successfully imported.");
        this.populateTestList();
      },
      (err) => {
        if (
          err.hasOwnProperty("error") &&
          err.error.hasOwnProperty("Message")
        ) {
          this.errorMessage = err.error.Message;
        }
        this.showErrorMessage = true;
      }
    );
  }
  private pageChanged(event, num) {
    let sortParam = {};
    this.assessmentListdata["collections"] = this.assessmentListdataCopy;
    this.pageNumber = event.pageIndex;
    this.displayInputText(this.searchText, false);
    sortParam = { property: this.column, direction: this.direction };
    this.assessmentListdata["collections"] = this.collectionListPipe.transform(
      this.assessmentListdata["collections"],
      sortParam
    );
    const paginateParam = {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.pageNumber,
    };
    this.assessmentListdata["collections"] = this.paginate.transform(
      this.assessmentListdata["collections"],
      paginateParam
    );
  }
  public displayInputText(event, doPaginate: boolean = true) {
    this.searchText = event;
    this.assessmentListdata["collections"] = this.assessmentListdataCopy;
    let filteredAssessmentList = this.assessmentListdataCopy;
    this.collectionLength = this.assessmentListdata["collections"].length;
    let clearSearch = false;
    if (this.searchText !== "" && this.searchText.length > 2) {
      const searchParam = { collection_title: this.searchText };
      filteredAssessmentList = this.searchFilter.transform(
        this.assessmentListdata["collections"],
        searchParam,
        false
      );
    } else {
      clearSearch = true;
    }
    this.collectionLength = filteredAssessmentList.length;
    if ((clearSearch && doPaginate) || this.collectionLength <= 10) {
      this.pageNumber = 0;
    }
    if (doPaginate) {
      const paginateParam = {
        itemsPerPage: this.itemsPerPage,
        currentPage: this.pageNumber,
      };
      if (filteredAssessmentList != undefined) {
        filteredAssessmentList = this.paginate.transform(
          filteredAssessmentList,
          paginateParam
        );
      }
    }
    this.assessmentListdata["collections"] = filteredAssessmentList;
  }
  private populateTestList() {
    this.assessmentListSubscription = this.assessmentList
      .getListCollection(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .subscribe(
        (data) => {
          this.assessmentListdata = data;
          if (
            this.assessmentListdata &&
            this.assessmentListdata["collections"]
          ) {
            this.collectionLength =
              this.assessmentListdata["collections"].length;
            this.totalItems = this.assessmentListdata["collections"].length;
            this.assessmentListdataCopy =
              this.assessmentListdata["collections"];
            this.displayInputText(this.searchText);
          }
        },
        (error) => {
          this.error = error;
        }
      );
  }
  public changeSize(event) {
    const element = this.renderer.selectRootElement("#searchfilter");
    element.style = "width:200px;";
  }
  toggle() {
    this.show = !this.show;
  }
  setDefault() {
    for (let sortobj in this.sortAscDsc) {
      this.sortAscDsc[sortobj] = "dpg-icon-system-sort-white";
    }
  }
  sort(property) {
    this.setDefault();
    var ctrl = this;
    let sortParam = {};
    ctrl.isDesc = !this.isDesc; //change the direction
    // ctrl.column = property;
    ctrl.direction = this.isDesc ? 1 : -1;
    this.sortAscDsc[property] = this.isDesc
      ? "dpg-icon-system-sort-up-white"
      : "dpg-icon-system-sort-down-white";
    ctrl.column = property == "modified_format" ? "modified" : property;
    sortParam = { property: ctrl.column, direction: ctrl.direction };
    ctrl.assessmentListdata["collections"] = ctrl.collectionListPipe.transform(
      this.assessmentListdataCopy,
      sortParam
    );
    this.displayInputText(this.searchText);
  }
  public refreshList() {
    let dateInSecs = Math.trunc(Date.now() / 1000);
    const localRenderer = this.renderer;
    const element = localRenderer.selectRootElement("#refreshSpinner");
    localRenderer.addClass(element, "fa-spin");
    setTimeout(function () {
      localRenderer.removeClass(element, "fa-spin");
    }, 10000);
    if (this.lastRefreshTime == 0 || dateInSecs - this.lastRefreshTime > 15) {
      this.populateTestList();
      this.lastRefreshTime = dateInSecs;
    }
  }

  public resync(collectionId: string, collectionTitle: string) {
    this.resyncSubscription = this.assessmentList
      .resync(collectionId, collectionTitle)
      .subscribe(
        (data) => {
          this.showSuccessMessage = true;
          this.showSuccessAlert("Regeneration initiated for " + collectionTitle);
          this.populateTestList();
        },
        (err) => {
          if (
            err.hasOwnProperty("error") &&
            err.error.hasOwnProperty("Message")
          ) {
            this.errorMessage = err.error.Message;
          }
          this.showErrorMessage = true;
        }
      );
  }

  public downloadFile(
    collectionId: string,
    exportType: string,
    fileName: string
  ) {
    this.fileDownlodSubscription = this.assessmentList
      .downloadFile(collectionId, exportType)
      .subscribe(
        (data) => {
          var blob = new Blob([data], { type: "application/octet-stream" });
          var downloadURL = window.URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = downloadURL;
          link.setAttribute("target", "_blank");
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          const self = this;
          setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadURL);
          }, 100);
        },
        (err) => {
          this.showExportErrorMessage = true;
        }
      );
  }
  createTest(testVal: MheOption[]) {
    if (testVal[0].value === 0) {
      this.router.navigate(["/editAssessment", "create"]);
    } else {
      this.showModal();
    }
  }
}
