import {
  Component,
  OnInit,
  NgZone,
  HostListener,
  AfterViewInit,
  Renderer2,
  ElementRef,
  ViewChild,
  QueryList,
  ViewChildren,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import { SelectedBankQuestionsService } from "./../../shared/services/selected-bank-questions.service";
import { ShareDataService } from "../../shared/services/shared.service";
import { DragulaService } from "ng2-dragula";
import { BehaviorSubject, Subscription } from "rxjs";
import { KebabMenuInterface } from "./../../shared/models/kebab-menu-model";
import { QuestionFilterPipe } from "./../../shared/pipes/question-filter.pipe";
import { Collection } from "./../../shared/models/collection.model";
import { References } from "../../shared/models/references.model";
import { MetaData } from "../../shared/models/metadata.model";
import { HeaderData } from "./../../shared/models/header-items";
import { FilterTestCategoryComponent } from "../filter-test-category/filter-test-category.component";
import { DndModule } from "ng2-dnd";
import { AlertType, ButtonType, MheOption, PageEvent } from "@mhe/ngx-shared";
import { ButtonPurpose } from "@mhe/ngx-shared";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { SourceBankComponent } from "src/app/shared/components/source-bank/source-bank.component";
import { BankModel } from "src/app/shared/models/bank.model";
import { ExamStatisticRes } from "src/app/shared/models/examstatres.model";
@Component({
  selector: "app-source-select",
  templateUrl: "./source-select.component.html",
  styleUrls: ["./source-select.component.scss"],
})
export class SourceSelectComponent implements OnInit, AfterViewInit {
  questionList: any;
  questionList1: any;
  bankObj: any;
  ezidObj: any;
  subscribedData: any;
  screenHeight: number;
  screenWidth: number;
  showBank: boolean = true;
  pinnedQuestionList = [];
  pinnedQuestionListIndex = [];
  previewUrl = [];
  qIndex: Number;
  closeModalPopUp: Boolean = false;
  sourcePickerCompHeight: number;
  sourcePickerDestiHeight: number;
  searchWidthLeft: number = 0;
  leftAddButtonAlignment: number = 0;
  totalQuestionCount: number = 0;
  leftFilterAlignment: number = 0;
  rightFilterAlignment: number = 0;
  rightSearchAlignment: number = 0;
  searchWidthRight: number = 0;
  sourcePickerTableCompHeight: number;
  headerData: HeaderData = new HeaderData();
  collection: Collection;
  operationMode = "sourceSelect";
  transferSuccessMsg = "";
  collectionId: String = "";
  textSearchLeft: string = "";
  selectedTitle = { selected: false };
  renameActivated = true;
  showQuestion: boolean = false;
  dragOperation = false;
  draggedArrayLength: number;
  selectedQuestions = { select: true };
  kebabMenu: KebabMenuInterface;
  searchedTextRight: string = "";
  totalSearchCountRight: number = 0;
  sectionBreakKebabMenu: KebabMenuInterface;
  partQuestionKebabMenu: KebabMenuInterface;
  panelExpanded: boolean = false;
  hasExpanded: boolean = false;
  panelExpandedRight: boolean = false;
  panelClosed: boolean = false;
  truncateTitle: number = 50;
  truncateChapter: number = 34;
  showCreateButton: boolean = true;
  public message =
    "Are you sure you want to remove this question from your test?";
  public alertMessage = "Points should be in between 1 to 99";
  public size = { width: 302 };
  public showModal = false;
  public modalShow = true;
  public buttonType = ButtonType;
  public _buttonPurpose = ButtonPurpose;
  public showSettings = false;
  public selectedQuestionsListObj;
  public selectedQuestionsList = [];
  public editQuestionList = [];
  private arrayIndex = 0;
  private questionIdArray = [];
  public hideHeader: boolean = false;
  COPYABLE = "COPYABLE";
  step: String = "step1";
  public tabIndex = 0;
  enableAdd = 0;
  count: any = 0;
  filterJsonObject: any = {};
  public algoImgUrl =
    environment.assests + "/assets/img/connect_sprite_icon2.png";
  public kebabIndex: number;
  private sectionBreakRemoveObj: any;
  private sectionPartsRemoveObj: any;
  public showSectionBreakQuestionDeleteModal: boolean = false;
  public deleteMessage: String;
  public secBreakRemove: boolean;
  public partQuestionRemove: boolean;
  public searchText = "";
  public DeleteModalHeader: String;
  public isPreviewTestQuestions: boolean = false;
  public isPreviewSourceQuestions: boolean = false;
  public previewQuestionsList = [];
  public previewSourceQuestionsList = [];
  subs = new Subscription();
  public previewHeaderText: string = "Previewing all questions";
  public questionIndex: any = "0";
  public sectionBreakQuestionStore = [];
  public selectedQuestionsListObjStore;
  public pointAlignment: string = "point-alignment";
  public typeAlignment: string = "type-alignment";
  showSuccessMessage: boolean = false;
  alertSuccessMessage: string = "Question(s) successfully added to Test";
  modalIsVisible: boolean = false;
  updatePointsValidationObject: any = {
    isDisableUpdatePoints: true,
    callFromUpdatePoints: false,
    showInvalidMessage: "",
  };

  bulkUpdatePointInputValue: string = "";
  bulkUpdatePointsSelectedQuestionCount: number = 0;
  public alertType = AlertType;
  selectedQuestionArray = new Map();
  currentPageIndex: number = 0;
  firstItemIndex: number = 0;
  totalLength: number = 0;
  pageSize: number = 20;
  questionCollection: any = {};
  ngModelOptions: MheOption[] = [
    {
      value: 0,
      viewValue: "Create New Test",
    },
    {
      value: 1,
      viewValue: "Import Test",
    },
  ];
  createTestText: string = "Create Test";
  @ViewChild(SourceBankComponent) sourceBank: SourceBankComponent;
  isExpand: boolean = true;
  textExpand: string = "Expand All";
  //@Output() emitQuestionEdit = new EventEmitter<any>();
  examStatisticsModalIsVisible: boolean = false;
  error: String = "";
  examStatsticsList: any = ExamStatisticRes;
  constructor(
    private elem: ElementRef,
    private render: Renderer2,
    private questionListPipe: QuestionFilterPipe,
    private sourceBankQuestion: SelectedBankQuestionsService,
    private router: Router,
    private dragula: DragulaService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private shareDataService: ShareDataService
  ) {
    dragula.destroy("SELECTED-QUESTIONS"); // Remove group before creating new
    dragula.createGroup("SELECTED-QUESTIONS", {
      moves: (el, container, handle) => {
        return handle.parentElement.className === "handles";
      },
    });
    this.dragula.dragend().subscribe((value) => {
      // reset all the index value after one/multiple objects inserted in between array

      this.questionIdArray = [];
      this.assignArrayIndex();
      this.arrayPinnedQuestions();
      this.selectedQuestionsListObj = JSON.parse(
        JSON.stringify(this.selectedQuestionsListObj)
      );
    });
    router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === "popstate") {
        this.router.navigate(["/list"]);
      }
    });
  }

  @ViewChild(FilterTestCategoryComponent) child: FilterTestCategoryComponent;

  ngOnInit() {
    this.onResize();
    this.kebabMenu = {
      addNewPart: false,
      get_info: false,
      preview: true,
      pinUnpin: true,
      edit: false,
      copy: false,
      export: false,
      rename: false,
      print: false,
      delete: false,
      remove: true,
      pastUsage: true,
      editQuestion: true,
      copyAndEdit: true,
    };
    this.sectionBreakKebabMenu = {
      addNewPart: true,
      get_info: false,
      preview: true,
      pinUnpin: true,
      edit: false,
      copy: false,
      export: false,
      rename: false,
      print: false,
      delete: false,
      remove: true,
      pastUsage: true,
      editQuestion: true,
      copyAndEdit: true,
    };
    this.partQuestionKebabMenu = {
      addNewPart: false,
      get_info: false,
      preview: true,
      pinUnpin: false,
      edit: false,
      copy: false,
      export: false,
      rename: false,
      print: false,
      delete: false,
      remove: true,
      pastUsage: true,
      editQuestion: true,
      copyAndEdit: true,
    };

    this.shareDataService.getCurrentStep().subscribe((stepName) => {
      this.step = stepName;
    });

    this.headerData = this.shareDataService.getHeaderData();
    if (this.headerData !== undefined && this.headerData != null) {
      this.collectionId = this.headerData.getCollectionId();
    }
    this.getEditData();
    this.setCollectionInfoInHeader();
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (event.target.className !== "ahe-icon ahe-icon-system-kebab") {
      if (event.srcElement.id != "kebab-item-add-new-part") {
        this.kebabIndex = -1;
      }
    }
    if (document.getElementsByClassName("ahe-ui-alert-modal").length > 0) {
      this.kebabIndex = -1;
    }
    if (event.target.id === "expandPanel") {
      this.truncateTitle = 100;
      this.truncateChapter = 50;
      // this.panelExpanded = true;
    } else if (event.target.id === "collapsePanel") {
      this.truncateTitle = 50;
      this.truncateChapter = 34;
      //  this.panelExpanded = false;
    }
  }

  public setKebabIndex(index: number) {
    this.kebabIndex = index;
  }
  expandPanel(panel: string) {
    if (panel === "left") {
      this.panelExpanded = !this.panelExpanded;
      setTimeout(() => {
        this.setSearchDiv();
      }, 100);
    } else {
      this.panelExpandedRight = !this.panelExpandedRight;
      setTimeout(() => {
        this.setSearchDivRight();
      }, 100);
    }
  }
  receiveDataWhileChecked($event) {
    this.enableAdd = $event;
    this.setSearchDiv();
  }

  public createCollectionObject() {
    this.collection = new Collection(
      Number(this.collectionId) || 0,
      Date.now(),
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    const metaDataArr: MetaData[] = new Array();
    if (this.headerData) {
      metaDataArr.push(new MetaData("title", this.headerData.getTitle()));
    } else {
      metaDataArr.push(new MetaData("title", "Unnamed Test"));
    }
    this.collection.setMetaData(metaDataArr);

    const questionsList = this.selectedQuestionsListObj;
    const isbn = localStorage.getItem("isbn");
    let algoPresent = false;
    if (questionsList && questionsList.length > 0) {
      const referencesArr: References[] = new Array();
      this.selectedQuestionsListObj.forEach((question) => {
        let mpartid = question.hasOwnProperty("multiPartId")
          ? question["multiPartId"]
          : "";
        let reftype = question.hasOwnProperty("refType")
          ? question["refType"]
          : "";
        let edited = question.hasOwnProperty("edited") ? question["edited"] : 0;
        //let parts   = ( question.hasOwnProperty("parts") ? question['parts'] : [] );
        var parts = [];
        if (question.hasOwnProperty("parts")) {
          question.parts.forEach((partQuestion) => {
            let quesparts = {};
            quesparts["tid"] = partQuestion["ezid"];
            quesparts["qid"] = partQuestion["qid"];
            quesparts["algo"] = partQuestion["algo"];
            quesparts["types"] = partQuestion["type"];
            quesparts["points"] = partQuestion["points"];
            quesparts["pinned"] = partQuestion["pinned"];
            quesparts["edited"] = partQuestion["edited"];
            quesparts["multiPartId"] = partQuestion["multiPartId"];
            quesparts["refType"] = partQuestion["refType"];
            quesparts["qTitle"] = partQuestion["title"];
            quesparts["bankName"] = this.shareDataService.getBankName();
            parts.push(quesparts);
          });
        }

        let updatedQuestion = new References(
          question["ezid"],
          question["qid"],
          question["algo"],
          question["type"],
          question["points"],
          question["pinned"],
          question["title"],
          this.shareDataService.getBankName(),
          mpartid,
          reftype,
          parts,
          edited
        );

        if (question["isbn"] === undefined || question["isbn"] == "") {
          updatedQuestion.setIsbn(isbn);
        } else {
          updatedQuestion.setIsbn(question["isbn"]);
        }
        referencesArr.push(updatedQuestion);
        if (updatedQuestion.getAlgo() && !algoPresent) {
          algoPresent = true;
        }
      });
      this.collection.setReferences(referencesArr);
    } else {
      this.collection.setClearedQuestion(true);
    }
    this.shareDataService.setCollectionDetails(this.collection);
    this.shareDataService.setCollectionSubject(this.collection);
    this.shareDataService.setAlgoPresent(algoPresent);
    return this.collection;
  }

  public setCollectionInfoInHeader() {
    let totalPoints = 0,
      questionLength = 0;
    if (this.selectedQuestionsListObj) {
      const questionsList = this.selectedQuestionsListObj;
      if (questionsList && questionsList.length > 0) {
        let totalPoints = 0;

        for (const question of questionsList) {
          if (question.hasOwnProperty("parts")) {
            var totalPointsPart = 0;
            for (const partsPoints of question.parts) {
              totalPointsPart += +partsPoints.points;
            }

            totalPoints += totalPointsPart;
          } else {
            totalPoints += +question.points;
          }
        }
        let questionCount = 0;
        questionsList.map((value) => {
          if (!value.hasOwnProperty("parts")) {
            questionCount++;
          }
          if (value?.parts && value?.parts.length > 0) {
            value?.parts.map(() => {
              questionCount++;
            });
          }
        });

        this.headerData.setTotalPoints(totalPoints.toFixed(2));
        this.headerData.setTotalQuestions(questionCount);
        this.shareDataService.setHeaderData(this.headerData);
        this.totalQuestionCount = questionCount;
      } else {
        this.headerData.setTotalPoints(totalPoints);
        this.headerData.setTotalQuestions(questionLength);
        this.totalQuestionCount = questionLength;
        this.shareDataService.setHeaderData(this.headerData);
      }
      return this.headerData;
    }
  }

  public arrayPinnedQuestions() {
    this.pinnedQuestionListIndex = [];
    this.pinnedQuestionList = [];
    this.selectedQuestionsListObj = this.selectedQuestionsListObj.map(
      (item) => {
        const index = this.selectedQuestionsListObj.indexOf(item);
        if (item.pinned) {
          this.pinnedQuestionListIndex.push(index);
          this.pinnedQuestionList.push(item);
        }
        return item;
      }
    );
    this.createCollectionObject();
  }

  public assignArrayIndex() {
    const list = [];
    this.selectedQuestionsListObj = this.selectedQuestionsListObj.map(
      (item, ind) => {
        item.index = ind;
        item.previewIndex = item.index;
        this.questionIdArray.push(item.qid);
        if (list.indexOf(item.qid) === -1) {
          list.push(item.qid);
        }
        this.shareDataService.setDroppedQuestionId(list);
        return item;
      }
    );
    // Share data to footer component for save and exit
    this.createCollectionObject();
    this.setCollectionInfoInHeader();
  }

  public pointsChanged(event) {
    // reset all the index value after one/multiple objects inserted in between array when input value changed
    this.arrayIndex = 0;
    this.questionIdArray = [];
    this.assignArrayIndex();
  }

  transferDataSuccess(event) {
    this.shareDataService.sendSpinnerData(false);
    const idVal = event.mouseEvent.target.id;
    const idIndex = idVal.match(/[0-9]+/g);
    // check to observe new data or selected data is dragged and push it to array
    const firstArrayItem = JSON.parse(
      JSON.stringify(event.dragData[0] || null)
    );
    const secondArrayItem = JSON.parse(
      JSON.stringify(event.dragData[1] || null)
    );

    if (
      firstArrayItem &&
      firstArrayItem.length > 0 &&
      firstArrayItem[0].parts
    ) {
      //if(!firstArrayItem[0].selected) {
      firstArrayItem[0].parts.map((item) => (item.selected = false));
      //}
    }

    if (
      secondArrayItem &&
      secondArrayItem.length > 0 &&
      secondArrayItem[0].parts
    ) {
      //if(secondArrayItem[0].selected && !secondArrayItem[0].selected) {
      secondArrayItem[0].parts.map((item) => {
        item.selected = false;
      });
      //}
    }

    firstArrayItem.forEach((item) => {
      // Comparing objects
      if (JSON.stringify(item) === JSON.stringify(secondArrayItem[0])) {
        secondArrayItem.splice(0, 1);
      }
      //uncheck after transfer
      item.selected = false;
    });
    if (event.dragData[0].length > 0) {
      if (idIndex !== null || undefined) {
        this.selectedQuestionsListObj.splice(idIndex, 0, ...firstArrayItem);
      } else {
        this.selectedQuestionsListObj.push(...firstArrayItem);
      }
    }
    if (secondArrayItem[0]) {
      if (idIndex !== null || undefined) {
        this.selectedQuestionsListObj.splice(idIndex, 0, ...secondArrayItem);
      } else {
        this.selectedQuestionsListObj.push(...secondArrayItem);
      }
    }

    //Set message on successfull drop
    this.transferSuccessMsg = "Successfull drop";
    // reset all the index value after one/multiple objects inserted in between array

    this.questionIdArray = [];
    this.assignArrayIndex();
    this.arrayPinnedQuestions();
    this.shareDataService.setSelectedQuestionList(
      this.selectedQuestionsListObj
    );
    this.assignSelectedQuestionArray();
    this.shareDataService.sendSpinnerData(true);
    this.assignArrayIndexSectionBreakQuestion(this.selectedQuestionsListObj);
    this.showSuccessAlert("Question(s) successfully added to Test");
    this.dataChanged();
  }

  addToRight = function (event, selectedQuestion?) {
    this.shareDataService.sendSpinnerData(false);
    let addQuestionsList = [];
    if (selectedQuestion) {
      addQuestionsList = JSON.parse(JSON.stringify(selectedQuestion));
    } else {
      addQuestionsList = JSON.parse(
        JSON.stringify(this.shareDataService.getSelectedQuestions())
      );
      //apply filter if filter is applied
      addQuestionsList = this.questionListPipe.transform(
        addQuestionsList,
        this.filterJsonObject,
        true
      );
    }

    addQuestionsList.forEach((item) => {
      if (item.selected) {
        //To remove selection while added to right
        item.selected = false;
      }
      //item.index = this.arrayIndex++;
      let randomNumber = Math.random().toString().substring(2);
      item.uniqueId = item.qid + "-" + randomNumber;
      if (item.hasOwnProperty("parts")) {
        item.parts = item.parts.map((partQuestion, pindex) => {
          if (partQuestion.selected) {
            //To remove selection while added to right
            partQuestion.selected = false;
          }
          partQuestion.uniqueId = partQuestion.qid + "-" + randomNumber;
          partQuestion.parentUniqueId = item.uniqueId;
          return partQuestion;
        });
      }
    });
    this.selectedQuestionsListObj.push(...addQuestionsList);

    //Set message on successfull drop
    this.transferSuccessMsg = "Successfull drop";

    // reset all the index value after one/multiple objects inserted in between array

    this.questionIdArray = [];
    this.assignArrayIndex();
    this.assignArrayIndexSectionBreakQuestion(this.selectedQuestionsListObj);
    this.arrayPinnedQuestions();

    // Clear array after adding to right

    this.setKebabPreviewIndex();
    if (this.selectedQuestionsListObjStore?.length > 0) {
      this.selectedQuestionsListObjStore.push(...addQuestionsList);
    }

    setTimeout(() => {
      this.pointsAlignment();
    }, 100);
    this.shareDataService.sendSpinnerData(true);
    this.assignSelectedQuestionArray();
    this.showSuccessAlert("Question(s) successfully added to Test");
    this.dataChanged();
    this.enableAdd = 0;
    this.setSearchDiv();
  };
  currentPage(dataArray) {
    const collectionArray = {};
    let pageNum = 0;
    for (let i = 0; i < dataArray.length; i += this.pageSize) {
      const chunk = dataArray.slice(i, i + this.pageSize);
      collectionArray[pageNum] = chunk;
      pageNum++;
    }
    this.totalLength = dataArray.length;
    this.questionCollection = { ...collectionArray };
  }
  get currentData() {
    this.dataChanged();
    return this.questionCollection[this.currentPageIndex] || [];
  }
  fetchPage(event: PageEvent) {
    this.currentPageIndex = event.pageIndex;
    this.firstItemIndex = event.firstItemIndex;
    if (!!this.questionCollection[this.currentPageIndex]) {
      return;
    }
  }
  OnCheckboxSelect(
    bank,
    event,
    qtype,
    dependent = false,
    parentQuestion,
    partindex
  ) {
    var that = this;
    var index = -1;
    let leng = this.selectedQuestionsList.length;
    for (let i = 0; i < leng; i++) {
      if (this.selectedQuestionsList[i].uniqueId == bank.uniqueId) {
        index = i;
        break;
      }
    }
    if (qtype == "part" && dependent === true) {
      var objIndex = this.selectedQuestionsListObj.indexOf(parentQuestion);
      let partQues = bank;
      bank = parentQuestion;
      bank.parts[partindex].selected = event;
      index = this.selectedQuestionsList.indexOf(bank);
      if (index === -1) {
        this.selectedQuestionsList.push(bank);
      } else {
        this.selectedQuestionsList[index] = bank;
      }
      var totalPartQuestion = bank.parts.length;
      if (totalPartQuestion == 1) {
        // If only one part question is present then parent question will be selected.
        if (event) {
          bank.selected = true;
          this.selectedQuestionsListObj[objIndex].selected = true;
        } else {
          this.selectedQuestionsListObj[objIndex].selected = false;
          bank.selected = false;
          if (index !== -1) {
            this.selectedQuestionsList.splice(index, 1);
          }
        }
      } else {
        // This will select/unselect the parent checkbox depending upon the child selection. if all child selected then parent will be selected automatically same as vice versa
        var checkedQuestion = 0;
        bank.parts.forEach((subpa) => {
          if (subpa.selected === true) {
            checkedQuestion++;
          }
        });
        if (totalPartQuestion == checkedQuestion) {
          bank.selected = true;
          this.selectedQuestionsListObj[objIndex].selected = true;
        } else {
          bank.selected = false;
          this.selectedQuestionsListObj[objIndex].selected = false;
          if (checkedQuestion == 0 && index !== -1) {
            // This is the last uncheck part question. So parent sb question will remove from seleted question list
            this.selectedQuestionsList.splice(index, 1);
          }
        }
      }
    } else if (qtype == "multipart") {
      bank.selected = event;
      index = this.selectedQuestionsList.indexOf(bank);
      if (index === -1) {
        this.selectedQuestionsList.push(bank);
      } else {
        this.selectedQuestionsList[index] = bank;
      }
      // For All child selection ..
      var objIndex = this.selectedQuestionsListObj.indexOf(bank);
      if (event) {
        this.selectedQuestionsListObj[objIndex].parts.forEach((subparts) => {
          subparts.selected = true;
        });
      } else {
        this.selectedQuestionsListObj[objIndex].parts.forEach((subparts) => {
          subparts.selected = false;
        });
        if (index !== -1) {
          this.selectedQuestionsList.splice(index, 1);
        }
      }
    } else {
      if (event) {
        if (index === -1) {
          bank.selected = true;
          that.selectedTitle.selected = false;
          this.selectedQuestionsList.push(bank);
        }
      } else {
        if (index !== -1) {
          bank.selected = false;
          that.selectedTitle.selected = false;
          this.selectedQuestionsList.splice(index, 1);
        }
      }
    }

    that.renameActivated = this.selectedQuestionsList.length > 0 ? false : true;
  }

  pinUnpinQuestion(obj) {
    if (this.selectedQuestionsListObj[obj.index].pinned != undefined) {
      this.selectedQuestionsListObj[obj.index].pinned =
        !this.selectedQuestionsListObj[obj.index].pinned;
    }
    this.createCollectionObject();
    this.arrayPinnedQuestions();
  }

  sortOnlyUnpinned(event) {
    if (event != undefined) {
      this.selectedQuestionsListObj = event;
      for (
        let questions = 0;
        questions < this.selectedQuestionsListObj.length;
        questions++
      ) {
        if (this.selectedQuestionsListObj[questions].pinned) {
          this.selectedQuestionsListObj.splice(questions, 1);
          questions--;
        }
      }
      //insert pinned one to designated pre determined position
      for (
        let pinnedArray = 0;
        pinnedArray < this.pinnedQuestionListIndex.length;
        pinnedArray++
      ) {
        this.selectedQuestionsListObj.splice(
          this.pinnedQuestionListIndex[pinnedArray],
          0,
          this.pinnedQuestionList[pinnedArray]
        );
      }
      // reset all the index value after one/multiple objects inserted in between array

      this.questionIdArray = [];
      this.assignArrayIndex();
    }
  }

  selectAllSelctedSource(event) {
    var that = this;
    if (event) {
      this.selectedTitle = { selected: true };
    } else {
      this.selectedTitle = { selected: false };
    }
    this.selectedQuestionsListObj.forEach((item) => {
      item.selected = event;

      if (item.hasOwnProperty("parts")) {
        item.parts = item.parts.map((partQuestion) => {
          partQuestion.selected = event;
          return partQuestion;
        });
      }

      // if (item.selected && this.selectedQuestionsList.indexOf(item) === -1) {
      if (item.selected) {
        this.selectedQuestionsList.push(item);
      } else if (!item.selected) {
        this.selectedQuestionsList = [];
      }
    });
    that.renameActivated = this.selectedQuestionsList.length > 0 ? false : true;
  }

  private getEditData() {
    this.getBankDetails();
    if (this.shareDataService.getLandingState() == "zero-state") {
      this.selectedQuestionsListObj = [];
    }
    if (this.shareDataService.getLandingState() == "edit-state") {
      if (
        this.shareDataService.getCollectionObjectDetails() != undefined ||
        this.shareDataService.getCollectionObjectDetails() != null
      ) {
        this.selectedQuestionsListObj =
          this.shareDataService.getCollectionObjectDetails().references;
        this.selectedQuestionsListObj.forEach((item) => {
          let randomNumber = Math.random().toString().substring(2);
          item.uniqueId = item.qid + "-" + randomNumber;
          if (item.hasOwnProperty("parts")) {
            item.parts = item.parts.map((partQuestion) => {
              partQuestion.uniqueId = partQuestion.qid + "-" + randomNumber;
              partQuestion.parentUniqueId = item.uniqueId;
              return partQuestion;
            });
          }
        });
        this.selectedQuestionsListObjStore = JSON.parse(
          JSON.stringify(this.selectedQuestionsListObj)
        );
      }
    }
    // reset all the index value after one/multiple objects inserted in between array

    this.questionIdArray = [];
    this.assignArrayIndex();
    this.assignArrayIndexSectionBreakQuestion(this.selectedQuestionsListObj);
    this.arrayPinnedQuestions();
    this.createCollectionObject();
    this.assignSelectedQuestionArray();
  }

  public trackByIndex(index, item) {
    return index;
  }
  checkQuestionLoad(event) {
    this.showBank = event;
  }
  getData() {
    this.questionList = "Example";
  }

  getBankDetails() {
    this.shareDataService.getBankObject().subscribe(
      (data) => {
        this.bankObj = data;
      },
      (error) => {
        console.log("in error of getBankDetails : ", error);
      }
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    /**set source picker component height */
    this.sourcePickerCompHeight = this.screenHeight - 335;
    this.sourcePickerDestiHeight = this.screenHeight - 386;
    this.sourcePickerTableCompHeight = this.screenHeight - 420;
    this.setSearchDiv();
    this.setSearchDivRight();
  }
  ngAfterViewInit() {
    this.setSearchDiv();
    this.setSearchDivRight();
    this.cdr.detectChanges();
  }
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  setSearchDiv() {
    const leftPanel = document.getElementById("left-panel");
    if (leftPanel != null) {
      const clientWidth = leftPanel.clientWidth;
      if (this.enableAdd) {
        this.searchWidthLeft = clientWidth - 280;
        this.leftFilterAlignment = clientWidth - 217;
        this.leftAddButtonAlignment = clientWidth - 146;
      } else {
        this.searchWidthLeft = clientWidth - 149;
        this.leftFilterAlignment = clientWidth - 86;
      }
    }
  }
  setSearchDivRight() {
    const rightPanel = document.getElementById("right-panel");
    if (rightPanel != null) {
      const clientWidth = rightPanel.clientWidth;
      if (this.panelExpandedRight) {
        this.searchWidthRight = clientWidth - 217;
        this.rightSearchAlignment = 52;
        this.rightFilterAlignment = clientWidth - 153;
      } else {
        this.searchWidthRight = clientWidth - 218;
        this.rightSearchAlignment = clientWidth + 52;
        this.rightFilterAlignment =
          this.searchWidthRight + this.rightSearchAlignment + 11;
      }
    }
  }
  OpenPanel(section, index, item, event, question) {
    this.child.OpenPanel("mainPanel", index, item, event, question);
  }

  receiveMessage($event) {
    this.count = $event;
  }

  receiveFilter($event) {
    this.filterJsonObject = JSON.parse(JSON.stringify($event));
  }

  modalOptOut($event) {
    this.modalShow = false;
  }

  removeSelected(event) {
    if (!this.modalShow) {
      this.modalOkJob(event);
    } else {
      this.showModal = true;
    }
  }
  setRemoveSelected(event) {
    this.modalShow = !event;
  }
  executePartQuestionRemove(params) {
    this.selectedQuestionsListObj.forEach((item, mainIndex) => {
      var sbindex;

      if (params.parentUniqueId == item.uniqueId) {
        sbindex = item.index;
        const index = params.index;
        const pIndex = this.selectedQuestionsListObj[mainIndex].parts.find(
          (x) => x.qid == params.qid
        );
        this.selectedQuestionsListObj[mainIndex].parts.splice(pIndex.index, 1);
        //this.selectedQuestionsListObj[sbindex].parts.splice(index, 1);

        if (item.parts.length == 0) {
          // If no question present under the section break then delete the section break
          this.selectedQuestionsListObj.splice(sbindex, 1);
          this.arrayIndex = 0;
          this.questionIdArray = [];

          this.showSectionBreakQuestionDeleteModal = false;
        }
        this.partQuestionRemove = false;
      }
    });

    this.assignArrayIndex();
    this.assignArrayIndexSectionBreakQuestion(this.selectedQuestionsListObj);
    this.setKebabPreviewIndex();
  }
  softSourceDelete(obj) {
    if (obj.refType == "multipart") {
      this.sectionBreakRemoveObj = obj;
      this.DeleteModalHeader = "Delete Section Break";
      this.deleteMessage = "msg1";
      this.secBreakRemove = true;
      this.toggleAddRemoveButtonPreviewModal(obj, false);
      this.showSectionBreakQuestionDeleteModal = true;
    } else if (
      obj.refType == "scalar" &&
      obj.hasOwnProperty("multiPartId") &&
      obj.multiPartId != ""
    ) {
      //this.partQuestionRemove = true
      //this.deleteMessage = 'Removing the section break will remove all of its parts.'

      let noOfPartQuestion;
      this.selectedQuestionsListObj.forEach((item) => {
        if (obj.multiPartId == item.multiPartId) {
          noOfPartQuestion = item.parts.length;
        }
      });

      if (noOfPartQuestion == 1) {
        this.showSectionBreakQuestionDeleteModal = true;
        this.partQuestionRemove = true;
        this.secBreakRemove = false;
        this.sectionPartsRemoveObj = obj;
        this.DeleteModalHeader = "Delete Question";
        this.deleteMessage = "msg2";
        this.toggleAddRemoveButtonPreviewModal(obj, false);
      } else {
        this.executePartQuestionRemove(obj);
      }
    } else {
      this.executeSoftSourceDelete(obj);
    }
  }

  executeSoftSourceDelete(obj) {
    // delete from selected question list

    if (this.selectedQuestionsList?.length > 0) {
      let selectedQuestionsListLength = this.selectedQuestionsList.length - 1;
      for (let i = 0; i <= selectedQuestionsListLength; i++) {
        if (this.selectedQuestionsList[i].qid == obj.qid) {
          this.selectedQuestionsList.splice(i, 1);
          break;
        }
      }
    }
    // delete from right hand panel
    this.selectedQuestionsListObj.forEach((item) => {
      if (obj.uniqueId == item.uniqueId) {
        const index = this.selectedQuestionsListObj.indexOf(item);
        this.selectedQuestionsListObj.splice(index, 1);
        this.shareDataService.setDeletedQuestionId(item);
        // reset all the index value after one/multiple objects inserted in between array

        this.questionIdArray = [];
        this.assignArrayIndex();
        this.assignArrayIndexSectionBreakQuestion(
          this.selectedQuestionsListObj
        );
      }
    });
    this.showSectionBreakQuestionDeleteModal = false;
    this.setKebabPreviewIndex();
  }

  public getOnlinePreviewUrl(clickedItem) {
    let selectedObject = false;

    this.selectedQuestionsListObj.map((item) => {
      if (item.selected == true && item.qid == clickedItem.qid)
        selectedObject = true;

      if (item?.parts) {
        item.parts.map((item) => {
          if (item.selected == true && item.qid == clickedItem.qid)
            selectedObject = true;
        });
      }
    });
    if (selectedObject) {
      this.previewTestQuestions();
      this.previewQuestionsList.map((x, index) => {
        if (x.uniqueId == clickedItem.uniqueId) this.qIndex = index;
      });
    } else {
      this.isPreviewTestQuestions = true;
      this.pushPartQuestionPreviewQuestionsList();
      this.setPreviewQuestionIndex();
      this.qIndex = this.previewQuestionsList.find(
        (x) => x.uniqueId == clickedItem.uniqueId
      ).previewIndex;
    }
  }
  public closeModal(obj) {
    this.isPreviewTestQuestions = !obj;
    this.isPreviewSourceQuestions = !obj;
    this.closeModalPopUp = !obj;
    this.previewHeaderText = "Previewing all questions";
    this.questionIndex = "0";
    this.resetQuestionPoistion();
    this.previewQuestionsList = [];
  }

  resetQuestionPoistion() {
    let freshSelectedQuestionsList = [];
    if (this.selectedQuestionsListObjStore?.length > 0) {
      this.selectedQuestionsListObjStore.forEach((itm) => {
        this.selectedQuestionsListObj.find((item) => {
          if (itm.uniqueId == item.uniqueId) {
            if (itm.hasOwnProperty("parts")) {
              let subparts = [];
              let partIndex = 0;
              itm.parts.forEach((pitm) => {
                let partsFound = 0;
                item.parts.find((pitem) => {
                  if (pitm.qid == pitem.qid) {
                    partsFound = 1;
                  }
                });
                if (partsFound == 1) {
                  pitm.index = partIndex++;
                  subparts.push(pitm);
                  partsFound = 0;
                }
              });
              itm.parts = subparts;
            }
            freshSelectedQuestionsList.push(itm);
          }
        });
      });
      this.selectedQuestionsListObj = JSON.parse(
        JSON.stringify(freshSelectedQuestionsList)
      );
    }

    freshSelectedQuestionsList = [];
    this.createCollectionObject();
  }

  // Remove array of data from right hand panel
  removeFromArray(original, remove) {
    return original.filter((value) => !remove.includes(value));
  }

  modalOkJob(event, selectedQuestion?) {
    var that = this;
    // this.selectedQuestionsListObj = this.removeFromArray(this.selectedQuestionsListObj, this.selectedQuestionsList);
    if (selectedQuestion) {
      this.selectedQuestionsList = selectedQuestion;
    }
    this.selectedQuestionsList.forEach((itm) => {
      let ind = this.selectedQuestionsListObj.indexOf(itm);
      if (selectedQuestion) {
        this.selectedQuestionsListObj.map((item, index) => {
          if (item.qid == itm.qid) {
            itm.selected = true;
            ind = index;
          }
        });
      }
      if (itm.selected === true) {
        // scalar question or multipart question remove which is selected true
        if (ind > -1) {
          this.selectedQuestionsListObj.splice(ind, 1);
        }
      } else if (itm.selected === false) {
        // This will delete the part question
        if (itm.hasOwnProperty("parts")) {
          let deletedParts = [];
          itm.parts.forEach((item) => {
            const index = item.index;
            if (item.selected == true) {
              deletedParts.push(item);
            }
          });
          this.selectedQuestionsListObj[ind].parts = this.removeFromArray(
            this.selectedQuestionsListObj[ind].parts,
            deletedParts
          );
        }
      }
    });

    // After deleteing the selection reset the checkbox
    this.selectedQuestionsListObj.forEach((element) => {
      element.selected = false;
      if (element.hasOwnProperty("parts")) {
        for (var i = 0; i < element.parts.length; i++) {
          element.parts[i].selected = false;
        }
      }
    });

    this.questionIdArray = [];
    this.assignArrayIndex();
    this.assignArrayIndexSectionBreakQuestion(this.selectedQuestionsListObj);
    this.setKebabPreviewIndex();
    that.renameActivated = this.selectedQuestionsList.length > 0 ? true : false;
    this.selectedQuestionsList = [];
    this.selectedTitle = { selected: false };
    this.showModal = false;
    this.dataChanged();
    setTimeout(() => {
      this.pointsAlignment();
    }, 100);
  }
  modalCancelJob() {
    this.showModal = false;
  }

  public settingsRouteActivate() {
    if (this.step == "step2") {
      this.step = "step1";
    }
  }

  public validatePoints(event: any) {
    let isValid = true;
    let message = { Message: "Enter a point value between 0.00 and 1000.00" };
    const value = event.target.value;
    if (
      value === undefined ||
      value == "" ||
      value.includes("-") ||
      value.includes("+")
    ) {
      isValid = false;
    } else if (isNaN(value)) {
      isValid = false;
    } else {
      const numVal = parseFloat(value);
      if (numVal > 1000) {
        isValid = false;
      } else if (numVal < 0) {
        isValid = false;
      } else {
        const dotPos = value.indexOf(".");
        if (
          dotPos > -1 &&
          value.substring(dotPos + 1, value.length).length > 2
        ) {
          isValid = false;
          message = {
            Message: "Enter a point value up to two decimal places.",
          };
        }
      }
    }
    if (isValid) {
      this.updatePointsValidationObject.isDisableUpdatePoints = false;
      event.target.style = "box-shadow: 0 2px 0 0 #007c91;";
      this.setCollectionInfoInHeader();
      this.updatePointsValidationObject.showInvalidMessage = "";
    } else {
      if (this.updatePointsValidationObject.callFromUpdatePoints) {
        this.updatePointsValidationObject.showInvalidMessage =
          "Enter a point value between 0.00 and 1000.00";

        this.updatePointsValidationObject.isDisableUpdatePoints = true;
      } else {
        event.target.style = "box-shadow: 0 2px 0 0 red;";
        const element = this.render.selectRootElement("#" + event.target.id);
        element.focus();
        this.shareDataService.sendMessageData(message);
      }
    }
  }
  public sortedQuestionsList() {
    if (this.selectedQuestionsList.length > 0) {
      this.selectedQuestionsList.sort(function (question1, question2) {
        return question1.index - question2.index;
      });
      return this.selectedQuestionsList;
    }
  }

  public setArrayIndex(arrayIndex: number) {
    this.arrayIndex = arrayIndex;
  }

  public hideAddQuestion(event) {
    if (event) {
      this.hideHeader = true;
    }
  }

  public hideLeftPanel(event) {
    if (event) {
      this.panelClosed = true;
    }
  }
  public filterEvent(event) {
    if (event) {
      this.showSettings = true;
      this.tabIndex = 1;
      this.showCreateButton = false;
    } else {
      this.showSettings = false;
    }
  }

  public tabClick(event) {
    this.tabIndex = event;
    if (this.tabIndex == 0) {
      this.router.navigate(["/editAssessment", "edit"]);
    } else {
      this.step = "step2";
    }
  }

  getItemData(val) {
    val = val.itemInfo.questions[0];
    let randomNumber = Math.random().toString().substring(2);
    val.uniqueId = val.qid + "-" + randomNumber;
    this.selectedQuestionsListObj.unshift(val);

    this.questionIdArray = [];
    this.assignArrayIndex();
    this.arrayPinnedQuestions();
  }
  sourceQuestionUpdate(val) {
    val = val.response.itemInfo.questions[0];
    let randomNumber = Math.random().toString().substring(2);
    val.uniqueId = val.qid + "-" + randomNumber;
    var getPos: number = -1;
    getPos = this.shareDataService.getEditedQuestionIndex();
    if (getPos > -1) {
      let questType = this.shareDataService.getEditedQuestionType();

      if (questType == "section-break") {
        // sction break new question add
        val.edited = 1;
        //this.selectedQuestionsListObj[getPos].parts.push(val);
        this.selectedQuestionsListObj[getPos].parts.splice(0, 0, val);
        this.assignArrayIndexSectionBreakQuestion(
          this.selectedQuestionsListObj
        );
      } else if (questType == "section-break-part-question") {
        // section break part question edit or copyedit for my question
        let partInfo = this.shareDataService.getPartQuestionEditedInfo();
        if (
          partInfo.hasOwnProperty("multiPartId") &&
          partInfo.multiPartId != ""
        ) {
          this.selectedQuestionsListObj = this.selectedQuestionsListObj.map(
            (item) => {
              if (partInfo.multiPartId == item.multiPartId) {
                if (partInfo.copyy === true) {
                  val.edited = 1;
                  item.parts.unshift(val);
                } else {
                  var partQuesIndex = partInfo.index;
                  val.edited = 1;
                  item.parts[partQuesIndex] = val;
                }
              }
              return item;
            }
          );
        }

        this.shareDataService.setPartQuestionEditedInfo({});
      } else if (questType == "section-break-part-question-copy-edit") {
        // section break part question edit for library question
        let partInfo = this.shareDataService.getPartQuestionEditedInfo();
        if (
          partInfo.hasOwnProperty("multiPartId") &&
          partInfo.multiPartId != ""
        ) {
          // Removed the existing item

          for (var i = this.selectedQuestionsListObj.length; i--; ) {
            if (
              this.selectedQuestionsListObj[i].multiPartId ==
              partInfo.multiPartId
            ) {
              this.selectedQuestionsListObj.splice(i, 1);
            }
          }
        }

        for (let i = 0; i < val.parts.length; i++) {
          if (i == getPos) {
            val.parts[i].edited = 1;
          }
        }
        this.selectedQuestionsListObj.unshift(val);
        this.shareDataService.setPartQuestionEditedInfo({});
      } else {
        //val.edited = 1;
        var newpartid = this.shareDataService.getNewPartQuestionId();
        if (typeof newpartid != "undefined" && newpartid != "-1") {
          if (val.hasOwnProperty("parts")) {
            for (let i = 0; i < val.parts.length; i++) {
              if (val.parts[i].qid == newpartid) {
                val.parts[i].edited = 1;
              }
            }
          }
          this.shareDataService.setNewPartQuestionId("-1");
        } else {
          val.edited = 1;
        }

        // this.shareDataService.setEditedQuestionIndex(-1);
        //this.assignArrayIndexSectionBreakQuestion(this.selectedQuestionsListObj);
        if (questType == "section-break-lib-question-edit") {
          this.selectedQuestionsListObj.splice(getPos, 1);
          this.selectedQuestionsListObj.unshift(val);
        } else {
          this.selectedQuestionsListObj[getPos] = val;
        }
      }
      this.shareDataService.setEditedQuestionType("");
      this.shareDataService.setEditedQuestionIndex(-1);
    } else {
      val.edited = 1;
      this.selectedQuestionsListObj.unshift(val);
    }

    this.questionIdArray = [];
    this.assignArrayIndex();
    this.assignArrayIndexSectionBreakQuestion(this.selectedQuestionsListObj);
    this.arrayPinnedQuestions();
    this.setKebabPreviewIndex();
  }

  changeSourcePicker() {
    this.showBank = true;
    this.hideHeader = false;
    this.count = 0;
    this.isExpand = false;
    this.textExpand = "Expand All";
    this.child.clearSelection();
  }
  indexCal(i, j) {
    return i + "." + j;
  }
  assignArrayIndexSectionBreakQuestion(data) {
    this.selectedQuestionsListObj = data.map((question, index) => {
      if (question.hasOwnProperty("parts")) {
        if (!question.hasOwnProperty("uniqueId")) {
          let randomNumber = Math.random().toString().substring(2);
          question.uniqueId = question.qid + "-" + randomNumber;
        }
        let ind = 0;
        question.parts.map((partQuestion) => {
          partQuestion.index = ind++;
          partQuestion.previewIndex = index + ind;
          partQuestion.uniqueId = partQuestion.qid + "-" + index;
          partQuestion.parentUniqueId = question.uniqueId;
          return partQuestion;
        });
      }
      return question;
    });
  }
  confirmSectionBreakRemove() {
    this.executeSoftSourceDelete(this.sectionBreakRemoveObj);
    this.toggleAddRemoveButtonPreviewModal(this.sectionBreakRemoveObj, true);
  }
  closeSectionBreakModal() {
    this.showSectionBreakQuestionDeleteModal = false;
    this.partQuestionRemove = false;
    this.secBreakRemove = false;
  }
  confirmPartQuestionRemove() {
    this.executePartQuestionRemove(this.sectionPartsRemoveObj);
    this.toggleAddRemoveButtonPreviewModal(this.sectionPartsRemoveObj, true);
  }
  decideBottomBorder(selectedQuestions, j) {
    return selectedQuestions.parts.length - 1 == j ? "bottom-border" : "";
  }
  decideTopBorder(selectedQuestions, j) {
    return 0 == j ? "top-border" : "";
  }
  decideTopLeftCorner(j) {
    return 0 == j ? "border-top-left-radius" : "";
  }
  decideTopRightCorner(j) {
    return 0 == j ? "border-top-right-radius" : "";
  }
  decideBottomRightCorner(selectedQuestions, j) {
    return selectedQuestions.parts.length - 1 == j
      ? "border-bottom-right-radius"
      : "";
  }
  decideBottomLeftCorner(selectedQuestions, j) {
    return selectedQuestions.parts.length - 1 == j
      ? "border-bottom-left-radius"
      : "";
  }
  setBackground() {
    return "part-background";
  }
  borderLeft() {
    return "part-border-left";
  }
  borderRight() {
    return "part-border-right";
  }
  isSectionBreak(refType) {
    return refType == "multipart" ? true : false;
  }

  selectAllPartQues(selectedQuestions, event) {
    var that = this;
    this.selectedQuestionsListObj.forEach((item) => {
      item.selected = event;
      if (item.selected && this.selectedQuestionsList.indexOf(item) === -1) {
        this.selectedQuestionsList.push(item);
      } else if (!item.selected) {
        const index = this.selectedQuestionsList.indexOf(item);
        this.selectedQuestionsList.splice(index, 1);
      }
    });
    that.renameActivated = this.selectedQuestionsList.length > 0 ? false : true;
  }

  noRightScalar(val) {
    return val ? "sb-kebab-cont" : "scalar-kebab-cont";
  }

  previewTestQuestions() {
    this.isPreviewTestQuestions = true;
    this.previewQuestionsList = [];
    this.pushPartQuestionPreviewQuestionsList();
    var userSelectedOptions = false;
    var tempPreviewList = [];
    if (this.selectedQuestionsListObj?.length > 0) {
      this.selectedQuestionsListObj.forEach((itm, index) => {
        let ind = this.previewQuestionsList.find(
          (x) => x.uniqueId == itm.uniqueId
        ).previewIndex;
        let tempOb = Object.assign({}, itm);
        tempOb.index = ind + 1;
        tempOb.dropped = true;
        if (tempOb.selected === true) {
          if (ind > -1) {
            userSelectedOptions = true;
            tempPreviewList.push(tempOb);
            if (itm.hasOwnProperty("parts")) {
              tempOb.parts.forEach((item, indx) => {
                if (item.selected == true) {
                  let tempPartObj = Object.assign({}, item);
                  tempPartObj.dropped = true;
                  let pind = this.previewQuestionsList.find(
                    (x) => x.qid == item.qid
                  ).previewIndex;
                  //tempPartObj.index = tempOb.index+1+indx+1;
                  tempPartObj.index = pind + 1;
                  tempPreviewList.push(tempPartObj);
                }
              });
            }
          }
        } else if (tempOb.selected === false) {
          if (tempOb.hasOwnProperty("parts")) {
            tempOb.parts.forEach((item) => {
              if (item.selected == true) {
                userSelectedOptions = true;
                let tempPartObj = Object.assign({}, item);
                tempPartObj.dropped = true;
                let pind = this.previewQuestionsList.find(
                  (x) => x.qid == item.qid
                ).previewIndex;
                tempPartObj.index = pind + 1;
                tempPreviewList.push(tempPartObj);
              }
            });
          }
        }
      });
    }

    if (userSelectedOptions === false) {
      this.pushPartQuestionPreviewQuestionsList();
      this.setPreviewQuestionIndex();
    } else {
      this.previewQuestionsList = tempPreviewList;
    }
    // this.previewQuestionsList.map(item => {
    //   item.index = item.originalIndex;
    // })

    this.qIndex = 0;
  }

  resetSelectedQuestionsListObjIndex() {
    this.selectedQuestionsListObj.forEach((itm, index) => {
      itm.index = index;
      itm.selected = false;
      if (itm.hasOwnProperty("parts")) {
        itm.parts.forEach((item, pIndex) => {
          //itm.parts[pIndex].index = itm.index + pIndex + 1;
          itm.parts[pIndex].index = pIndex;
          itm.parts[pIndex].selected = false;
        });
      }
    });
    this.selectedTitle = { selected: false };
  }
  setPreviewQuestionIndex() {
    this.previewQuestionsList.forEach((itm, ind) => {
      this.previewQuestionsList[ind].index = ind + 1;
    });
  }

  receiveDataPreviewClicked(event) {
    this.previewSourceQuestions(event);
  }

  previewSourceQuestions(dataObj) {
    this.previewHeaderText = "Previewing all questions";
    let indexParts = 0;
    this.isPreviewSourceQuestions = true;
    let addQuestionsList: any = [];
    let listOfSelectedQuestion: any = [];
    addQuestionsList = this.bankObj.questions.itemInfo.questions;
    var userChecked = false;
    this.previewSourceQuestionsList = [];
    if (this.bankObj?.bank?.title == "My Questions") {
      this.previewHeaderText = "Previewing my questions";
    }

    addQuestionsList = this.questionListPipe.transform(
      addQuestionsList,
      this.filterJsonObject,
      true
    );
    addQuestionsList.forEach((item, index) => {
      let isDropped = this.selectedQuestionsListObj.find(
        (o) => o.qid === item.qid
      );
      item.dropped = isDropped ? true : false;
      indexParts = indexParts + 1;
      item["index"] = indexParts;
      this.previewSourceQuestionsList.push(item);
      if (item.selected) {
        userChecked = true;
        listOfSelectedQuestion.push(item);
      }
      if (item.hasOwnProperty("parts")) {
        let isDropped;
        item.parts = item.parts.map((partQuestion, ind) => {
          this.selectedQuestionsListObj.map((item) => {
            if (item.parts) {
              isDropped = item.parts.find((o) => o.qid === partQuestion.qid);
            }
          });

          // = this.selectedQuestionsListObj.find(o => o.qid === partQuestion.qid);
          partQuestion.dropped = isDropped ? true : false;
          indexParts = indexParts + 1;
          partQuestion["index"] = indexParts;
          this.previewSourceQuestionsList.push(partQuestion);
          if (item.selected) {
            listOfSelectedQuestion.push(partQuestion);
            userChecked = true;
          }
          return partQuestion;
        });
      }
    });

    this.questionIndex = 0;

    if (
      listOfSelectedQuestion &&
      listOfSelectedQuestion?.length > 0 &&
      !dataObj
    ) {
      this.previewHeaderText = "Previewing all selected questions";
      // this.previewSourceQuestionsList.forEach((item,index) => {
      //       if(item.qid === listOfSelectedQuestion[0].qid) this.questionIndex = index;
      //   });
      this.previewSourceQuestionsList = listOfSelectedQuestion;
    } else if (dataObj) {
      this.previewSourceQuestionsList.forEach((item, index) => {
        if (item.qid === dataObj.qid) this.questionIndex = index;
      });
    }
    if (listOfSelectedQuestion && listOfSelectedQuestion?.length > 0) {
      listOfSelectedQuestion.map((item, index) => {
        if (dataObj) {
          if (item.qid === dataObj.qid) {
            this.questionIndex = index;
            this.previewSourceQuestionsList = listOfSelectedQuestion;
          }
        }
      });
    }
  }

  isPreviewDisable() {
    let filteredData = this.questionListPipe.transform(
      this.bankObj.questions.itemInfo.questions,
      this.filterJsonObject,
      true
    );
    return filteredData.length == 0;
  }

  pushPartQuestionPreviewQuestionsList() {
    var tempPreviewQuestionsList = [];
    this.selectedQuestionsListObjStore = JSON.parse(
      JSON.stringify(this.selectedQuestionsListObj)
    );
    this.selectedQuestionsListObj.forEach((element, index) => {
      let tempOb = Object.assign({}, element);
      tempOb.originalIndex = index + 1;
      tempPreviewQuestionsList.push(tempOb);
      if (element.hasOwnProperty("parts")) {
        element.parts.forEach((item, pIndex) => {
          let tempPartOb = Object.assign({}, item);
          tempPartOb.originalIndex = tempOb.originalIndex + pIndex + 1;
          tempPreviewQuestionsList.push(tempPartOb);
        });

        //tempPreviewQuestionsList.push(...elepushPartQuestionPreviewQuestionsListment.parts);
      }
    });
    var pIndex = 0;
    tempPreviewQuestionsList = tempPreviewQuestionsList.map((elm) => {
      elm.dropped = true;
      elm.index = pIndex++;
      elm.previewIndex = elm.index;
      elm.isRemoved = false;
      return elm;
    });
    this.previewQuestionsList = tempPreviewQuestionsList;
  }

  kebabPreview(selectedQuestionsListObj) {
    this.getOnlinePreviewUrl(selectedQuestionsListObj);
  }

  setKebabPreviewIndex() {
    var mindex = 0;
    this.selectedQuestionsListObj.forEach((itm, index) => {
      itm.index = index;
      itm.previewIndex = mindex;
      itm.originalIndex = index + 1;
      if (itm.hasOwnProperty("parts")) {
        itm.parts.forEach((item, pIndex) => {
          itm.parts[pIndex].index = pIndex;
          itm.parts[pIndex].previewIndex = mindex + pIndex + 1;
          itm.parts[pIndex].originalIndex = itm.originalIndex + 1 + pIndex;
        });
        let partLength = itm.parts.length - 1;
        mindex = itm.parts[partLength].previewIndex;
      }
      mindex++;
    });
  }

  addQuestionClicked(event) {
    let selectedData = event.data;
    if (event.source == "SourcePanel") {
      this.previewQuestionsList.map((obj) => {
        if (obj.qid == event.data[0].qid) {
          obj.dropped = event.data[0].dropped;
        }
      });

      if (!selectedData[0].parts && selectedData[0]?.multiPartId) {
        if (event.data[0].dropped) {
          selectedData = this.getSectionBreakWrapper(event);
          this.previewSourceQuestionsList.map((obj) => {
            if (obj.qid == selectedData[0].qid) {
              obj.dropped = true;
              if (obj.parts) {
                obj.parts.map((item) => {
                  if (item.qid == event.data[0].qid) item.dropped = true;
                  return item;
                });
              }
            }
          });

          if (this.selectedQuestionsListObj.length > 0) {
            this.selectedQuestionsListObj.forEach((item) => {
              if (item.qid == selectedData[0].qid) {
                if (item.parts) {
                  item.parts.push(selectedData[0].parts[0]);
                }
              }
            });
            let isObjectExist = this.selectedQuestionsListObj.filter((item) => {
              return item.qid == selectedData[0].qid;
            });
            if (isObjectExist.length == 0)
              this.selectedQuestionsListObj.push(selectedData[0]);
          } else {
            this.selectedQuestionsListObj.push(selectedData[0]);
          }
        } else {
          selectedData = this.getSectionBreakWrapper(event);

          if (this.selectedQuestionsListObj.length > 0) {
            this.selectedQuestionsListObj.forEach((item) => {
              if (item.qid == selectedData[0].qid) {
                if (item.parts) {
                  item.parts = item.parts.filter((item) => {
                    return item.qid != event.data[0].qid;
                  });
                }
                if (item.parts.length == 0) {
                  this.selectedQuestionsListObj =
                    this.selectedQuestionsListObj.filter((item) => {
                      return item.qid != selectedData[0].qid;
                    });
                  let objIndex = this.previewSourceQuestionsList.findIndex(
                    (obj) => obj.qid == selectedData[0].qid
                  );

                  this.previewSourceQuestionsList[objIndex]["dropped"] = false;
                }
              }
            });
          }
        }
      } else {
        event.data[0].dropped
          ? this.addToRight(null, selectedData)
          : this.modalOkJob(null, event.data);
      }
    } else {
      this.addRemoveOperation(event);
    }

    this.createCollectionObject();
  }

  addRemoveOperation(data) {
    data.data = data.data[0];
    if (data.mode == "remove") {
      let sectionBreakId;
      if (
        data.data.refType == "scalar" &&
        data.data.hasOwnProperty("multiPartId") &&
        data.data.multiPartId != ""
      ) {
        sectionBreakId = data.data.parentUniqueId;
        let findSBQuestions = this.selectedQuestionsListObj.find(
          (itm) => itm.uniqueId == data.data.parentUniqueId
        );
        this.sectionBreakQuestionStore[sectionBreakId] = JSON.parse(
          JSON.stringify(findSBQuestions)
        );
      } else if (
        data.data.refType == "multipart" &&
        data.data.multiPartId != ""
      ) {
        sectionBreakId = data.data.uniqueId;
        this.sectionBreakQuestionStore[sectionBreakId] = data.data;
      }

      this.softSourceDelete(data.data);
    } else if (data.mode == "add") {
      let newItem = data.data;
      if (newItem.refType == "multipart") {
        let item;
        if (this.sectionBreakRemoveObj) {
          item = this.sectionBreakRemoveObj;
          const index = this.selectedQuestionsListObjStore.find(
            (itm) => itm.uniqueId == item.uniqueId
          ).index;
          let tempObjQuestions = JSON.parse(
            JSON.stringify(this.selectedQuestionsListObj)
          );
          tempObjQuestions.splice(index, 0, item);
          this.selectedQuestionsListObj = tempObjQuestions;
          this.toggleAddRemoveButtonPreviewModal(item, false);
        } else if (this.sectionPartsRemoveObj) {
          item = this.sectionPartsRemoveObj;
          let findParentQuestions =
            this.sectionBreakQuestionStore[newItem.uniqueId];
          if (findParentQuestions) {
            let tempObj = JSON.parse(
              JSON.stringify(this.selectedQuestionsListObj)
            );
            tempObj.splice(findParentQuestions.index, 0, newItem);
            this.selectedQuestionsListObj = tempObj;

            let addPartQuestion =
              this.selectedQuestionsListObj[findParentQuestions.index];
            addPartQuestion.parts.splice(0, 0, item);
            this.previewQuestionsList.find((itm) => {
              if (itm.uniqueId == item.uniqueId) {
                itm.isRemoved = false;
                itm.dropped = true;
              }
              if (itm.uniqueId == newItem.uniqueId) {
                itm.isRemoved = false;
                itm.dropped = true;
              }
            });
          } else {
            let tempObjQuestions = JSON.parse(
              JSON.stringify(this.selectedQuestionsListObj)
            );
            tempObjQuestions.splice(data.index, 0, item);
            this.selectedQuestionsListObj = tempObjQuestions;
          }
        } else {
          item = newItem;
          const index = this.selectedQuestionsListObjStore.find(
            (itm) => itm.uniqueId == item.uniqueId
          ).index;
          let tempObjQuestions = JSON.parse(
            JSON.stringify(this.selectedQuestionsListObj)
          );
          tempObjQuestions.splice(index, 0, item);
          this.selectedQuestionsListObj = tempObjQuestions;
        }
      } else if (
        newItem.refType == "scalar" &&
        newItem.hasOwnProperty("multiPartId") &&
        newItem.multiPartId != ""
      ) {
        let findParentQuestion = this.selectedQuestionsListObj.find(
          (itm) => itm.uniqueId == newItem.parentUniqueId
        );

        if (typeof findParentQuestion == "undefined") {
          let mulitpartid = newItem.parentUniqueId;
          findParentQuestion = this.sectionBreakQuestionStore[mulitpartid];
          let tempObjQuestions = JSON.parse(
            JSON.stringify(this.selectedQuestionsListObj)
          );
          tempObjQuestions.splice(
            findParentQuestion.index,
            0,
            findParentQuestion
          );
          this.selectedQuestionsListObj = tempObjQuestions;

          this.previewQuestionsList.find((itm) => {
            if (itm.uniqueId == findParentQuestion.uniqueId) {
              itm.isRemoved = false;
              itm.dropped = true;
            }
          });
          if (findParentQuestion.parts.length > 0) {
            findParentQuestion.parts = [];
          }
        }

        if (findParentQuestion?.hasOwnProperty("parts")) {
          let partQIndex;

          if (findParentQuestion.parts.length == 0) {
            partQIndex = 0;
          } else {
            this.sectionBreakQuestionStore[
              newItem.parentUniqueId
            ].parts.forEach((element, index) => {
              if (element.qid == newItem.qid) {
                partQIndex = index;
              }
            });
          }

          findParentQuestion.parts.splice(partQIndex, 0, newItem);
          this.setKebabPreviewIndex();
        }
      } else {
        const index = this.selectedQuestionsListObjStore.find(
          (itm) => itm.uniqueId == data.data.uniqueId
        ).index;
        let tempObj = JSON.parse(JSON.stringify(this.selectedQuestionsListObj));
        tempObj.splice(index, 0, data.data);
        this.selectedQuestionsListObj = tempObj;
        this.bankObj.questions.itemInfo.questions.filter((item) => {
          if (item.uniqueId == data.data.uniqueId) {
            item.dropped = true;
          }
        });
      }
      this.setCollectionInfoInHeader();
    }
    setTimeout(() => {
      this.pointsAlignment();
    }, 100);
  }

  getSectionBreakWrapper(event) {
    let selectedData;
    this.bankObj.questions.itemInfo.questions.map((item) => {
      if (item?.parts && event?.data[0]?.multiPartId == item?.multiPartId) {
        let tempData = [];
        let x = JSON.parse(JSON.stringify(item));
        tempData.push(x);
        tempData[0].parts = [];
        tempData[0].parts.push(event.data[0]);
        selectedData = JSON.parse(JSON.stringify(tempData));
      }
    });
    return selectedData;
  }

  toggleAddRemoveButtonPreviewModal(obj, value) {
    if (this.previewQuestionsList?.length > 0) {
      let partIdStore = [];
      if (obj.parts?.length > 0) {
        obj.parts.find((itm) => {
          partIdStore.push(itm.uniqueId);
        });
      }

      this.previewQuestionsList.find((element) => {
        if (element.uniqueId == obj.uniqueId) {
          // Selected Question
          element.isRemoved = value;
          element.dropped = !value;
        }

        if (partIdStore.indexOf(element.uniqueId) > -1) {
          // Part Question
          element.isRemoved = value;
          element.dropped = !value;
        }

        if (partIdStore.length == 0) {
          // Parent Question
          if (element.uniqueId == obj.parentUniqueId) {
            element.isRemoved = value;
            element.dropped = !value;
          }
        }
      });
    }
  }

  pointsAlignment() {
    let scrollHeight = document.getElementById("test-panel").scrollHeight;
    let clientHeight = document.getElementById("test-panel").clientHeight;

    this.pointAlignment = "point-alignment";
    this.typeAlignment = "type-alignment";

    if (scrollHeight > clientHeight) {
      this.pointAlignment = "point-alignment-resize";
      this.typeAlignment = "type-alignment-resize";
    }
  }

  expandAllQuestions() {
    if (this.isExpand) {
      this.textExpand = "Collapse All";
    } else {
      this.textExpand = "Expand All";
    }
    this.isExpand = !this.isExpand;
    this.sourceBank.expandAll(this.isExpand);
  }
  public clearSearch() {
    this.textSearchLeft = "";
  }
  public clearSearchRight() {
    this.searchedTextRight = "";
    this.dataChanged();
  }
  dataChanged() {
    let filteredArray = [];
    if (this.searchedTextRight.trim() != "") {
      filteredArray = this.filterSearchedItem(this.selectedQuestionsListObj);
    } else {
      filteredArray = this.selectedQuestionsListObj;
    }
    this.currentPage(filteredArray);
  }
  filterSearchedItem(totalQuestionAR) {
    const questionArray = [];
    totalQuestionAR.map((findData) => {
      let dataFound =
        findData.preview
          .toLowerCase()
          .search(this.searchedTextRight.toLowerCase().trim()) !== -1;
      if (findData.hasOwnProperty("parts") && !dataFound) {
        for (let i = 0; i < findData.parts.length; i++) {
          if (
            findData.parts[i].preview
              .toLowerCase()
              .search(this.searchedTextRight.toLowerCase().trim()) !== -1
          ) {
            dataFound = true;
            break;
          }
        }
      }
      if (dataFound) {
        questionArray.push(findData);
      }
    });
    this.totalSearchCountRight = questionArray.length;
    return questionArray;
  }
  assignSelectedQuestionArray() {
    let arrayIndex = 0;
    if (this.selectedQuestionsListObj) {
      this.selectedQuestionsListObj.forEach((item) => {
        item["index"] = arrayIndex++;
        this.selectedQuestionArray.set(item.qid + "-main", false);
        if (item.hasOwnProperty("parts")) {
          item["parts"].map((val) => {
            this.selectedQuestionArray.set(val.qid + "-parts", false);
          });
        }
      });
    }
  }
  toggleQuestionHideArray(questionId: string) {
    const isExpand = this.selectedQuestionArray.get(questionId);
    this.selectedQuestionArray.set(questionId, !isExpand);
  }
  expandAll(isExpand: boolean) {
    this.hasExpanded = isExpand;
    this.selectedQuestionArray.forEach((values, keys) => {
      this.selectedQuestionArray.set(keys, isExpand);
    });
  }
  closeUpdateBulkPointsModal() {
    this.updatePointsValidationObject.callFromUpdatePoints = false;
    this.updatePointsValidationObject.isDisableUpdatePoints = true;
    this.modalIsVisible = false;
    this.bulkUpdatePointsSelectedQuestionCount = 0;
    this.updatePointsValidationObject.showInvalidMessage = "";
  }
  showUpdatePointsAlrtModal() {
    this.modalIsVisible = !this.modalIsVisible;
  }
  updatePoints() {
    this.selectedQuestionsListObj.map((value) => {
      if (value.selected && !value.hasOwnProperty("parts")) {
        this.bulkUpdatePointsSelectedQuestionCount++;
      }
      if (value?.parts && value?.parts.length > 0) {
        value?.parts.map((pitem) => {
          if (pitem.selected) {
            this.bulkUpdatePointsSelectedQuestionCount++;
          }
        });
      }
    });
    this.bulkUpdatePointInputValue = "0.00";
    this.showUpdatePointsAlrtModal();
  }
  public onChange(event) {
    this.bulkUpdatePointInputValue = event.value;
    this.updatePointsValidationObject.callFromUpdatePoints = true;
    this.updatePointsValidationObject.isDisableUpdatePoints = false;
    this.updatePointsValidationObject.showInvalidMessage = "";
  }
  ngOnChanges(event) {
    this.setSearchDiv();
    this.cdr.detectChanges();
  }
  updateBulkPoints() {
    this.updatePointsValidationObject.isDisableUpdatePoints = true;

    this.modalIsVisible = false;
    this.selectedQuestionsListObj.map((value, index) => {
      if (value.selected) {
        this.selectedQuestionsListObj[index].points =
          this.bulkUpdatePointInputValue;
        this.showSuccessMessage = true;
        this.showSuccessAlert("Question Point assignment updated successfully");
        this.pointsChanged(value);
        this.setCollectionInfoInHeader();
      }
      if (value?.parts && value?.parts.length > 0) {
        value?.parts.map((pitem, pindex) => {
          if (pitem.selected) {
            value.parts[pindex].points = this.bulkUpdatePointInputValue;
            this.pointsChanged(pindex);
            this.setCollectionInfoInHeader();
          }
        });
      }
    });
  }
  showSuccessAlert(message: string) {
    this.alertSuccessMessage = message;
    this.showSuccessMessage = true;
    this.closeUpdateBulkPointsModal();
    this.closeAlert();
  }
  closeAlert(type?: string) {
    if (type == "manual") {
      this.showSuccessMessage = false;
    } else {
      setTimeout(() => {
        this.showSuccessMessage = false;
      }, 8000);
    }
  }
  updateChangeValueOfDropdown(value) {
    this.showQuestion = value;
  }
  changeshowQuestion() {
    this.showQuestion = !this.showQuestion;
  }
  setParts(partData, title) {
    for (let i = 0; i < partData.length; i++) {
      partData[i].bankName = title;
    }
    return partData;
  }

  previeExamStatistics() {
    const sourceInfoDataList = this.shareDataService.getSourceInfoDataList();
    this.collection.references.map((val: References, index) => {
      let remTid = val.getTid();
      sourceInfoDataList.banks.map((data: BankModel) => {
        if (remTid === data.ezid) {
          this.collection.references[index].setBankName(data.title);
          this.collection.references[index].setIsbn(sourceInfoDataList.isbn);
          remTid = "";
          const parts = this.collection.references[index].getParts();
          if (parts !== undefined) {
            this.collection.references[index].setParts(
              this.setParts(parts, data.title)
            );
          }
        }
      });
      if (
        sourceInfoDataList.hasOwnProperty("myquestions") &&
        val.getTid() === sourceInfoDataList.myquestions.ezid
      ) {
        this.collection.references[index].setBankName(
          sourceInfoDataList.myquestions.title
        );
        this.collection.references[index].setIsbn(null);
        const parts = this.collection.references[index].getParts();
        if (parts !== undefined) {
          this.collection.references[index].setParts(
            this.setParts(parts, sourceInfoDataList.myquestions.title)
          );
        }
      } else if (remTid != "") {
        this.collection.references[index].setBankName("myquestions");
        this.collection.references[index].setIsbn(null);
        const parts = this.collection.references[index].getParts();
        if (parts !== undefined) {
          this.collection.references[index].setParts(
            this.setParts(parts, "myquestions")
          );
        }
      }
    });
    let payloadRefrences: any = [];
    this.collection.references.forEach((x: any) => {
      let params: any = {
        ezid: x.tid,
        qid: x.qid,
        bankName: x.bankName,
        parts: [],
      };
      if (x.parts && x.parts.length > 0) {
        x.parts.forEach((y: any) => {
          params.parts.push({
            ezid: y.tid,
            qid: y.qid,
            bankName: y.bankName,
          });
        });
      }
      if (params.parts.length == 0) {
        delete params.parts;
      }
      payloadRefrences.push(params);
    });
    let modifiedPayloadRefrences = { references: payloadRefrences };
    this.sourceBankQuestion
      .getExamStatistics(modifiedPayloadRefrences)
      .subscribe(
        (response: any) => {
          for (let res in response) {
            this.examStatsticsList[res] = response[res];
          }
          this.showExamStatisticsAlrtModal();
        },
        (error) => {
          this.error = error;
        }
      );
  }
  examStatisticsConfirm() {
    this.examStatisticsModalIsVisible = false;
  }
  showExamStatisticsAlrtModal() {
    this.examStatisticsModalIsVisible = !this.examStatisticsModalIsVisible;
  }
  closeExamStatistics() {
    this.examStatisticsModalIsVisible = false;
  }
}
