import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  Renderer2,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { Location } from "@angular/common";
import { HeaderData } from "../../models/header-items";
import { ShareDataService } from "../../../shared/services/shared.service";
import { Router } from "@angular/router";
import { AlertType, InputType } from "@mhe/ngx-shared";
import { CommonModalComponent } from "../common.modal/common.modal.component";
import { ButtonPurpose, ButtonType } from "@mhe/ngx-shared";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public headerName: String;
  public isBack: boolean;
  public title: String;
  public renameTitle: boolean;
  public totalPoints: number;
  public totalQuestions: number;
  public previewButton: String;
  private collectionId: Number;
  public inputType = InputType;
  showQuestionTypes: boolean = false;
  showEditorLoader: boolean = false;
  public editorLoaderStaticMessage: string =
    "Please wait... Question editor is opening on new tab.";
  public editorLoaderMessage: string = this.editorLoaderStaticMessage;
  menuWidth: any;
  itemInfoData: any;
  public buttonType = ButtonType;
  public _buttonPurpose = ButtonPurpose;

  headerData: HeaderData;

  constructor(
    private shareDataService: ShareDataService,
    private router: Router,
    private _location: Location,
    private renderer: Renderer2,
    private eRef: ElementRef
  ) {}

  public show = false;
  public buttonName: any = "Show";
  public alertMessageData: String = "";
  public showHeaderPreferences = true;
  public showConfirmationHeader = false;
  public alertMessage: String =
    "You have not saved your changes. Are you sure you want to continue?";
  public modalHeader: String = "Confirmation";
  public size: any = { width: 500 };
  public showRenameError: boolean = false;
  public RenameErrorMsg: String = "";
  public alertType = AlertType;
  @Input("operationMode") operationMode: String;
  @Input("subRoute") subRoute: String;
  @Input() alertSuccessMessage: String;
  @Input() showSuccessAlert: boolean = false;
  @Input("showQuestionTypeBtn") showQuestionTypeBtn: boolean;
  @Output() itemInfoDataEmitter = new EventEmitter<any>();
  @Output() closeAlert = new EventEmitter<string>();

  @Input()
  set collection_id(collection_id: Number) {
    this.collectionId = collection_id;
  }
  @Output() settingsRouteActivate = new EventEmitter<any>();
  @Output() filterEvent = new EventEmitter<boolean>();
  ngOnInit() {
    this.headerData = this.shareDataService.getHeaderData();
    if (this.headerData == null) {
      this.shareDataService.getCollection().subscribe((data) => {
        this.headerData = data;
      });
      if (this.headerData == null) {
        this.headerData = new HeaderData();
      }
    }
    this.headerName = this.headerData.getTitle();
    this.isBack = this.headerData.getIsBack();
    this.title = this.headerName;
    this.totalPoints = this.headerData.getTotalPoints();
    this.totalQuestions = this.headerData.getTotalQuestion();
    this.previewButton = this.headerData.getPreviewButton();

    this.shareDataService.getFocusOnTitle().subscribe((data) => {
      this.showHideTitle(true);
    });

    this.shareDataService.getOpenEditorModalValue().subscribe((message) => {
      if (message === true) {
        this.editorLoaderMessage = this.editorLoaderStaticMessage;
      }
      setTimeout(() => {
        this.editorLoaderMessage =
          "You have opened question editor on a new tab. Please close or exit the editor tab to hide this popup.";
      }, 5000);

      this.showEditorLoader = message;
    });
  }
  ngAfterViewInit() {}
  modelChanged(event) {
    /** Share data on title change*/
    this.headerData.setTitle(event);
    this.shareDataService.setHeaderData(this.headerData);
    this.shareDataService.setCollection(this.headerData);

    if (event.length > 80) {
      // invalid character, prevent input
      this.showErrors(
        "Please enter a test name that is unique and between 3 to 80 characters." +
          "<br/>" +
          "Note that certain special characters i.e. /     ;  : and < in the title will not be displayed" +
          "<br/>" +
          "in the docx/qti filename."
      );
    } else if (event === "" || event === "Unnamed Test") {
      // invalid character, prevent input
      this.showErrors(
        "Please enter a test name that is unique and between 3 to 80 characters." +
          "<br/>" +
          "Note that certain special characters i.e. /     ;  : and < in the title will not be displayed" +
          "<br/>" +
          "in the docx/qti filename."
      );
    } else {
      this.hideErrors();
    }
  }

  _keyUp(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
  }

  cancel() {
    if (this.operationMode === "sourceSelect") {
      if (this.subRoute != "step2") {
        const message = {
          Message: this.alertMessage,
        };
        this.shareDataService.sendConfirmMessageData(message);
      } else {
        if (this.shareDataService.getCategoryFilterCount()) {
          this.shareDataService.getCategoryFilterCount().length = 0;
        }
        if (this.subRoute == "step2") {
          this.shareDataService.setCurrentStep("step1");
          this.shareDataService.setLandingState("my-state");
          this.filterEvent.emit(false);
          this.router.navigate(["/sourceSelect"]);
        } else {
          if (
            this.shareDataService &&
            this.shareDataService.getLandingState() === "edit-state"
          ) {
            this.router.navigate(["/editAssessment", "edit"]);
          } else {
            this.router.navigate(["/list"]);
          }
        }
      }
    } else if (
      this.operationMode === "create" ||
      this.headerName == "Unnamed Test" ||
      this.subRoute == "step2"
    ) {
      if (
        this.operationMode == "create" ||
        this.headerName == "Unnamed Test" ||
        (this.headerData && this.headerData.getTitle() == "Unnamed Test")
      ) {
        const message = {
          Message: this.alertMessage,
        };
        this.shareDataService.sendConfirmMessageData(message);
      } else {
        this.showConfirmationHeader = true;
      }
    } else if (this.operationMode === "edit") {
      if (this.shareDataService.getCategoryFilterCount()) {
        this.shareDataService.getCategoryFilterCount().length = 0;
      }
      this.router.navigate(["/list"]);
    } else {
      if (this.shareDataService.getCategoryFilterCount()) {
        this.shareDataService.getCategoryFilterCount().length = 0;
      }
      this._location.back();
    }
  }

  public editNewAssessment() {
    this.headerData.setCollectionId(this.headerData.getCollectionId());
    this.headerData.setTitle(this.headerData.getTitle());
    this.shareDataService.setHeaderData(this.headerData);
    this.router.navigate(["/editAssessment", "edit"]);
  }

  public modalOkJob() {
    if (this.subRoute == "step2") {
      this.settingsRouteActivate.emit("step1");
    } else {
      this._location.back();
    }
    this.showConfirmationHeader = false;
  }

  public modalCancelJob() {
    this.showConfirmationHeader = false;
  }
  public showErrors(msg) {
    const element = this.renderer.selectRootElement("#rename-text");
    element.focus();
    element.style = "box-shadow: 0 2px 0 0 red;";
    this.showRenameError = true;
    this.RenameErrorMsg = msg;
  }

  public hideErrors() {
    this.showRenameError = false;
    const element = this.renderer.selectRootElement("#testTtitle");
    element.style = "box-shadow: 0 2px 0 0 #007c91;";
    this.RenameErrorMsg = "";
  }

  public showHideTitle(showHide: boolean) {
    this.show = showHide;
    var elemRender = this.renderer;
    if (this.show) {
      this.setFocus(elemRender);
    }
  }

  private setFocus(elemRender: Renderer2) {
    setTimeout(function () {
      const element = elemRender.selectRootElement("#testTtitle");
      element.focus();
    }, 100);
  }

  updateChangeValueOfDropdown(value) {
    this.showQuestionTypes = value;
  }

  passValueToSource(val) {
    this.itemInfoData = val;
    this.itemInfoDataEmitter.emit(this.itemInfoData);
  }
  closeAlertModal() {
    this.closeAlert.emit("manual");
  }
}
