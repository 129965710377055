import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  Renderer2,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { HeaderData } from "../../models/header-items";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AssessmentCollectionInterface } from "../../models/assessment-collection-interface.model";
import { ShareDataService } from "../../../shared/services/shared.service";
import { ResponseModal } from "../../../shared/models/response.model";
import { ViewAssessmentInfoService } from "../../services/view-assessment-info.service";
import { SaveAssessmentService } from "../../services/save-assessment.service";
import { KebabMenuInterface } from "./../../models/kebab-menu-model";
import { PaginatePipe } from "./../../pipes/pagination.pipe";
import { Collection } from "../../models/collection.model";
import { MetaData } from "../../models/metadata.model";
import { ButtonType } from "@mhe/ngx-shared";
import { InputType } from "@mhe/ngx-shared";
import { ButtonPurpose } from "@mhe/ngx-shared";

import { CommonModalComponent } from "../common.modal/common.modal.component";
import { EditorQuestionsService } from "../../../shared/services/editor-questions.service";
import { AssessmentListService } from "../../services/assessment-list.service";

@Component({
  selector: "kebab-component",
  templateUrl: "./kebab.component.html",
  styleUrls: ["./kebab.component.scss"],
})
export class KebabComponent implements OnInit {
  constructor(
    private eRef: ElementRef,
    private router: Router,
    private shareDataService: ShareDataService,
    private viewAssessmentInfoService: ViewAssessmentInfoService,

    public sanitizer: DomSanitizer,
    private saveAssessmentService: SaveAssessmentService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,

    private paginate: PaginatePipe,
    private editorService: EditorQuestionsService,
    private assesmentListService: AssessmentListService
  ) {}

  windowStuff: any;
  renameValidationForm: FormGroup;
  submitted = false;
  static collectionId: String;
  private error;
  viewAssessmentData: any;
  headerData: HeaderData = new HeaderData();
  isClicked = false;
  listStyle = {};
  alertMessageData: ResponseModal = {
    Message: "",
    collectionId: 0,
    isSuccess: false,
  };
  previewMessageData: ResponseModal = {
    Message: "",
    collectionId: 0,
    isSuccess: false,
  };
  editCopyMessageData: ResponseModal = {
    Message: "",
    collectionId: 0,
    isSuccess: false,
  };
  show = false;
  showDelete = false;
  showIframe = false;
  showEditCopy = false;
  showEditAlert = false;
  whereIHaveUsedframe = false;

  confirmBtn = false;
  keepExistingQuestion = false;
  timerEditor: any;
  itemsPerPage = 1;
  pageNumber = 0;
  totalPage = 0;
  public modalHeader = "Rename";
  public size = { width: 600 };
  public title: String = "";
  public showCopyModal: boolean = false;
  public newCollectionTitle: string = "";
  public showCopyError: boolean = false;
  public copyErrorMsg: string = "";
  public rename: Boolean = false;
  public buttonType = ButtonType;
  public _buttonPurpose = ButtonPurpose;
  public inputType = InputType;
  public previewCheckedArr = [];
  public pinFlag = false;
  public unPinFlag = false;
  public renameTitle: String = "";
  public lastKebab: boolean = false;
  public questionUsedArr: any = [];

  @Input("collection") collection: AssessmentCollectionInterface;
  @Input("kebabMenuItems") kebabMenu: KebabMenuInterface;
  @Input("checked-questions") checkedQuestions;
  @Input("disableDelete") disableDelete = false;
  @Input("selectedQuestion") selectedQuestion: any;
  @Input("message") message;
  @Input("showKebab") showKebab: boolean = false;
  @Input("question-source") questionSource: boolean = false;
  @Output() showSuccessAlert = new EventEmitter<any>();

  @Output() softDelete = new EventEmitter<any>();
  @Output() pinUnpinQuestion = new EventEmitter<any>();
  @Output() refreshList = new EventEmitter<any>();
  @Output() renameCollection = new EventEmitter<any>();
  @Output() emitQuestionEdit = new EventEmitter<any>();
  @Output() emitQuestionUpdate = new EventEmitter<any>();
  @Output() emitItemInfo = new EventEmitter<any>();
  @Input()
  set show_kebab(show_kebab: boolean) {
    this.showKebab = show_kebab;
    if (this.showKebab) {
      var checkrow_3, elements_3: any, elements_2, elements_4, elem;
      elem = this.eRef.nativeElement.parentElement.parentElement.parentElement;
      checkrow_3 = elem.parentElement;
      elements_3 = elem.className.indexOf("part-row");
      elements_2 = elem.className.indexOf("questions-unused");
      elements_4 = elem.className.indexOf("question-row");

      if (elements_3 > -1) {
        setTimeout(function () {
          checkrow_3.scrollTop = checkrow_3.scrollHeight;
        }, 1000); // My Question section break question
      } else if (elements_2 > -1) {
        if (elem.nextElementSibling == null) {
          // My question scalar last question
          setTimeout(function () {
            checkrow_3.scrollTop = checkrow_3.scrollHeight;
          }, 1000);
        } else if (!this.isVisible(elem.nextElementSibling)) {
          // My question scalar question below the viewport
          this.eRef.nativeElement.scrollIntoView();
        }
      } else if (elements_4 > -1) {
        if (elem.parentElement.nextElementSibling == null) {
          // Right Panel last scalar question
          setTimeout(function () {
            checkrow_3.parentElement.parentElement.scrollTop =
              checkrow_3.parentElement.parentElement.scrollHeight;
          }, 1000);
        } else if (!this.isVisible(elem.parentElement.nextElementSibling)) {
          // Right Panel question below the viewport
          this.eRef.nativeElement.scrollIntoView();
        }
      }
    }
  }
  @Input()
  set last_kebab(lastKebab: boolean) {
    this.lastKebab = lastKebab;
  }
  questionTypesList: any;
  showQuestionTypes: boolean;

  isVisible(elem) {
    //if (!(elem instanceof Element)) throw Error('DomUtil: elem is not an element.');
    const style: any = getComputedStyle(elem);
    if (style.display === "none") return false;
    if (style.visibility !== "visible") return false;
    if (style.opacity < 0.1) return false;
    if (
      elem.offsetWidth +
        elem.offsetHeight +
        elem.getBoundingClientRect().height +
        elem.getBoundingClientRect().width ===
      0
    ) {
      return false;
    }
    const elemCenter = {
      x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
      y: elem.getBoundingClientRect().top + elem.offsetHeight / 2,
    };
    if (elemCenter.x < 0) return false;
    if (
      elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)
    )
      return false;
    if (elemCenter.y < 0) return false;
    if (
      elemCenter.y >
      (document.documentElement.clientHeight || window.innerHeight)
    )
      return false;
    var pointContainer: any = document.elementFromPoint(
      elemCenter.x,
      elemCenter.y
    );
    do {
      if (pointContainer === elem) return true;
    } while ((pointContainer = pointContainer.parentNode));
    return false;
  }
  ngOnInit() {
    this.shareDataService.getMessageData().subscribe((data) => {
      this.alertMessageData = data;
    });
    this.renameValidationForm = this.formBuilder.group({
      title: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(80),
        ],
      ],
    });

    this.questionTypesList = [
      { value: "TF", viewValue: "True/False" },
      { value: "MC", viewValue: "Multiple Choice" },
      { value: "FB", viewValue: "Fill in the Blank" },
      { value: "ES", viewValue: "Essay" },
      { value: "SA", viewValue: "Short Answer" },
      { value: "CA", viewValue: "Check All That Apply" },
    ];
  }

  ngOnChanges() {
    if (this.checkedQuestions) {
      this.checkedQuestions = this.checkedQuestions;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.renameValidationForm.controls;
  }

  pinUnpinQuestionEvent(element) {
    this.pinUnpinQuestion.emit(this.collection);
  }

  deleteAssessment(status) {
    if (this.disableDelete) {
      this.softDelete.emit(this.collection);
    } else {
      this.showDelete = status;
      KebabComponent.collectionId = this.collection.collection_id;
      const message = {
        Message:
          "This cannot be undone, are you sure you want to delete this test?",
      };
      this.shareDataService.sendMessageData(message);
    }
  }

  closeModal(okCancel) {
    this.showDelete = okCancel;
    if (okCancel) {
      this.router.navigate(["/deleteAssessment", KebabComponent.collectionId]);
    }
  }

  editAssessment() {
    this.headerData.setCollectionId(this.collection.collection_id);
    this.headerData.setTitle(this.collection.collection_title);
    this.shareDataService.setHeaderData(this.headerData);
    this.router.navigate(["/editAssessment", "edit"]);
  }

  viewAssessment(status: boolean) {
    if (status) {
      this.viewAssessmentInfoService
        .viewAssessmentList(
          this.collection.collection_id,
          Intl.DateTimeFormat().resolvedOptions().timeZone
        )
        .subscribe(
          (data) => {
            this.viewAssessmentData = data;
            this.show = status;
          },
          (error) => {
            this.error = error;
          }
        );
    } else {
      this.show = false;
    }
  }

  showPreview(element) {
    if (!this.questionSource) {
      this.showIframe = true;
      this.previewCheckedArr = [];
      if (this.checkedQuestions === undefined) {
        this.checkedQuestions = [];
        this.checkedQuestions.push(this.collection);
      } else {
        //Check the selected preview is checked or not
        if (!this.checkedQuestions.includes(this.collection)) {
          this.checkedQuestions.push(this.collection);
        }
      }
      this.previewCheckedArr = [...this.checkedQuestions];
      this.totalPage = this.checkedQuestions.length;
    } else {
      this.emitItemInfo.emit(this.collection);
    }
  }

  closePreviewModal(okPreview) {
    this.previewMessageData.Message = "";
    if (okPreview) {
      this.showIframe = false;
    }
  }

  private pageChanged(event, num) {
    this.pageNumber = event.pageIndex;
    const startingIndex = this.pageNumber * this.itemsPerPage;
    const endingIndex = this.pageNumber * this.itemsPerPage + this.itemsPerPage;

    let paginateParam = {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.pageNumber,
    };
    this.previewCheckedArr = this.paginate.transform(
      this.checkedQuestions,
      paginateParam
    );
  }

  public copyTest() {
    this.saveAssessmentService
      .getNewCollectionName(this.collection.collection_id)
      .subscribe(
        (data) => {
          this.newCollectionTitle = data["collectionTitle"];
          this.showCopyModal = true;
        },
        (error) => {
          this.error = error;
        }
      );
  }

  public cancelCopy() {
    this.showCopyModal = false;
  }
  public confirmCopy() {
    if (
      this.newCollectionTitle === "Unnamed Test" ||
      this.newCollectionTitle == "" ||
      this.newCollectionTitle.length < 3 ||
      this.newCollectionTitle.length > 80
    ) {
      this.error =
        "Please enter a test name that is unique and between 3 to 80 characters." +
        "<br/>" +
        "Note that certain special characters i.e. /     ;  : and < in the title will not be displayed" +
        "<br/>" +
        "in the docx/qti filename.";
      this.showErrors(this.error);
    } else {
      let copyCollection = new Collection(
        Number(this.collection.collection_id),
        Date.now(),
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      const metaDataArr: MetaData[] = new Array();
      metaDataArr.push(new MetaData("title", this.newCollectionTitle));
      copyCollection.setMetaData(metaDataArr);
      this.saveAssessmentService.copyCollection(copyCollection).subscribe(
        (data) => {
          if (data["isSuccess"]) {
            this.showCopyModal = false;
            this.refreshList.emit();
          } else {
            this.showErrors(data["Message"]);
          }
        },
        (error) => {
          this.error = error;
          this.showErrors(this.error);
        }
      );
    }
  }

  public showErrors(msg: string = "") {
    const element = this.renderer.selectRootElement("#newCollectionTitle");
    element.focus();
    element.style = "box-shadow: 0 2px 0 0 red;";
    this.showCopyError = true;
    this.copyErrorMsg = msg;
  }

  public hideErrors() {
    this.showCopyError = false;
    this.copyErrorMsg = "";
    const element = this.renderer.selectRootElement("#newCollectionTitle");
    element.style = "box-shadow: 0 2px 0 0 #007c91;";
  }

  public renameModal() {
    this.title = this.collection.collection_title || this.collection.title;
    this.rename = !this.rename;
  }
  public clearFilter() {
    this.message = "";
  }
  public modalOkJob() {
    this.submitted = true;
    this.renameTitle = this.renameValidationForm.controls.title.value;
    //stop if character count excluding space doesn't match minimum length
    if (
      this.renameTitle === "" ||
      this.renameTitle === "Unnamed Test" ||
      this.renameValidationForm.controls.title.value.trim().length < 3 ||
      this.renameValidationForm.controls.title.value.trim().length > 80
    ) {
      this.message =
        "Please enter a test name that is unique and between 3 to 80 characters." +
        "<br/>" +
        "Note that certain special characters i.e. /     ;  : and < in the title will not be displayed in the docx/qti filename.";
      return;
    }

    // stop here if form is invalid
    if (this.renameValidationForm.invalid) {
      return;
    }
    let collectionDetails = new Collection(
      Number(this.collection.collection_id),
      Date.now(),
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const metaDataArr: MetaData[] = new Array();
    metaDataArr.push(
      new MetaData("title", this.renameValidationForm.controls.title.value)
    );
    collectionDetails.setMetaData(metaDataArr);
    this.saveAssessmentService.saveAssessment(collectionDetails).subscribe(
      (data) => {
        if (data["isSuccess"]) {
          this.rename = false;
          this.refreshList.emit();
        } else {
          this.message = data["Message"];
          return;
        }
      },
      (error) => {
        this.message = error;
        return;
      }
    );
  }
  public modalCancelJob() {
    this.rename = false;
  }

  public pinUnpinCheck(event) {
    if (this.collection.pinned) {
      this.unPinFlag = false;
      this.pinFlag = true;
    } else {
      this.pinFlag = false;
      this.unPinFlag = true;
    }
  }
  public editQuestion(event) {
    this.showEditAlert = true;
    this.confirmBtn = false;
    if (this.collection.userEdited === false) {
      this.confirmBtn = true;
    } else if (
      this.collection.userEdited === true &&
      this.collection.consumers.length == 0
    ) {
      this.continueEditModal();
    }
  }
  continueEditModal() {
    this.showEditAlert = false;
    this.shareDataService.setOpenEditorModalValue(true);
    let coll: any = {};
    coll = this.collection;

    this.shareDataService.setEditedQuestionIndex(coll.index);
    this.windowStuff = this.shareDataService.openBlankTab();
    var partQuestionParams =
      coll.refType == "multipart"
        ? { ezid: coll.ezid, qid: coll.qid, parts: coll.parts }
        : {}; // section break question
    var editedFlag = 0;
    if (
      coll.hasOwnProperty("multiPartId") &&
      coll.multiPartId != "" &&
      coll.refType == "scalar"
    ) {
      // this block is for section break par question edit
      this.shareDataService.setEditedQuestionType(
        "section-break-part-question"
      );
      this.shareDataService.setPartQuestionEditedInfo({
        index: coll.index,
        multiPartId: coll.multiPartId,
        copyy: false,
      });
      editedFlag = coll.edited == 1 ? 0 : 1;
    } else if (
      coll.hasOwnProperty("multiPartId") &&
      coll.multiPartId != "" &&
      coll.refType == "multipart"
    ) {
      editedFlag = coll.edited == 1 ? 0 : 1;
    }

    this.editorService.editQuestion(coll.qid, editedFlag).subscribe(
      (data) => {
        this.openEditorUrl(
          data,
          { ezid: coll.ezid, qid: coll.qid },
          this.collection,
          partQuestionParams
        );
      },
      (err) => console.log(err)
    );
  }
  editCopy(element) {
    this.keepExistingQuestion = true;
    this.continueEditCopyModal();
  }

  continueEditCopyModal() {
    this.showEditAlert = false;

    var coll: any = {};
    coll = this.collection;
    let copyParams = {
      sourceezid: coll.ezid,
      sourceqid: coll.qid,
    };

    if (this.keepExistingQuestion == false) {
      this.shareDataService.setEditedQuestionIndex(coll.index);
    }
    //if( coll.hasOwnProperty("multiPartId") && coll.multiPartId != '' && coll.refType =='scalar' && coll.userEdited === true){ // this block is for section break par question edit

    //this.shareDataService.setEditedQuestionIndex(coll.index);
    //this.shareDataService.setEditedQuestionType('section-break-part-question');
    //this.shareDataService.setPartQuestionEditedInfo({'index':coll.index,'multiPartId' : coll.multiPartId,'copyy' : true});
    // }
    if (
      coll.hasOwnProperty("multiPartId") &&
      coll.multiPartId != "" &&
      coll.refType == "scalar" &&
      coll.userEdited === false
    ) {
      var qidd = coll.qid;
      if (this.selectedQuestion.hasOwnProperty("qid")) {
        qidd = this.selectedQuestion.qid;
      }
      copyParams = { sourceezid: coll.ezid, sourceqid: qidd };
    }
    this.windowStuff = this.shareDataService.openBlankTab();
    this.editorService.copyQuestion(copyParams).subscribe(
      (response) => {
        var copyQuestionResponseReceived = response;
        copyQuestionResponseReceived.copyof = true;
        //      this.shareDataService.setOpenEditorModalValue(true);
        //var partQuestionParams = (coll.refType == 'multipart' ? copyQuestionResponseReceived : {} );
        var partQuestionParams = {};
        if (coll.refType == "multipart") {
          partQuestionParams = copyQuestionResponseReceived;
          //
          this.shareDataService.setEditedQuestionType(
            "section-break-lib-question-edit"
          );
        }
        var editqid = response.qid;
        if (
          coll.hasOwnProperty("multiPartId") &&
          coll.multiPartId != "" &&
          coll.refType == "scalar" &&
          coll.userEdited === true
        ) {
          this.shareDataService.setEditedQuestionIndex(coll.index);
          this.shareDataService.setEditedQuestionType(
            "section-break-part-question"
          );
          this.shareDataService.setPartQuestionEditedInfo({
            index: coll.index,
            multiPartId: coll.multiPartId,
            copyy: true,
          });
          editqid = response.parts[0].qid;
          copyQuestionResponseReceived.qid = editqid;
        } else if (
          coll.hasOwnProperty("multiPartId") &&
          coll.multiPartId != "" &&
          coll.refType == "scalar" &&
          coll.userEdited === false
        ) {
          this.shareDataService.setEditedQuestionIndex(coll.index);
          this.shareDataService.setEditedQuestionType(
            "section-break-part-question-copy-edit"
          );
          this.shareDataService.setPartQuestionEditedInfo({
            multiPartId: coll.multiPartId,
          });
          let ind = coll.index;
          editqid = response.parts[ind].qid;
          partQuestionParams = copyQuestionResponseReceived;
          copyQuestionResponseReceived.qid = response.qid;
        }

        this.editorService.editQuestion(editqid, 1).subscribe(
          (data) => {
            this.openEditorUrl(
              data,
              copyQuestionResponseReceived,
              this.collection,
              partQuestionParams
            );
          },
          (err) => console.log(err)
        );
      },
      (error) => {
        this.error = error;
      }
    );
  }

  closeEditCopyModal() {
    this.showEditCopy = false;
  }
  closeEditModal() {
    this.showEditCopy = false;
    this.showEditAlert = false;
  }
  openEditorUrl(url: any, data: any, colobj: any, containPartsId) {
    const that = this;
    let JWT = url.editurl.substr(
      url.editurl.indexOf("EZTO_NMW_") + 9,
      url.editurl.length - 1
    );
    this.setMHECookie("JWT", JWT, 3);
    this.windowStuff.location.href = url.editurl.substr(
      0,
      url.editurl.indexOf("EZTO_NMW_") + 9
    );
    const thisWindow = this.windowStuff;

    this.shareDataService.setOpenEditorModalValue(true);
    thisWindow.focus();
    this.timerEditor = setInterval(() => {
      if (thisWindow.closed) {
        clearInterval(this.timerEditor);
        that.editorOnExit(data, colobj, containPartsId);
      }
    }, 500);
  }

  setMHECookie(name, value, exdays) {
    let cookieText = name + "=" + value;
    if (exdays != 0) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      cookieText =
        cookieText +
        "; " +
        "expires=" +
        d.toUTCString() +
        ";secure;SameSite=none;domain=.mheducation.com;path=/";
    }
    document.cookie = cookieText;
  }

  editorOnExit(data, colobj, containPartsId) {
    var body;

    if (Object.keys(containPartsId).length > 0) {
      body = {
        references: [
          {
            ezid: containPartsId.ezid,
            qid: containPartsId.qid,
            parts: containPartsId.parts,
          },
        ],
      };
    } else {
      body = {
        references: [
          {
            ezid: data.ezid,
            qid: data.qid,
          },
        ],
      };
    }

    this.shareDataService.setOpenEditorModalValue(false);
    this.editorService.getItemInfoQuestion(body).subscribe(
      (response) => {
        this.emitQuestionUpdate.emit({
          response: response,
          collectionobj: colobj,
          keepExistingQuestion: this.keepExistingQuestion,
        });
      },
      (err) => console.log(err)
    );
  }

  questionUses(element) {
    this.questionUsedArr = this.collection;
    this.whereIHaveUsedframe = true;
  }
  closeQuestionUsedModal() {
    this.whereIHaveUsedframe = false;
  }

  createNewQuestion(type: any) {
    let collMultipartID: any = {};
    collMultipartID = this.collection;

    if (this.collection.userEdited === false) {
      this.shareDataService.setEditedQuestionIndex(collMultipartID.index);
      let copyParams = {
        sourceezid: collMultipartID.ezid,
        sourceqid: collMultipartID.qid,
      };

      this.windowStuff = this.shareDataService.openBlankTab();
      this.editorService.copyQuestion(copyParams).subscribe(
        (response) => {
          const copyQuestionResponseReceived = response;

          this.editorService
            .newQuestion(type.value, copyQuestionResponseReceived.multiPartId)
            .subscribe(
              (response) => {
                const newquestionApiResponse = response;

                copyQuestionResponseReceived.parts.push(newquestionApiResponse); // New created question push to parts question for getItemInfo api call

                this.shareDataService.setNewPartQuestionId(
                  newquestionApiResponse.qid
                );
                this.shareDataService.setOpenEditorModalValue(true);
                this.editorService
                  .editQuestion(newquestionApiResponse.qid)
                  .subscribe(
                    (data) =>
                      this.openEditorUrl(
                        data,
                        newquestionApiResponse,
                        this.collection,
                        copyQuestionResponseReceived
                      ),
                    (err) => console.log(err)
                  );
              },
              (error) => console.log(error)
            );
        },
        (error) => {
          this.error = error;
        }
      );
    } else {
      this.shareDataService.setEditedQuestionIndex(collMultipartID.index);
      this.shareDataService.setEditedQuestionType("section-break");
      this.windowStuff = this.shareDataService.openBlankTab();
      this.editorService
        .newQuestion(type.value, collMultipartID.multiPartId)
        .subscribe(
          (response) => {
            const responseReceived = response;
            this.shareDataService.setOpenEditorModalValue(true);
            this.editorService.editQuestion(response.qid).subscribe(
              (data) =>
                this.openEditorUrl(data, responseReceived, this.collection, {}),
              (err) => console.log(err)
            );
          },
          (error) => console.log(error)
        );
    }
  }

  copyQuestionApi() {}

  showNewQuestion() {
    this.showQuestionTypes = !this.showQuestionTypes;
  }

  exportTest() {
    this.assesmentListService
      .exportTest(
        this.collection.collection_id,
        this.collection.collection_title
      )
      .subscribe((data) => {
        var blob = new Blob([data], { type: "application/octet-stream" });

        var downloadURL = window.URL.createObjectURL(blob);

        var link = document.createElement("a");

        link.href = downloadURL;

        link.setAttribute("target", "_blank");

        link.download = this.collection.collection_title + ".zip";

        document.body.appendChild(link);

        link.click();

        const self = this;

        setTimeout(function () {
          self.showSuccessAlert.emit(
            "Your test has been successfully exported. Import of this file can only be completed by colleagues with access to the same ISBN."
          );

          document.body.removeChild(link);

          window.URL.revokeObjectURL(downloadURL);
        }, 100);
      });
  }
}
