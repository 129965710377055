import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone,
  HostListener,
  AfterViewInit,
  Renderer2,
  ElementRef,
  ViewChild,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { QuestionList } from "../../shared/models/bank-question-list.model";
import { ShareDataService } from "../../shared/services/shared.service";

@Component({
  selector: "app-filter-test-category",
  templateUrl: "./filter-test-category.component.html",
  styleUrls: ["./filter-test-category.component.scss"],
})
export class FilterTestCategoryComponent implements OnInit, AfterViewInit {
  qCheckBoxCount: any = 0;
  selectAllItems = true;
  count: any = 0;
  status = true;
  mainPanelElements: any;
  selectedRow: number;
  mainPanel: any;
  questionPanel: any;
  catInputs: any;
  quesChckbxes: any;
  catCountBxes: any;
  catCheckboxes: any;
  inputQuesCount: any;
  filterJsonObject: any = { questions: [], cats: [] };
  firstPanelItems: any;
  dataList: any[] = [];
  isFilterOpen: boolean = false;

  @ViewChildren("items") items: QueryList<ElementRef>;
  @ViewChildren("inputCat") inputCats: QueryList<ElementRef>;
  @ViewChildren("qCheckbox") qCheckboxes: QueryList<ElementRef>;
  @ViewChildren("catCount") catCounts: QueryList<ElementRef>;
  @ViewChildren("cCheckbox") cCheckboxes: QueryList<ElementRef>;
  @ViewChildren("mainPanelItem") mainPanelItems: QueryList<ElementRef>;

  @ViewChild("mainPanel", { static: true }) mainPanels: ElementRef;
  @ViewChild("questionPanel", { static: true }) questionPanels: ElementRef;
  @ViewChild("inputQues", { static: true }) inputQues: ElementRef;

  constructor(
    private elem: ElementRef,
    private render: Renderer2,
    private sharedService: ShareDataService
  ) {}

  @Input("question-list") questionList: QuestionList;
  @Output() messageEvent = new EventEmitter<any>();
  @Output() filterEvent = new EventEmitter<any>();

  ngAfterViewInit() {
    this.mainPanelElements = this.items;
    this.catInputs = this.inputCats;
    this.quesChckbxes = this.qCheckboxes;
    this.catCountBxes = this.catCounts;
    this.catCheckboxes = this.cCheckboxes;
    this.mainPanel = this.mainPanels;
    this.questionPanel = this.questionPanels;
    this.inputQuesCount = this.inputQues;
    this.firstPanelItems = this.mainPanelItems;
  }

  ngOnInit() {}

  OpenPanel(section, index, currItem, event, question) {
    this.hideSubFilterPanels();
    this.isFilterOpen = true;

    if (question && question.tags) {
      question.tags.forEach((questionval) => {
        if (this.filterJsonObject.cats.includes(questionval.id)) {
          questionval.checked = true;
        }
      });
    }

    if (section == "question") {
      this.toggleDisplay(this.questionPanel.nativeElement.classList, true);
      event.currentTarget.classList.add("cq-box-white");
    } else if (section == "category") {
      this.toggleDisplay(currItem.classList, true);
      event.currentTarget.classList.add("cq-box-white");
    } else if (section == "mainPanel") {
      if (
        this.sharedService.getCategoryFilterCount() != undefined &&
        this.sharedService.getCategoryFilterCount().length > 0
      ) {
        for (let i = 0; i < this.catCountBxes.length; i++) {
          for (let key of this.sharedService.getCategoryFilterCount()) {
            if (
              this.catCountBxes._results[i].nativeElement.parentElement
                .firstChild.wholeText === key.name
            ) {
              this.catCountBxes._results[i].nativeElement.innerHTML = key.value;
              this.catInputs._results[i].nativeElement.value = key.value;
            }
          }
        }
      }
      this.toggleDisplay(this.mainPanel.nativeElement.classList, true);
    }
  }

  clickCount(event, section, index) {
    if (section == "question") {
      this.qCheckBoxCount = this.qCheckBoxCount;
      if (event.target.checked) {
        this.qCheckBoxCount = this.qCheckBoxCount + 1;
      } else {
        this.qCheckBoxCount = this.qCheckBoxCount - 1;
      }

      if (this.quesChckbxes.length == this.qCheckBoxCount) {
        this.selectAllItems = false;
      } else {
        this.selectAllItems = true;
      }
    } else if (section == "category") {
      const counyShowBox: any = this.catCountBxes._results[index].nativeElement;
      const inputCatCount: any = this.catInputs._results[index].nativeElement;

      if (event.target.checked) {
        inputCatCount.value = parseInt(inputCatCount.value, 10) + 1;
      } else {
        inputCatCount.value = parseInt(inputCatCount.value, 10) - 1;
      }
      counyShowBox.innerHTML = inputCatCount.value;

      const elements: any = this.elem.nativeElement.querySelectorAll(
        ".cCheckbox" + index
      );
      const selectAll = this.elem.nativeElement.querySelector(
        "#select" + index
      );
      const deSelectAll = this.elem.nativeElement.querySelector(
        "#deselect" + index
      );

      if (elements.length == inputCatCount.value) {
        this.toggleShowHide(false, selectAll, deSelectAll);
      } else {
        this.toggleShowHide(true, selectAll, deSelectAll);
      }
    }
  }

  closeFilter(section) {
    this.isFilterOpen = false;
    if (section == "mainPanel") {
      this.count = 0;
      this.dataList.length = 0;
      this.toggleDisplay(this.mainPanel.nativeElement.classList, false);
      this.catInputs.forEach((element) => {
        this.dataList.push(element.nativeElement.value);
        this.count = parseInt(element.nativeElement.value, 10) + this.count;
      });
      this.count = this.count + parseInt(this.qCheckBoxCount, 10);
      let countArray = [];
      for (
        let index = 0;
        index < this.questionList.itemInfo.categories.length;
        index++
      ) {
        countArray.push({
          name: " " + this.questionList.itemInfo.categories[index].name + " ",
          value: this.dataList[index],
        });
      }
      //this.sharedService.setCategoryFilterCount(countArray);
      this.messageEvent.emit(this.count);
    }
  }

  selectAll(section, index, catName) {
    this.selectAllItems = false;
    if (section == "question") {
      this.qCheckBoxCount = 0;
      this.quesChckbxes.forEach((element) => {
        element.nativeElement.checked = true;
      });
      this.qCheckBoxCount = this.quesChckbxes.length;
      this.createFilterJsonObject(this.quesChckbxes, "question", true, catName);
    } else if (section == "category") {
      const selectAll = this.elem.nativeElement.querySelector(
        "#select" + index
      );
      const deSelectAll = this.elem.nativeElement.querySelector(
        "#deselect" + index
      );
      this.toggleShowHide(false, selectAll, deSelectAll);

      const elements = this.elem.nativeElement.querySelectorAll(
        ".cCheckbox" + index
      );
      const counyShowBox: any = this.elem.nativeElement.querySelector(
        "#catCount" + index
      );
      const inputCatCount: any = this.elem.nativeElement.querySelector(
        "#inputCat" + index
      );

      elements.forEach((element) => {
        element.checked = true;
      });

      inputCatCount.value = elements.length;
      counyShowBox.innerHTML = elements.length;
      this.createFilterJsonObject(elements, "category", true, catName);
    }
  }

  deselectAll(section, index, catName) {
    this.selectAllItems = true;
    if (section == "question") {
      this.qCheckBoxCount = 0;
      this.quesChckbxes.forEach((element) => {
        element.nativeElement.checked = false;
      });
      this.createFilterJsonObject(
        this.quesChckbxes,
        "question",
        false,
        catName
      );
    } else if (section == "category") {
      const selectAll = this.elem.nativeElement.querySelector(
        "#select" + index
      );
      const deSelectAll = this.elem.nativeElement.querySelector(
        "#deselect" + index
      );
      this.toggleShowHide(true, selectAll, deSelectAll);

      const elements = this.elem.nativeElement.querySelectorAll(
        ".cCheckbox" + index
      );
      const counyShowBox: any = this.elem.nativeElement.querySelector(
        "#catCount" + index
      );
      const inputCatCount: any = this.elem.nativeElement.querySelector(
        "#inputCat" + index
      );

      elements.forEach((element) => {
        element.checked = false;
      });

      inputCatCount.value = 0;
      counyShowBox.innerHTML = inputCatCount.value;
      this.createFilterJsonObject(elements, "category", false, catName);
    }
  }

  toggleShowHide(showSelectAll, selectAll, deSelectAll) {
    if (showSelectAll) {
      this.render.addClass(selectAll, "show");
      this.render.removeClass(selectAll, "hide");
      this.render.addClass(deSelectAll, "hide");
      this.render.removeClass(deSelectAll, "show");
    } else {
      this.render.addClass(selectAll, "hide");
      this.render.removeClass(selectAll, "show");
      this.render.addClass(deSelectAll, "show");
      this.render.removeClass(deSelectAll, "hide");
    }
  }

  hideSubFilterPanels() {
    this.toggleDisplay(this.questionPanel.nativeElement.classList, false);
    this.mainPanelElements.forEach((element) => {
      this.toggleDisplay(element.nativeElement.classList, false);
    });

    this.firstPanelItems.forEach((element) => {
      element.nativeElement.classList.remove("cq-box-white");
    });
  }

  toggleDisplay(element, show) {
    if (show == true) {
      element.add("show");
      element.remove("hide");
    } else {
      element.add("hide");
      element.remove("show");
      element.remove("cq-box-white");
    }
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (event.target.id != "openMainPanel") {
      if (
        !this.elem.nativeElement.contains(event.target) &&
        this.isFilterOpen == true
      ) {
        this.closeFilter("mainPanel");
      }
    }
  }

  findIndexToBeRemoved(catvalue) {
    var processFilterTest = [];
    this.filterJsonObject.cats = JSON.parse(
      JSON.stringify(this.filterJsonObject.cats)
    );
    if (
      this.filterJsonObject.cats != undefined &&
      this.filterJsonObject.cats.length > 0
    ) {
      this.filterJsonObject.cats.forEach((e) => {
        processFilterTest.push(e.name.toLowerCase() + "#" + e.val);
      });
    }
    return processFilterTest.indexOf(catvalue);
  }
  createFilterJsonObject(elements, section, selectall, catName) {
    let index: any;
    let catvalue: any;
    if (section == "category") {
      elements.forEach((element) => {
        catvalue = catName.toLowerCase() + "#" + element.value;
        index = this.findIndexToBeRemoved(catvalue);
        //index = this.filterJsonObject.cats.indexOf(catName.toLowerCase()+'#'+element.value);
        if (index < 0 && selectall) {
          this.filterJsonObject.cats.push({
            name: catName,
            val: element.value,
          });
        } else if (!selectall) {
          this.filterJsonObject.cats.splice(index, 1);
        }
      });
    } else if (section == "question") {
      elements.forEach((element) => {
        index = this.filterJsonObject.questions.indexOf(
          element.nativeElement.value
        );
        if (index < 0 && selectall) {
          this.filterJsonObject.questions.push(element.nativeElement.value);
        } else if (!selectall) {
          this.filterJsonObject.questions.splice(index, 1);
        }
      });
    }

    this.filterEvent.emit(this.filterJsonObject);
  }

  onFilterChange(event, itemVal: any, section, filterCatName) {
    let index: any;
    if (section == "category") {
      //index = this.filterJsonObject.cats.indexOf(itemVal);
      let catvalue = filterCatName.toLowerCase() + "#" + itemVal;
      index = this.findIndexToBeRemoved(catvalue);
      if (event.currentTarget.checked) {
        if (index < 0) {
          this.filterJsonObject.cats.push({
            name: filterCatName,
            val: itemVal,
          });
        }
      } else {
        this.filterJsonObject.cats.splice(index, 1);
      }
    } else if (section == "question") {
      index = this.filterJsonObject.questions.indexOf(itemVal);
      if (event.currentTarget.checked) {
        if (index < 0) {
          this.filterJsonObject.questions.push(itemVal);
        }
      } else {
        this.filterJsonObject.questions.splice(index, 1);
      }
    }
    this.filterEvent.emit(this.filterJsonObject);
  }
  isObject(val): boolean {
    return typeof val === "object";
  }
  clearSelection() {
    this.filterJsonObject = { questions: [], cats: [] };
    let catlen = this.questionList.itemInfo.categories.length;
    for (let i = 0; i < catlen; i++) {
      this.deselectAll(
        "category",
        i,
        this.questionList.itemInfo.categories[i].name.toLowerCase()
      );
    }
    this.deselectAll("question", 0, "");
  }
}
