import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { NgxSharedModule, 
  TranslateModule } from '@mhe/ngx-shared';

@NgModule({
  declarations: [
    LoadingSpinnerComponent,
  ],
  imports: [
    CommonModule,
    NgxSharedModule,    
    TranslateModule.forRoot()
  ],
  providers: [],
  exports: [
    LoadingSpinnerComponent,
  ]
})
export class LoadingSpinnerModule { }
