import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EditorQuestionsService {

  constructor(private http: HttpClient) { }

  public newQuestion(type: any, multipardId = ''): Observable<any> {
    const baseUrl = environment.baseUrl;
    const multipart_id = (multipardId != '' ? '/'+multipardId : ''  );
    const listUrl = baseUrl+'/testbuilder/api/v1/newquestion/' + type + multipart_id;
    return this.http.get(listUrl);
  }

  public editQuestion(qid: any,copyof=0): Observable<any> {
    const baseUrl = environment.baseUrl;
    const listUrl = baseUrl + '/testbuilder/api/v1/editquestion/' + qid+'/'+copyof;
    return this.http.get(listUrl);
  }

  public getItemInfoQuestion(data: any): Observable<any> {    
    const baseUrl = environment.baseUrl;
    //  Replace this URL with the API call
    //const listUrl = '../../../assets/stubs/itemInfoItem.json';
    const listUrl =  baseUrl + '/testbuilder/api/itemInfo/';
    
    return this.http.post(listUrl, data);
  }
  public copyQuestion(data: any): Observable<any>{
    const baseUrl = environment.baseUrl;    
    const listUrl = baseUrl + '/testbuilder/api/v1/copyquestion/' + data.sourceezid + '/' + data.sourceqid;
    return this.http.get(listUrl);
  }

  public editCopiedQuestion(data: any): Observable<any>{
    const baseUrl = environment.baseUrl;    
    const listUrl = baseUrl + '/testbuilder/api/v1/editquestion/' + data.newCreatedQid;
    return this.http.get(listUrl);
  }
  
}
