import {
  Component,
  OnInit,
  ViewChildren,
  HostListener,
  Input,
  EventEmitter,
  Output,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { QuestionList } from "../../models/bank-question-list.model";
@Component({
  selector: "source-question",
  templateUrl: "./source-question.component.html",
  styleUrls: ["./source-question.component.scss"],
})
export class SourceQuestionComponent implements OnInit, AfterViewInit {
  constructor(private cdr: ChangeDetectorRef) {
    this.onResize();
  }
  @Input("question-preview") questionPreview: string;
  @Input("index-item") indexItem: string;
  @Input("question-hidden-array") questionHideArray: boolean[];
  @Input("head-question") headQuestion: string;
  @Input("ref-type") refType: string;
  @Output() toggleQuestionHideArrayClicked = new EventEmitter<string>();
  widthQuestionDisplay: number;

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.onResize();
    this.cdr.detectChanges();
  }
  onResize() {
    const element = document.getElementById("setQuestionDiv");
    if (element) {
      const positionInfo = element.getBoundingClientRect();
      this.widthQuestionDisplay = positionInfo.width - 77;
    }
  }
  toggleQuestionHideArray() {
    this.toggleQuestionHideArrayClicked.emit(this.indexItem);
  }
  // parsing the html file
  displayQuestion(htmlString: string) {
    const domParser = new DOMParser();
    const htmlElement = domParser.parseFromString(htmlString, "text/html");
    const e = htmlElement.querySelector(".stemStyle");
    const child = e.lastElementChild;
    e.removeChild(child);
    const removeMath = e.querySelector("math");
    if (removeMath !== null) {
      const imageHTML = e.querySelector("img");
      imageHTML.style.display = "block";
      removeMath.remove();
    }
    const tableElement = e.querySelectorAll("table");
    tableElement.forEach((el) => {
      el.querySelectorAll("tr > th").forEach((dataTh) => {
        dataTh.className = "fontData";
      });
      el.querySelectorAll("tr > td").forEach((data) => {
        if (
          data.lastElementChild !== null &&
          data.lastElementChild.hasAttribute("align")
        ) {
          data.className = "fontData";
        } else {
          data.className = "whiteSpace";
        }
        data.removeAttribute("width");
      });
      el.style.minWidth = "400px";
      el.removeAttribute("width");
    });
    e.querySelectorAll("a").forEach((val, index) => {
      val.href = localStorage.getItem("eztUrl") + val.attributes[0].nodeValue;
    });
    e.querySelectorAll("img").forEach((val, index) => {
      if (!val.src.startsWith("//") && val.src.includes("/extMedia")) {
        val.src = localStorage.getItem("eztUrl") + val.attributes["src"].nodeValue;
      }
    });
    return e.outerHTML;
  }
  convertReducedArray(initialArray) {
    return initialArray.reduce(function (result, value, index, array) {
      if (index % 2 === 0) result.push(array.slice(index, index + 2));
      return result;
    }, []);
  }
  displayAnswers(htmlString: string) {
    const domParser = new DOMParser();
    const htmlElement = domParser.parseFromString(htmlString, "text/html");
    const answerElements = htmlElement.querySelector(".stemStyle");
    answerElements.className = "stemStyle answerBlock";
    const refrenceElements = htmlElement.querySelector(".ref");
    const child = answerElements.lastElementChild;
    const totalHead = document.createElement("div");
    totalHead.className = "HeadElement";
    const headingAnswer = document.createElement("h4");
    headingAnswer.className = "answer-head";
    headingAnswer.innerHTML = "Answer";
    child.prepend(headingAnswer);
    const tableElement = child.querySelector("table");
    if (tableElement !== null) {
      if (!tableElement.hasAttribute("class")) {
        tableElement.className = "table-padding-0";
        tableElement.querySelectorAll(".mcCell").forEach((mcCellElement) => {
          const spanElement = mcCellElement.getElementsByTagName("span")[0];
          const inputElement = mcCellElement.getElementsByTagName("input")[0];
          if (spanElement !== undefined) {
            spanElement.remove();
            const answerSpan = document.createElement("span");
            answerSpan.className = "arrow-color arrow-color-checkbox";
            mcCellElement.appendChild(answerSpan);
          } else if (inputElement !== undefined) {
            inputElement.remove();
            const checkboxSpan = document.createElement("span");
            checkboxSpan.className = "checkbox-color";
            mcCellElement.appendChild(checkboxSpan);
          }
        });
      } else {
        child.querySelectorAll(".mcCellR").forEach((el) => {
          el.querySelector("input").remove();
          const hasSpan = el.querySelectorAll("span");
          const span = document.createElement("span");
          if (hasSpan.length > 0) {
            hasSpan.forEach((elm) => elm.remove());
            const answerSpan = document.createElement("span");
            answerSpan.className = "arrow-color arrow-color-radio";
            el.appendChild(answerSpan);
            span.className = "checkbox-span";
          } else {
            span.className = "checkbox-span no-answer";
          }
          el.appendChild(span);
        });
      }
    }
    totalHead.appendChild(child);
    if (tableElement == null) {
      const dataHead = totalHead.querySelector(".rspStyle");
      dataHead.className = "rspStyle left-padding";
    }
    const feedbackDiv = child.querySelector(".feedback_block");
    if (feedbackDiv !== null) {
      feedbackDiv.className = "table-display-div";
    }
    const refrenceDiv = document.createElement("div");
    refrenceDiv.className = "refrencesDiv container-fluid";
    const headingRefrence = document.createElement("h4");
    headingRefrence.className = "headingRefrence";
    headingRefrence.innerHTML = "References";
    refrenceDiv.appendChild(headingRefrence);
    let refrenceDivData = document.createElement("div");
    refrenceElements.childNodes.forEach((refrenceChilds) => {
      let initialNodeArray = [];
      refrenceChilds.childNodes.forEach((val) => {
        if (
          val.nodeName != "BR" &&
          val.textContent.trim() != "" &&
          val.textContent != null
        ) {
          initialNodeArray.push(val);
        }
      });
      initialNodeArray = this.convertReducedArray(initialNodeArray);
      initialNodeArray.map((nodePairArray) => {
        let rowDiv = document.createElement("div");
        rowDiv.className = "row rowRefrencediv";
        if (nodePairArray.length == 1) {
          let refrenceDivChildData = document.createElement("div");
          refrenceDivChildData.className =
            "col-lg-12 col-md-12 refrenceDivData panel-left";
          refrenceDivChildData.textContent = nodePairArray[0].textContent;
          rowDiv.appendChild(refrenceDivChildData);
        } else {
          nodePairArray.map((val, index) => {
            let refrenceDivChildData = document.createElement("div");
            if (index % 2 == 0) {
              refrenceDivChildData.className =
                "col-lg-6 col-md-6 refrenceDivData panel-left";
            } else {
              refrenceDivChildData.className =
                "col-lg-6 col-md-6 refrenceDivData panel-right";
            }
            refrenceDivChildData.textContent = val.textContent;
            rowDiv.appendChild(refrenceDivChildData);
          });
        }
        refrenceDivData.appendChild(rowDiv);
      });
    });
    refrenceDiv.appendChild(refrenceDivData);
    totalHead.appendChild(refrenceDiv);
    totalHead.querySelectorAll("img").forEach((val, index) => {
      if (!val.src.startsWith("//") && val.src.includes("/extMedia")) {
        val.src = localStorage.getItem("eztUrl") + val.attributes["src"].nodeValue;
      }
    });
    return totalHead.outerHTML;
  }
}
