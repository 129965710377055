import { HttpBackend, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class AssessmentListService {
  private httpClient: HttpClient;

  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  public getListCollection(timezone): Observable<any> {
    const baseUrl = environment.baseUrl;
    const userId = localStorage.getItem("userId");
    //let listUrl = '../../../assets/stubs/assessment-list.json'
    const listUrl = baseUrl + "/testbuilder/api/userCollections";
    return this.http.post(listUrl, { timeZone: timezone });
  }

  public resync(
    collectionId: String,
    collectionTitle: String
  ): Observable<any> {
    const baseUrl = environment.baseUrl;
    const userId = localStorage.getItem("userId");
    const listUrl =
      baseUrl +
      "/testbuilder/api/resync/" +
      collectionId +
      "/" +
      collectionTitle;
    return this.http.get(listUrl);
  }
  public downloadFile(
    collectionId: String,
    exportType: String
  ): Observable<any> {
    const baseUrl = environment.baseUrl;
    const userId = localStorage.getItem("userId");
    const downloadUrl =
      baseUrl +
      "/testbuilder/api/download/" +
      collectionId +
      "/" +
      exportType;
    return this.http.get(downloadUrl, { responseType: "blob" });
  }

  public renameCollection(data: any): Observable<any> {
    const baseUrl = environment.baseUrl;
    const userId = localStorage.getItem("userId");
    const renameUrl = baseUrl + "/rename/collection/" + userId;
    return this.http.post(renameUrl, data);
  }

  public exportTest(collectionId: String, testTitle: String): Observable<any> {
    const baseUrl = environment.baseUrl;

    const userId = localStorage.getItem("userId");

    const downloadUrl =
      baseUrl +
      "/testbuilder/api/export/" +
      collectionId +
      "/" +
      testTitle;

    return this.http.get(downloadUrl, { responseType: "blob" });
  }

  public uploadTestFile(data: any): Observable<any> {
    const baseUrl = environment.baseUrl;

    const userId = localStorage.getItem("userId");

    const isbn = localStorage.getItem("isbn");

    const jwtToken = localStorage.getItem("JWT-Token");

    const uploadUrl =
      baseUrl + "/testbuilder/api/import/" + isbn;

    return this.httpClient.post(uploadUrl, data, {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    });
  }
}
