import {
  Component,
  OnInit,
  ViewChildren,
  HostListener,
  Input,
  EventEmitter,
  Output,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { DragulaService } from "ng2-dragula";
import { QuestionList } from "../../models/bank-question-list.model";
import { QuestionFilterPipe } from "./../../pipes/question-filter.pipe";
import { SourceInfoService } from "../../../shared/services/source-info.service";
import { ShareDataService } from "../../services/shared.service";
import { environment } from "../../../../environments/environment";
import { ButtonPurpose, PageEvent } from "@mhe/ngx-shared";
import { SelectedBankQuestionsService } from "../../services/selected-bank-questions.service";

import { KebabMenuInterface } from "./../../../shared/models/kebab-menu-model";

@Component({
  selector: "source-bank",
  templateUrl: "./source-bank.component.html",
  styleUrls: ["./source-bank.component.scss"],
})
export class SourceBankComponent implements OnInit, AfterViewInit {
  private error;
  private droppedQuestions = [];
  droppedIndex: number = null;
  checkedQuestion = true;
  sourceInfoData = [];
  title = { selected: false };
  checked = false;
  initialEvent = {};
  selectedQuestions = [];
  similarQuestionsBothPanel = [];
  selectedQuestionsToMove = [];
  selectAllQuestions = [];
  hasMultiple = false;
  hasExpended: boolean = false;
  screenHeight: number = null;
  screenWidth: number = null;
  totalQuestion: number = 0;
  sourcePickerCompHeight: number = null;
  sourcePickerCompHeightBody: number = null;
  direction: number;
  isDesc: any;
  totalSearchCount: number = 0;
  column: any;
  questionCollection: any = {};
  previewUrl = [];
  qIndex: Number;
  closeModalPopUp: Boolean;
  public algoImgUrl =
    environment.assests + "/assets/img/connect_sprite_icon2.png";
  kebabMenu: KebabMenuInterface;
  sectionBreakKebabMenu: KebabMenuInterface;
  partQuestionKebabMenu: KebabMenuInterface;
  partQuestionNoDeleteKebabMenu: KebabMenuInterface;
  sectionBreakNoDeleteKebabMenu: KebabMenuInterface;
  public kebabIndex: any;
  public deleteText: string = "Delete";
  public deleteMessage: String;
  public showQuestionDelete: boolean = false;
  public warningImage = environment.assests + "/assets/img/warning.png";
  private deletedObject: any;
  private isPartQuestion: any;
  private parentQuestionIndex: any;
  public _buttonPurpose = ButtonPurpose;
  sectionTableWidth: string = "width:73%";

  previewHeaderText: string = "Previewing my questions";
  isPreviewSourceQuestions: boolean = false;
  public questionIndex: any = "0";
  constructor(
    private sourceInfoService: SourceInfoService,
    private questionListPipe: QuestionFilterPipe,
    private shareDataService: ShareDataService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private dragula: DragulaService,
    private sourceBankQuestionService: SelectedBankQuestionsService
  ) {
    this.onResize();
  }

  @Input("question-list") questionList: QuestionList;
  @Input("filters-list") filterList: QuestionList;
  @Input("dropped-questions") droppedQuestionList;
  @Input("transfer-success") transferSuccess;
  @Input("panel-Expanded") panelExpanded;
  @Input("truncate-Title") truncateTitle;
  @Input("text-search") searchedText = "";
  @ViewChildren("mySelectedBank") item;
  @Output() enableAdd = new EventEmitter<number>();
  @Output() singleItemClicked = new EventEmitter<number>();
  @Output() emitAddQuestion = new EventEmitter<number>();
  public questionListKebab: any = [];
  questionHideArray = new Map();
  currentPageIndex: number = 0;
  firstItemIndex: number = 0;
  totalLength: number = 1;
  pageSize: number = 20;
  widthQuestionDisplay: number;
  headQuestion: string = "head-question-default";
  ngOnInit() {
    this.sort("typeDesc");
    this.kebabMenu = {
      addNewPart: false,
      get_info: false,
      preview: true,
      pinUnpin: false,
      edit: false,
      copy: false,
      export: false,
      rename: false,
      print: false,
      delete: true,
      remove: false,
      pastUsage: true,
      editQuestion: true,
      copyAndEdit: true,
    };
    this.sectionBreakKebabMenu = {
      addNewPart: false,
      get_info: false,
      preview: true,
      pinUnpin: false,
      edit: false,
      copy: false,
      export: false,
      rename: false,
      print: false,
      delete: true,
      remove: false,
      pastUsage: true,
      editQuestion: true,
      copyAndEdit: true,
    };
    this.sectionBreakNoDeleteKebabMenu = {
      addNewPart: false,
      get_info: false,
      preview: true,
      pinUnpin: false,
      edit: false,
      copy: false,
      export: false,
      rename: false,
      print: false,
      delete: false,
      remove: false,
      pastUsage: true,
      editQuestion: true,
      copyAndEdit: true,
    };
    this.partQuestionKebabMenu = {
      addNewPart: false,
      get_info: false,
      preview: true,
      pinUnpin: false,
      edit: false,
      copy: false,
      export: false,
      rename: false,
      print: false,
      delete: true,
      remove: false,
      pastUsage: true,
      editQuestion: true,
      copyAndEdit: true,
    };
    this.partQuestionNoDeleteKebabMenu = {
      addNewPart: false,
      get_info: false,
      preview: true,
      pinUnpin: false,
      edit: false,
      copy: false,
      export: false,
      rename: false,
      print: false,
      delete: false,
      remove: false,
      pastUsage: true,
      editQuestion: true,
      copyAndEdit: true,
    };
    this.assignArrayIndex();
  }
  ngAfterViewInit() {
    this.assignArrayIndex();
    this.cdr.detectChanges();
  }
  assignArrayIndex() {
    let arrayIndex = 0;
    if (this.questionList) {
      this.questionList["itemInfo"].questions.forEach((item) => {
        item["index"] = arrayIndex++;
        this.questionHideArray.set(item.qid + "-main", false);
        if (item.hasOwnProperty("parts")) {
          item["parts"].map((val) => {
            this.questionHideArray.set(val.qid + "-parts", false);
          });
        }
        item = this.partQuestionAddIndex(item);
      });
    }
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (event.target.className !== "ahe-icon ahe-icon-system-kebab") {
      this.kebabIndex = -1;
    }
    if (document.getElementsByClassName("ahe-ui-alert-modal").length > 0) {
      this.kebabIndex = -1;
    }
    if (!this.panelExpanded) {
      this.headQuestion = "head-question-default";
      this.sectionTableWidth = "width:73%";
    } else {
      this.headQuestion = "head-question-fullWidth";
      this.sectionTableWidth = "width:78%";
    }
  }

  OnCheckboxSelect(bank, event) {
    const index = this.selectedQuestions.indexOf(bank);
    const draggableIndex = this.selectedQuestionsToMove.indexOf(bank);
    // To initialize the array once selct all and then unselect one
    // Issue is once select all then unselect one also setting as blank array instead the rest of the values

    // For checkbox select unselect object
    if (event) {
      bank.selected = true;
      if (bank.hasOwnProperty("parts")) {
        bank.parts = bank.parts.map((partQuestion) => {
          partQuestion.selected = true;
          return partQuestion;
        });
      }

      if (index === -1) {
        this.title.selected = false;
        this.selectedQuestions.push(bank);
        // Set selected question list
        this.shareDataService.setSelectedQuestions(this.selectedQuestions);
      }
    } else {
      bank.selected = false;
      if (bank.hasOwnProperty("parts")) {
        bank.parts = bank.parts.map((partQuestion) => {
          partQuestion.selected = false;
          return partQuestion;
        });
      }
      if (index !== -1) {
        this.title.selected = false;
        this.selectedQuestions.splice(index, 1);
        // Set selected question list
        this.shareDataService.setSelectedQuestions(this.selectedQuestions);
      }
    }
    this.sendMessage();
  }

  private unSelectAll() {
    this.title.selected = false;
    this.questionList.itemInfo.questions.forEach((item) => {
      item.selected = false;
    });
  }

  public trackByEzid(index, item) {
    return item.ezid;
  }
  selectAll(event) {
    const requiredResult = this.questionListPipe.transform(
      this.questionList.itemInfo.questions,
      this.filterList,
      true
    );
    if (event) {
      this.title = { selected: true };
    } else {
      this.title = { selected: false };
    }
    requiredResult.forEach((item) => {
      item.selected = event;
      if (item.hasOwnProperty("parts")) {
        item.parts = item.parts.map((partQuestion) => {
          partQuestion.selected = event;
          return partQuestion;
        });
      }

      if (item.selected && this.selectedQuestions.indexOf(item) === -1) {
        this.selectedQuestions.push(item);
        // Set selected question list
        this.shareDataService.setSelectedQuestions(this.selectedQuestions);
      } else if (!item.selected) {
        const index = this.selectedQuestions.indexOf(item);
        this.selectedQuestions.splice(index, 1);
        // Set selected question list
        this.shareDataService.setSelectedQuestions(this.selectedQuestions);
      }
    });
    this.sendMessage();
  }

  sendMessage() {
    this.enableAdd.emit(this.selectedQuestions.length);
  }

  @HostListener("window:resize", ["$event"])
  private onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    /**set source picker component height */
    this.sourcePickerCompHeight = this.screenHeight - 323;
    this.sourcePickerCompHeightBody = this.screenHeight - 386;
  }
  selectBank() {
    this.router.navigate(["/", "sourceSelect"]);
  }

  private setSelectedRowColor() {
    if (this.shareDataService.getDroppedQuestionId() != undefined) {
      this.shareDataService.getDroppedQuestionId().forEach((item) => {
        const position = this.droppedQuestions.indexOf(item);
        if (!this.shareDataService.getDeletedQuestionId()) {
          this.droppedQuestions.push(item);
        }
      });
    }
  }

  // Check and remove the
  removeFromArray(original, rightPanel) {
    this.similarQuestionsBothPanel = original
      .filter((o1) => rightPanel.some((o2) => o1.qid === o2.qid))
      .map(function (item) {
        return item["qid"];
      });
  }

  ngOnChanges(event) {
    if (this.droppedQuestionList) {
      this.setSelectedRowColor();
      this.removeFromArray(
        this.questionList.itemInfo.questions,
        this.droppedQuestionList
      );
      this.unselectQuestions();
    }
    if (event.hasOwnProperty("filterList") || this.searchedText != "") {
      this.currentPageIndex = 0;
    }
    this.setQuestionCount(this.getParsedArray());
    this.currentPage();
  }
  getParsedArray() {
    let totalQuestionAR: any = [];
    if (Object.keys(this.filterList).length === 0) {
      totalQuestionAR = this.questionList.itemInfo.questions;
    } else {
      totalQuestionAR = this.questionListPipe.transform(
        this.questionList.itemInfo.questions,
        this.filterList,
        true
      );
    }
    if (this.searchedText != "") {
      totalQuestionAR = this.filterSearchedItem(totalQuestionAR);
    }
    return totalQuestionAR;
  }
  filterSearchedItem(totalQuestionAR) {
    const questionArray = [];
    totalQuestionAR.map((findData) => {
      let dataFound =
        findData.preview
          .toLowerCase()
          .search(this.searchedText.toLowerCase().trim()) !== -1;
      if (findData.hasOwnProperty("parts") && !dataFound) {
        for (let i = 0; i < findData.parts.length; i++) {
          if (
            findData.parts[i].preview
              .toLowerCase()
              .search(this.searchedText.toLowerCase().trim()) !== -1
          ) {
            dataFound = true;
            break;
          }
        }
      }
      if (dataFound) {
        questionArray.push(findData);
      }
    });
    this.totalSearchCount = questionArray.length;
    return questionArray;
  }
  setQuestionCount(totalQuestionAR) {
    let totalQuestion = 0;
    totalQuestionAR.map((val) => {
      if (val.hasOwnProperty("parts")) {
        totalQuestion += val.parts.length;
      } else {
        totalQuestion += 1;
      }
    });
    this.totalQuestion = totalQuestion;
  }
  unselectQuestions() {
    for (const data in this.questionCollection) {
      this.questionCollection[data].forEach((item) => {
        item.selected = false;
      });
    }
    this.selectedQuestions = [];
    this.unSelectAll();
  }
  filterData() {
    this.currentPageIndex = 0;

    return;
  }
  currentPage() {
    const collectionArray = {};
    const dataArray = this.getParsedArray();
    let pageNum = 0;
    for (let i = 0; i < dataArray.length; i += this.pageSize) {
      const chunk = dataArray.slice(i, i + this.pageSize);
      collectionArray[pageNum] = chunk;
      pageNum++;
    }
    this.totalLength = dataArray.length;
    this.questionCollection = { ...collectionArray };
    return this.questionCollection[this.currentPageIndex] || [];
  }
  fetchPage(event: PageEvent) {
    this.currentPageIndex = event.pageIndex;
    this.firstItemIndex = event.firstItemIndex;
    if (!!this.questionCollection[this.currentPageIndex]) {
      return;
    }
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  public getOnlinePreviewUrl(question, index) {
    this.singleItemClicked.emit(question);
  }

  public closeModal(obj) {
    this.closeModalPopUp = !obj;
    this.isPreviewSourceQuestions = false;
    this.questionListKebab = [];
    this.questionIndex = "0";
  }
  sourceQuestionUpdate(val) {
    val = val.response.itemInfo.questions[0];
    var getPos: number = -1;
    getPos = this.shareDataService.getEditedQuestionIndex();

    if (getPos > -1) {
      let questType = this.shareDataService.getEditedQuestionType();

      if (questType == "section-break") {
        // sction break new question add

        this.questionList.itemInfo.questions[getPos].parts.push(val);
        this.assignArrayIndexSectionBreakQuestion(
          this.questionList.itemInfo.questions
        );
      } else if (questType == "section-break-part-question") {
        // section break part question edit or copyedit for my question
        let partInfo = this.shareDataService.getPartQuestionEditedInfo();
        if (
          partInfo.hasOwnProperty("multiPartId") &&
          partInfo.multiPartId != ""
        ) {
          var temp;
          temp = this.questionList.itemInfo.questions.map((item) => {
            if (partInfo.multiPartId == item.multiPartId) {
              if (partInfo.copyy === true) {
                item.parts.unshift(val);
              } else {
                var partQuesIndex = partInfo.index;
                item.parts[partQuesIndex] = val;
              }
            }
            return item;
          });
          this.questionList.itemInfo.questions = temp;
        }

        this.shareDataService.setPartQuestionEditedInfo({});
      } else if (questType == "section-break-part-question-copy-edit") {
        // section break part question edit for library question
        let partInfo = this.shareDataService.getPartQuestionEditedInfo();

        if (
          partInfo.hasOwnProperty("multiPartId") &&
          partInfo.multiPartId != ""
        ) {
          // Removed the existing item

          for (var i = this.questionList.itemInfo.questions.length; i--; ) {
            if (
              this.questionList.itemInfo.questions[i].multiPartId ==
              partInfo.multiPartId
            ) {
              this.questionList.itemInfo.questions.splice(i, 1);
            }
          }
        }

        this.questionList.itemInfo.questions.unshift(val);
        this.shareDataService.setPartQuestionEditedInfo({});
      } else {
        val = this.partQuestionAddIndex(val);
        this.questionList.itemInfo.questions[getPos] = val;
        // this.shareDataService.setEditedQuestionIndex(-1);
        //this.assignArrayIndexSectionBreakQuestion(this.selectedQuestionsListObj);
      }
      this.shareDataService.setEditedQuestionType("");
      this.shareDataService.setEditedQuestionIndex(-1);
    } else {
      val = this.partQuestionAddIndex(val);
      this.questionList.itemInfo.questions.unshift(val);
    }

    this.assignArrayIndex();
    //this.assignArrayIndexSectionBreakQuestion(this.questionList.itemInfo.questions);
    //this.arrayPinnedQuestions();
    //console.log('Left Panel Final Update');
    //console.log(this.questionList.itemInfo.questions);
  }
  partQuestionAddIndex(val) {
    if (val.hasOwnProperty("parts")) {
      let ind = 0;
      val.parts = val.parts.map((partQuestion) => {
        partQuestion.index = ind++;
        return partQuestion;
      });
    }
    return val;
  }

  deleteQuestion(val) {
    this.showQuestionDelete = true;
    this.deletedObject = val;
    this.deleteMessage = "msg3";
    if (this.deletedObject.refType == "multipart") {
      this.deleteMessage = "msg3";
    } else if (
      this.deletedObject.refType == "scalar" &&
      this.deletedObject.hasOwnProperty("multiPartId") &&
      this.deletedObject.multiPartId != ""
    ) {
      var noOfPartQuestion;
      var parentQuestionObj;
      this.questionList.itemInfo.questions.forEach((item) => {
        if (this.deletedObject.multiPartId == item.multiPartId) {
          noOfPartQuestion = item.parts.length;
          parentQuestionObj = item;
          this.parentQuestionIndex = item["index"];
        }
      });

      if (noOfPartQuestion == 1) {
        this.deleteMessage = "msg2";
        this.deletedObject = parentQuestionObj;
        this.isPartQuestion = false;
      } else {
        this.isPartQuestion = true;
        this.deleteMessage = "msg3";
      }
    }
  }

  closeConfirmModal() {
    this.showQuestionDelete = false;
    const response = this.sourceBankQuestionService
      .deleteQuestion(this.deletedObject)
      .subscribe(
        (data) => {
          if (this.isPartQuestion === true) {
            let pindex = this.parentQuestionIndex;
            this.questionList.itemInfo.questions[pindex].parts.splice(
              this.deletedObject.index,
              1
            );
            this.questionList.itemInfo.questions[pindex] =
              this.partQuestionAddIndex(
                this.questionList.itemInfo.questions[pindex]
              );
          } else {
            this.questionList.itemInfo.questions.splice(
              this.deletedObject.index,
              1
            );
            this.assignArrayIndex();
          }
        },
        (error) => {
          this.error = error;
          console.log("Question delete error");
        }
      );
  }

  public setKebabIndex(index: any) {
    this.kebabIndex = index;
  }
  closeDeleteModal() {
    this.showQuestionDelete = false;
    this.deletedObject = {};
  }
  indexCal(i, j) {
    return i + "." + j;
  }
  setWidthClass(panelExpanded, userEdited) {
    if (panelExpanded) {
      return "sec-td-wt-1";
    } else if (!panelExpanded && userEdited) {
      return "sec-td-wt-2";
    } else if (!panelExpanded && !userEdited) {
      return "sec-td-wt-3";
    } else if (panelExpanded && userEdited) {
      return "sec-td-wt-4";
    } else if (panelExpanded && !userEdited) {
      return "sec-td-wt-5";
    }
  }
  assignArrayIndexSectionBreakQuestion(data) {
    let len = this.questionList.itemInfo.questions.length;
    for (var i = 0; i <= len; i++) {
      if (this.questionList.itemInfo.questions[i].hasOwnProperty("parts")) {
        let ind = 0;
        let partslen = this.questionList.itemInfo.questions[i].parts.length;
        for (var j = 0; j <= partslen; j++) {
          this.questionList.itemInfo.questions[i].parts[j]["index"] = j;
        }
      }
    }
    /*this.questionList.itemInfo.questions = data.map((question) => {
        if( question.hasOwnProperty("parts")){
           let ind = 0;
            question.parts.map((partQuestion) => {
                 partQuestion.index = ind++;
                 return partQuestion;
            }) ;
        }
        return question;
    });   */
  }

  emitQuestionInfo(selectedQuestionsListObj) {
    this.singleItemClicked.emit(selectedQuestionsListObj);
  }

  addQuestionClicked(obj) {
    this.emitAddQuestion.emit(obj);
  }

  private uncheckedAddedQuestions(addedQuestions) {
    this.questionList.itemInfo.questions
      .filter((o1) => addedQuestions.some((o2) => o1.qid === o2.qid))
      .map(function (item) {
        item.selected = false;
      });

    const filterSelectedQuestions = this.selectedQuestions
      .filter((o1) => addedQuestions.some((o2) => o1.qid === o2.qid))
      .map(function (item) {
        return item;
      });

    var newFilterSelectedQuestions = this.selectedQuestions.filter(
      (element) => !filterSelectedQuestions.includes(element)
    );
    if (newFilterSelectedQuestions) {
      this.selectedQuestions = JSON.parse(
        JSON.stringify(newFilterSelectedQuestions)
      );
    }
    this.shareDataService.setSelectedQuestions(this.selectedQuestions);
  }
  toggleQuestionHideArray(questionId: string) {
    const isExpand = this.questionHideArray.get(questionId);
    this.questionHideArray.set(questionId, !isExpand);
  }
  expandAll(isExpand: boolean) {
    this.hasExpended = isExpand;
    this.questionHideArray.forEach((values, keys) => {
      this.questionHideArray.set(keys, isExpand);
    });
  }
}
