import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { EditorQuestionsService } from "../../../shared/services/editor-questions.service";
import { ShareDataService } from "../../../shared/services/shared.service";
@Component({
  selector: "app-question-type-dropdown",
  templateUrl: "./question-type-dropdown.component.html",
  styleUrls: ["./question-type-dropdown.component.scss"],
})
export class QuestionTypeDropdownComponent implements OnInit {
  constructor(
    private eRef: ElementRef,
    private editorService: EditorQuestionsService,
    private shareDataService: ShareDataService
  ) {}

  @Input("showQuestionTypes") showQuestionTypes: boolean;
  @Input("menuWidth") menuWidth: any;
  questionTypesList: any;
  @Output() emitValueOfDropdown = new EventEmitter<any>();
  timerEditor: any;
  @Output() emitItemInfo = new EventEmitter<any>();

  ngOnInit() {
    this.questionTypesList = [
      { value: "TF", viewValue: "True/False" },
      { value: "MC", viewValue: "Multiple Choice" },
      { value: "FB", viewValue: "Fill in the Blank" },
      { value: "ES", viewValue: "Essay" },
      { value: "SA", viewValue: "Short Answer" },
      { value: "CA", viewValue: "Check All That Apply" },
    ];
  }
  windowStuff: any;
  ngOnChanges(changes: SimpleChanges) {
    if (this.showQuestionTypes) {
      this.showQuestionTypes = changes["showQuestionTypes"].currentValue;
    }
  }

  createNewQuestion(type: any) {
    this.windowStuff = this.shareDataService.openBlankTab();
    this.editorService.newQuestion(type.value).subscribe(
      (response) => {
        const responseReceived = response;
        this.shareDataService.setOpenEditorModalValue(true);
        this.editorService.editQuestion(response.qid).subscribe(
          (data) => this.openEditorUrl(data, responseReceived),
          (err) => console.log(err)
        );
      },
      (error) => console.log(error)
    );
  }

  openEditorUrl(url: any, data: any) {
    const that = this;

    let JWT = url.editurl.substr(
      url.editurl.indexOf("EZTO_NMW_") + 9,
      url.editurl.length - 1
    );
    this.setMHECookie("JWT", JWT, 3);
    this.windowStuff.location.href = url.editurl.substr(
      0,
      url.editurl.indexOf("EZTO_NMW_") + 9
    );
    const thisWindow = this.windowStuff;
    thisWindow.focus();
    this.timerEditor = setInterval(() => {
      if (thisWindow.closed) {
        clearInterval(this.timerEditor);
        that.editorOnExit(data);
      }
    }, 500);
  }

  setMHECookie(name, value, exdays) {
    let cookieText = name + "=" + value;
    if (exdays != 0) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      cookieText =
        cookieText +
        "; " +
        "expires=" +
        d.toUTCString() +
        ";secure;SameSite=none;domain=.mheducation.com;path=/";
    }
    document.cookie = cookieText;
  }

  editorOnExit(data) {
    const body = {
      references: [
        {
          ezid: data.ezid,
          qid: data.qid,
        },
      ],
    };
    this.shareDataService.setOpenEditorModalValue(false);
    this.editorService.getItemInfoQuestion(body).subscribe(
      (response) => {
        this.emitItemInfo.emit(response);
      },
      (err) => console.log(err)
    );
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    const parentNode = event.target.parentElement;
    if (parentNode && parentNode.parentElement) {
      if (parentNode.parentElement.id !== "questionTypeContainer") {
        this.showQuestionTypes = false;
        this.emitValueOfDropdown.emit(false);
      }
    }
  }

  getWidthOfDropdown() {
    return this.menuWidth + "%";
  }

  ngOnDestroy() {
    if (this.timerEditor) {
      clearInterval(this.timerEditor);
    }
  }
}
