import {
  Component,
  OnInit,
  ViewChildren,
  HostListener,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { Router } from "@angular/router";
import { SelectedBankQuestionsService } from "./../../services/selected-bank-questions.service";
import { SourceInfoService } from "../../../shared/services/source-info.service";
import { ShareDataService } from "../../services/shared.service";
import { AutoUnsubscribe } from "./../decorator/autoUnsubscribe.decorator";
import { Subscription } from "rxjs";
import { HeaderData } from "../../models/header-items";
import { ButtonType } from "@mhe/ngx-shared";
import { ButtonPurpose } from "@mhe/ngx-shared";

@Component({
  selector: "source-picker",
  templateUrl: "./source-picker.component.html",
  styleUrls: ["./source-picker.component.scss"],
})

// @AutoUnsubscribe(["sourceInfoSubscription","selectedBankSubscription"])
export class SourcePickerComponent implements OnInit {
  private error;
  headerData: HeaderData;
  checkedBank = -1;
  plusMinusSign = -1;
  selectedBankObj: any;
  combinedBankNQuestionObj: any;
  questionList: any;
  sourceInfoData = [];
  initialEvent = {};
  useSourceSuccess: boolean = false;
  screenHeight: number = null;
  screenWidth: number = null;
  selectedBankSubscription: Subscription;
  sourceInfoSubscription: Subscription;
  viewBankInfoData: any;
  showBankInfo = false;
  bankTitle: any;
  sourcePickerCompHeight: number = null;
  public buttonType = ButtonType;
  public _buttonPurpose = ButtonPurpose;
  private testIsbn: String;
  constructor(
    private sourceInfoService: SourceInfoService,
    private sourceBankQuestion: SelectedBankQuestionsService,
    private router: Router,
    private shareDataService: ShareDataService
  ) {
    this.onResize();
  }

  @ViewChildren("mySelectedBank") item;
  @Output() questionFlagSelect = new EventEmitter<boolean>();
  @Output() cancelButtonSelect = new EventEmitter<boolean>();
  @Output() sourceSuccess = new EventEmitter<boolean>();
  @Input("truncate-Title") truncateTitle;
  products = [];
  selectedBanks = [];

  OnCheckboxSelect(bank, bankName: String, event, index: number) {
    this.checkedBank = index;
    this.selectedBankObj = bank;
    this.shareDataService.setBankName(bankName);
    setTimeout(function () {
      //do nothing
    }, 50);
  }

  public dblClickBank(bank, event, index) {
    this.OnCheckboxSelect(bank, bank.title, event, index);
    this.selectBank();
  }

  OnBankInfoSelect(show: boolean, bankId: String, bankTitle: String) {
    this.bankTitle = bankTitle;
    if (bankId != undefined) {
      this.sourceInfoService.viewBankInfo(bankId).subscribe(
        (data) => {
          this.viewBankInfoData = data;
          this.showBankInfo = show;
        },
        (error) => {
          this.error = error;
        }
      );
    }
  }

  hideBankInfo(show: boolean) {
    this.showBankInfo = show;
    this.viewBankInfoData = undefined;
  }

  ngOnInit() {
    this.sourceInfoSubscription = this.sourceInfoService
      .getSourceInfoCollection()
      .subscribe(
        (data) => {
          this.sourceInfoData = data;
          this.shareDataService.setSourceInfoDataList(data.sourceInfo);
        },
        (error) => {
          this.error = error;
        }
      );
    this.headerData = this.shareDataService.getHeaderData();
  }

  @HostListener("window:resize", ["$event"])
  private onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.sourcePickerCompHeight = this.screenHeight - 367;
  }

  private getQuestionList() {
    this.questionFlagSelect.emit(true);
    const requestParam: any = { ezid: this.selectedBankObj.ezid };
    const release = localStorage.getItem("release");
    if (release) {
      requestParam['release'] = release;
    }
    this.selectedBankSubscription = this.sourceBankQuestion
      .SelectedBankQuestions(requestParam)
      .subscribe((data) => {
        this.questionList = data;
        this.combinedBankNQuestionObj = {
          bank: this.selectedBankObj,
          questions: this.questionList,
        };
        this.shareDataService.setBankObject(this.combinedBankNQuestionObj);
        this.useSourceSuccess = false;
        this.sourceSuccess.emit(this.useSourceSuccess);
      });
  }
  selectBank() {
    let bankTitle = this.selectedBankObj.title;
    this.testIsbn = this.shareDataService.getcheckISBN();
    if (
      this.testIsbn != undefined &&
      this.testIsbn != localStorage.getItem("isbn")
    ) {
      if (bankTitle != "My Questions") {
        this.sourceInfoService.getSourceInfoCollection(this.testIsbn).subscribe(
          (data) => {
            if (data && data["sourceInfo"]) {
              const sourceInfo = data["sourceInfo"];
              const message = {
                Message:
                  "Only questions from this title: " +
                  sourceInfo["isbn_title"] +
                  ", edition: " +
                  sourceInfo["edition"] +
                  " can be added to this test.",
              };
              this.shareDataService.sendMessageData(message);
            }
          },
          (error) => {
            this.error = error;
          }
        );
      } else {
        if (this.selectedBankObj) {
          this.getQuestionList();
        }
      }
    } else {
      if (this.selectedBankObj) {
        this.getQuestionList();
      }
    }
  }
  public hideLeftPanel() {
    this.cancelButtonSelect.emit(true);
  }
}
